import { useState } from 'react';
import Modal from '@mui/material/Modal';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import '../../styles/ahu-test.css'
import '../../styles/select-order.css'
import PrintIcon from '@mui/icons-material/Print';
import { getPrinters } from '../queries/adminQueries';

const useStyles = makeStyles({
    root: {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#000000a8',
        border: '2px solid #000',
        width: "600px",
        whiteSpace: "pre-line",
    },
    title: {
        marginTop: "10px",
        fontSize: "24px",
        fontFamily: 'Banschrift'
    },
    bodyText: {
        padding: '30px',
        color: 'white',
        textAlign: 'left',
        fontFamily: 'Banschrift',
        fontSize: '18px',
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        justifyContent: "center",
        gap: "10px"
    },
    select: {
        color: "black",
        padding: "5px",
        width: "25%",
        borderRadius: "7px",
    },
    input: {
        color: "black",
        width: "25%",
        borderRadius: "7px",
    }
})

type Props<T> = {
    item: T;
    isPrintingAllowed: (item: T) => any;
    printFunction: (...args: any[]) => Promise<any>;
    printFunctionArgs: any[];
    defaultPrinter: string;
    defaultQuantity?: number;
    printerLabeL?: string;
};

const PrinterModal = <T extends {}>({ item, isPrintingAllowed, printFunction, printFunctionArgs, defaultPrinter, defaultQuantity = 1, printerLabeL = "ProductCodeLabel" }: Props<T>) => {
    const [isOpen, setIsOpen] = useState(false);
    const styles = useStyles();
    const [printers, setPrinters] = useState<string[]>([]);
    const [selectedPrinter, setSelectedPrinter] = useState<string>(defaultPrinter);
    const [printCount, setPrintCount] = useState<number>(defaultQuantity);

    const handleOpen = () => {
        setIsOpen(true);
        getPrinters().then((res) => {
            setPrinters(res.data);
        }).catch((err) => { console.log(err) });
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    const handleSelectPrinter = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedPrinter(e.target.value);
    }

    const handlePrint = () => {
        if (selectedPrinter === "") return alert("Valige printer");
        if (item)
            printFunction(printCount, selectedPrinter, ...printFunctionArgs, printerLabeL)
                .then((res) => { console.log("Success") })
                .catch((err) => { console.log(err) })
                .finally(() => { setIsOpen(false) });
    }
    return (
        <>
            {isPrintingAllowed(item) && (
                <>
                    <div className="select-order-grid__icon1" style={{ marginTop: "4%" }} onClick={handleOpen}>
                        <IconButton><PrintIcon /></IconButton>
                    </div>

                    <Modal
                        open={isOpen}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box className={styles.root}>
                            <Typography className={styles.title} id="modal-modal-title" variant="h6" component="h2">
                                ID sildi printimine
                            </Typography>
                            <Typography className={styles.bodyText} id="modal-modal-description">
                                Valige mitu koopiat soovite printida
                                <input className={styles.input} type="number" min="1" max="10" value={printCount} onChange={(e) => setPrintCount(+e.target.value)} />
                                Valige printer
                                <select title="Printers" className={styles.select} value={selectedPrinter ?? ""} onChange={(e: any) => handleSelectPrinter(e)}>
                                    <option value="" disabled hidden></option>
                                    {printers.map((printer, idx) => (
                                        <option key={idx} style={{ color: "black" }} value={printer}>{printer}</option>
                                    ))}
                                </select>
                            </Typography>
                            <div style={{ display: "flex", justifyContent: "center", gap: "10px", marginTop: "25px" }}>
                                <Button style={{ color: "white", background: "green", fontWeight: "bold" }} onClick={() => handlePrint()} disabled={selectedPrinter === ""}>Prindi</Button>
                                <Button style={{ color: "white", background: "#c00", fontWeight: "bold" }} onClick={() => setIsOpen(false)}>Tühista</Button>
                            </div>
                        </Box>
                    </Modal>
                </>
            )}
        </>
    );
}

export default PrinterModal