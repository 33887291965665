import React from 'react'
import { Label } from 'reactstrap';
import { useTranslation } from "react-i18next";
import MeasurementField from '../MeasurementField';
import { CheckboxOptions, CheckboxDefaultValue } from '../../ahu/common/checkboxConfig';
import CheckboxGroup from '../CheckboxGroup';
import InfoModal from '../InfoModal';
import { pressureSensorsText } from '../ModalTexts';

const FilterForm = () => {
    const { t } = useTranslation();

    return (
        <form className="ahutest-container" >
            <Label className="ahutest-from--title">{t("Filters")}</Label>
            <div className="flexboxAlignedColumn" style={{ padding: "2%" }}>
                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Outdoor air filter installed correctly (F7)")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="outdoor-filt"
                    />
                </div>
                <MeasurementField showValue={false} properyName='outdoor-filt' />

                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Extract filter installed correctly (M5)")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="extract-filt"
                    />
                </div>
                <MeasurementField showValue={false} properyName='extract-filt' />

                <div className="ahutest-form__label-info-button--grid" >
                    <Label className="grid-item-label" >{t("Pressure sensors installed")}</Label>
                    <InfoModal title={t("Juhend")} bodyText={pressureSensorsText} />
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="sensors-filt"
                    />
                </div>
                <MeasurementField showValue={false} properyName='sensors-filt' />
            </div>
        </form>
    )
}

export default FilterForm