import React from 'react'
import { Label } from 'reactstrap';
import { useTranslation } from "react-i18next";
import { CheckboxOptions, CheckboxDefaultValue } from '../../../ahu/common/checkboxConfig';
import CheckboxGroup from '../../CheckboxGroup';
import IsDisabled from '../../IsDisabled';
import { useAhuDataContext } from '../../../../../context/ahuData';


const FactorySettingsForm = () => {
    const { t } = useTranslation();
    const { ahuTestDataEst, setAhuTestDataEst } = useAhuDataContext();

    const handleAhuTestMeasurementsValue = (event: any, propertyName: string) => {
        setAhuTestDataEst({
            ...ahuTestDataEst,
            "measurements": { ...ahuTestDataEst.measurements, [event.target.name]: { ...ahuTestDataEst.measurements[event.target.name], [propertyName]: event.target.value } }
        })
    }

    return (
        <form className="ahutest-container" >
            <Label className="ahutest-from--title">{t("Select factory settings in controller")}</Label>

            <div className="flexboxAlignedColumn" style={{ padding: "2%" }}>
                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Heater type")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="heater-factSett"
                    />
                </div>
                {IsDisabled("heater-factSett") &&
                    <div className="flexboxAlignedRow" style={{ gap: "25px" }}>
                        <select className="ahutest-form__input select" title={t("Choose value")} name="heater-factSett" onChange={e => handleAhuTestMeasurementsValue(e, "value")} value={ahuTestDataEst.measurements["heater-factSett"]?.value ?? ""} placeholder={t("Value")} >
                            <option value="" disabled hidden>{t("Choose value")}</option>
                            <option className="option" value="Vesikütte kalorifeer">{t("Water heater")}</option>
                            <option className="option" value="Elektrikütte kalorifeer">{t("Electric heater")}</option>
                        </select>
                    </div>}

                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("CO2 measurement sensor")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="co2-factSett"
                    />
                </div>
                {IsDisabled("co2-factSett") &&
                    <div className="flexboxAlignedRow" style={{ gap: "25px" }}>
                        <select className="ahutest-form__input select" title={t("Choose value")} name="co2-factSett" onChange={e => handleAhuTestMeasurementsValue(e, "value")} value={ahuTestDataEst.measurements["co2-factSett"]?.value ?? ""} placeholder={t("Value")} >
                            <option value="" disabled hidden>{t("Choose value")}</option>
                            <option className="option" value="Kasutusel">{t("In use")}</option>
                            <option className="option" value="Ei ole kasutusel">{t("Not in use")}</option>
                        </select>
                    </div>}
            </div>
        </form>
    )
}

export default FactorySettingsForm