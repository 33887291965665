import { useTranslation } from "react-i18next";
import { useCustomer } from '../../context/user';
import { Redirect, useHistory } from "react-router-dom";
import { Button } from 'reactstrap';
import '../../styles/login.css'
import '../../styles/flexbox.css'
import { Buffer } from 'buffer';

const Login = () => {
    // @ts-ignore
    const encodedString = Buffer.from(window.location.origin + "/").toString('base64');
    const { t, i18n } = useTranslation();
    const { logged } = useCustomer();
    const history = useHistory();

    /* if (logged) {
        return <Redirect to="/" />
    } */

    return (
        <div>
            <div className="authBtns_container">
                <Button style={{ width: "200px", height: "50px", backgroundColor: "initial", border: "2px solid #FFFFFF", borderRadius: "25px", zIndex: "99" }}
                    onClick={(e) => {
                        e.preventDefault();
                        window.location.href = `https://login.etsnord.com/?language=${i18n.language}&returnurl=${encodedString}`
                    }}
                >
                    {t("Login")}
                </Button>
                <div className="container-background" style={{ position: "fixed", width: "100vw", height: "100vh", bottom: "0", left: "0" }}></div>
            </div>
        </div>
    )
}
export default Login
