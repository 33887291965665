import React from 'react'
import { useTranslation } from 'react-i18next';
import { Label } from 'reactstrap';
import { useTkalDataContext } from '../../../context/tkalData';
import InfoModal from '../ahu-estonia/InfoModal';
import { CheckboxDefaultValue, CheckboxOptions, CheckboxOptionsThumbsOnly } from '../ahu/common/checkboxConfig';
import CheckboxGroupOzonator from './common/CheckboxGroupOzonator';
import MeasurementFieldOzonator from './common/MeasurementFieldOzonator';

const OzonatorPreCheckForm = () => {
    const { t } = useTranslation();
    const instructionText1 = "Generaatorid peavad olema märgistatud alt poolt. Prinditud kleebis (HX1; HX2 ja HX3) panna korpuse peale, osooni generaatori juurde"
    const instructionText2 = "Kirjuta ülesse iga osoonigeneraatori seerianumber, juhul kui see on tal olemas."
    const { ozonatorTest, setOzonatorTest } = useTkalDataContext()

    const handleChangeMeasurementsValueUnit = (event: any, propertyName: string) => {
        setOzonatorTest({
            ...ozonatorTest,
            "measurements": { ...ozonatorTest.measurements, [event.target.name]: { ...ozonatorTest.measurements[event.target.name], [propertyName]: event.target.value, unit: "" } }
        })
    }

    return (
        <form className="ahutest-container" >
            <Label className="ahutest-from--title">{t("Eelkontroll")}</Label>
            <div className="flexboxAlignedColumn" style={{ padding: "2%" }}>
                <div className="ahutest-form__label--grid2">
                    <Label className="grid-item-label" >{t("Koostatud vastavalt elektriskeemile")}</Label>
                    <CheckboxGroupOzonator
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="schema-preCheck"
                    />
                </div>
                <MeasurementFieldOzonator propertyName="schema-preCheck" showValue={false} />

                <div className="ahutest-form__label-info-button--grid" >
                    <Label className="grid-item-label" >{t("Sisend pistikul X1 on emane pistik küljes")}</Label>
                    <InfoModal title={t("Juhend")} bodyText={"Pistik X1 emane pistik on küljes ja kleebis 1-16 on küljes"} />
                    <CheckboxGroupOzonator
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="inlet-preCheck"
                    />
                </div>
                <MeasurementFieldOzonator propertyName="inlet-preCheck" showValue={false} />

                <div className="ahutest-form__label-info-button--grid" >
                    <Label className="grid-item-label" >{t("Juhtmed on fiktseeritud ja kinnitatud korralikult. Juhtmed ei ole suure pinge all")}</Label>
                    <InfoModal title={t("Juhend")} bodyText={"Kontrollida, et ei oleks lahtiseid juhtmeid. Kergelt tõmmates juhet."} />
                    <CheckboxGroupOzonator
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="wires-preCheck"
                    />
                </div>
                <MeasurementFieldOzonator propertyName="wires-preCheck" showValue={false} />

                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Seadmetel ja klemmidel on kleebised/märgistused peal")}</Label>
                    <CheckboxGroupOzonator
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="stickers-preCheck"
                    />
                </div>
                <MeasurementFieldOzonator propertyName="stickers-preCheck" showValue={false} />

                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Sulavkaitse 3,15A klemmis F1 on olemas 2 tk (üks sees, üks varu)")}</Label>
                    <CheckboxGroupOzonator
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="fuseF1-preCheck"
                    />
                </div>
                <MeasurementFieldOzonator propertyName="fuseF1-preCheck" showValue={false} />

                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Sulavkaitse 1A klemmis F2 on olemas 2 tk (üks sees, üks varu)")}</Label>
                    <CheckboxGroupOzonator
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="fuseF2-preCheck"
                    />
                </div>
                <MeasurementFieldOzonator propertyName="fuseF2-preCheck" showValue={false} />

                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Sulavkaitse 1A klemmis F3 on olemas 2 tk (üks sees, üks varu)")}</Label>
                    <CheckboxGroupOzonator
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="fuseF3-preCheck"
                    />
                </div>
                <MeasurementFieldOzonator propertyName="fuseF3-preCheck" showValue={false} />

                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Sulavkaitse 1A klemmis F4 on olemas 2 tk (üks sees, üks varu)")}</Label>
                    <CheckboxGroupOzonator
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="fuseF4-preCheck"
                    />
                </div>
                <MeasurementFieldOzonator propertyName="fuseF4-preCheck" showValue={false} />

                <div className="ahutest-form__label-info-button--grid" >
                    <Label className="grid-item-label" >{t("Osoonigeneraatorid on märgistatud, juhtmed parajas pikkuses ja pistikud on küljes")}</Label>
                    <InfoModal title={t("Juhend")} bodyText={instructionText1} />
                    <CheckboxGroupOzonator
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="ozone-preCheck"
                    />
                </div>
                <MeasurementFieldOzonator propertyName="ozone-preCheck" showValue={false} />

                <div className="ahutest-form__label-info-button--grid" >
                    <Label className="grid-item-label" >{t("Osoonigeraatorite seerianumbrid")}</Label>
                    <InfoModal title={t("Juhend")} bodyText={instructionText2} />
                    <CheckboxGroupOzonator
                        options={CheckboxOptionsThumbsOnly}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="serials-preCheck"
                    />
                </div>
                <div className="flexboxAlignedRow" style={{ gap: "15px" }}>
                    <div className='ahutest-form__input --value' style={{ border: "none" }}>HX1</div>
                    <input className="ahutest-form__input" placeholder='Seerianumber HX1' name="serialsHx1-preCheck" value={ozonatorTest.measurements["serialsHx1-preCheck"]?.value ?? ""} onChange={e => handleChangeMeasurementsValueUnit(e, "value")} />
                </div>

                <div className="flexboxAlignedRow" style={{ gap: "15px" }}>
                    <div className='ahutest-form__input --value' style={{ border: "none" }}>HX2</div>
                    <input className="ahutest-form__input" placeholder='Seerianumber HX2' name="serialsHx2-preCheck" value={ozonatorTest.measurements["serialsHx2-preCheck"]?.value ?? ""} onChange={e => handleChangeMeasurementsValueUnit(e, "value")} />
                </div>
                <div className="flexboxAlignedRow" style={{ gap: "15px" }}>
                    <div className='ahutest-form__input --value' style={{ border: "none" }}>HX3</div>
                    <input className="ahutest-form__input" placeholder='Seerianumber HX3' name="serialsHx3-preCheck" value={ozonatorTest.measurements["serialsHx3-preCheck"]?.value ?? ""} onChange={e => handleChangeMeasurementsValueUnit(e, "value")} />
                </div>

                <div className="ahutest-form__label-info-button--grid" >
                    <Label className="grid-item-label" style={{ gridArea: "label  icon0 icon1 icon2 icon3" }}>{t("Ouman M BA 1-PCB kontrolleri seerianumber")}</Label>
                    <InfoModal title={t("Juhend")} bodyText={"Sisesta kontrolleri tootekleebisel olev seerianumber"} />
                </div>
                <div className="flexboxAlignedRow" style={{ gap: "15px" }}>
                    <div className='ahutest-form__input --value' style={{ border: "none" }}>SN</div>
                    <input className="ahutest-form__input" placeholder='Seerianumber' name="oumanSerial-preCheck" value={ozonatorTest.measurements["oumanSerial-preCheck"]?.value ?? ""} onChange={e => handleChangeMeasurementsValueUnit(e, "value")} />
                </div>
            </div>
        </form>
    )
}

export default OzonatorPreCheckForm