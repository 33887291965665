import React from 'react'
import { useTranslation } from 'react-i18next';
import { Label } from 'reactstrap';

import CheckBoxGroupControlPanel from '../common/CheckBoxGroupControlPanel';
import MeasurementFieldControlPanel from '../common/MeasurementFieldControlPanel';
import InfoModal from '../../ahu-estonia/InfoModal';
import { CheckboxDefaultValue, CheckboxOptions } from '../../ahu/common/checkboxConfig';

const UVSOperationCheckForm = () => {
    const { t } = useTranslation();
    const masterSlaveConnectionText = `Ühenda X1 pistik koos slave kontrolleriga juhtimiskilbi külge.\n 
    LCD-st aktiveeri Slave seade nii: Test menüü -> Modbus ühendus -> vali "Aktiivne". Peamenüüsse tekivad read "Seadme olek" ja "OK teated". \n
    Kui OK teated numbrid kasvavad ja Seadme olek näitab olekut "Aktiivne", siis on test läbitud ning Master-Slave ühendus seadme vahel toimib. \n
    Pärast seda vali, et tal ei ole Slave seadmeid: Test menüü -> Modbus ühendus -> vali "Mitte aktiivne".`
    const workPermitText = `Seadista osonaatori programmis tööloa kontrollimist järgmiselt: Test menüü -> Tööloa seaded -> vali "Kasutuses".\n
    Test menüüsse tekkis rida Tööloa olek. \n
    Kui seina peal oleval testimis karbil lülitada tööluba ON olekusse, peab näitama LCD-s Tööloa oleku real "ON" ning kui lüliti on OFF olekus, näitab LCD-s "OFF". Kui toimib, vali Tööloa seadetes -> "Puudub".`
    const pressureText1 = `Ühendada rõhuanduri 1.1 miinus (-) testkambri küljes olevasse mõõteotsikusse. Mõõda rõhku samas punktis kalibreeritud rõhuanduriga ning võrdle erinevust. Mõõteviga võib olla maksimaalselt +- 2 Pa.`;
    const failSafeText1 = `UV testimise karbil lülita antud lüliti sisse ON olekusse ja LCD-s peab Turvalüliti 1.1 olek näitama "okei". Kui lüliti on OFF olekus, näitab LCD-s "Pole okei"`;

    const powerOnText = `Turvalüliti olema olekus "OKEI" ja rõhk näitama vähemalt 20 Pa. Ühenda UV lamp juhtimiskilbi. \n
    Lülita "Süsteemi olek" olekusse "Töötab". Tekib rida UV lamp 1 olek ning see peab näitama "Töötab". \n
    Võimalusel kontrolli kambris kas lamp ka päriselt töötab.`;

    const ballastTemperatureText = `Peab näitama +- toatemperatuuri.`;
    const workBitText = `Kui süsteem on välja lülitatud, mine Test menüü -> Tööoleku olek -> Manual -> vali "ON". Testimise karbil peab minema BIT0 LED põlema. Kui toimib siis panna Tööoleku olek tagasi automaatse peale.`
    const serviceText = `Mine Test menüü -> Hoolduse olek -> Manual -> vali "ON". Testimise karbil peab minema BIT1 LED põlema. Kui toimib siis panna Hoolduse olek tagasi automaatse peale.`;
    const errorStatusText = `Mine Test menüü -> Vea olek -> Manual -> vali "ON". Testimise karbil peab minema BIT2 LED põlema. Kui toimib siis panna Vea olek tagasi automaatse peale.`;
    const ledAlarmText = `Mine Test menüü -> LED alarm -> Manual -> vali "ON". Juhtimiskilbil peab minema LED indikatsioon põlema. Kui toimib siis panna LED alarm tagasi automaatse peale.`;

    return (
        <form className="ahutest-container" >
            <Label className="ahutest-from--title">{t("Seadme töökorra testimine kambris")}</Label>
            <div className="flexboxAlignedColumn" style={{ padding: "2%" }}>

                <div className="ahutest-form__label-info-button--grid" >
                    <Label className="grid-item-label" >{t("Master-Slave ühendus toimib")}</Label>
                    <InfoModal title={t("Juhend")} bodyText={masterSlaveConnectionText} />
                    <CheckBoxGroupControlPanel
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="msConnection-operations"
                    />
                </div>
                <MeasurementFieldControlPanel propertyName="msConnection-operations" showValue={false} />

                <div className="ahutest-form__label-info-button--grid" >
                    <Label className="grid-item-label" >{t("Tööloa toimivus")}</Label>
                    <InfoModal title={t("Juhend")} bodyText={workPermitText} />
                    <CheckBoxGroupControlPanel
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="workPermit-operations"
                    />
                </div>
                <MeasurementFieldControlPanel propertyName="workPermit-operations" showValue={false} />

                <div className="ahutest-form__label-info-button--grid" >
                    <Label className="grid-item-label" >{t("Rõhk 1.1")}</Label>
                    <InfoModal title={t("Juhend")} bodyText={pressureText1} />
                    <CheckBoxGroupControlPanel
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="pressure1-operations"
                    />
                </div>
                <MeasurementFieldControlPanel propertyName="pressure1-operations" showValue={false} />

                <div className="ahutest-form__label-info-button--grid" >
                    <Label className="grid-item-label" >{t("Turvalüliti 1.1")}</Label>
                    <InfoModal title={t("Juhend")} bodyText={failSafeText1} />
                    <CheckBoxGroupControlPanel
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="failSafe1-operations"
                    />
                </div>
                <MeasurementFieldControlPanel propertyName="failSafe1-operations" showValue={false} />

                <div className="ahutest-form__label-info-button--grid" >
                    <Label className="grid-item-label" >{t("Süsteemi käivitamine toimib")}</Label>
                    <InfoModal title={t("Juhend")} bodyText={powerOnText} />
                    <CheckBoxGroupControlPanel
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="powerOn-operations"
                    />
                </div>
                <MeasurementFieldControlPanel propertyName="powerOn-operations" showValue={false} />

                <div className="ahutest-form__label-info-button--grid" >
                    <Label className="grid-item-label" >{t("Ballasti temperatuur")}</Label>
                    <InfoModal title={t("Juhend")} bodyText={ballastTemperatureText} />
                    <CheckBoxGroupControlPanel
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="ballast-operations"
                    />
                </div>
                <MeasurementFieldControlPanel propertyName="ballast-operations" showValue={false} />

                <div className="ahutest-form__label-info-button--grid" >
                    <Label className="grid-item-label" >{t("Tööoleku olek BIT0")}</Label>
                    <InfoModal title={t("Juhend")} bodyText={workBitText} />
                    <CheckBoxGroupControlPanel
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="workBit0-operations"
                    />
                </div>
                <MeasurementFieldControlPanel propertyName="workBit0-operations" showValue={false} />

                <div className="ahutest-form__label-info-button--grid" >
                    <Label className="grid-item-label" >{t("Hoolduse olek BIT1")}</Label>
                    <InfoModal title={t("Juhend")} bodyText={serviceText} />
                    <CheckBoxGroupControlPanel
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="serviceBit1-operations"
                    />
                </div>
                <MeasurementFieldControlPanel propertyName="serviceBit1-operations" showValue={false} />

                <div className="ahutest-form__label-info-button--grid" >
                    <Label className="grid-item-label" >{t("Vea olek BIT2")}</Label>
                    <InfoModal title={t("Juhend")} bodyText={errorStatusText} />
                    <CheckBoxGroupControlPanel
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="errorStatus-operations"
                    />
                </div>
                <MeasurementFieldControlPanel propertyName="errorStatus-operations" showValue={false} />

                <div className="ahutest-form__label-info-button--grid" >
                    <Label className="grid-item-label" >{t("LED alarm")}</Label>
                    <InfoModal title={t("Juhend")} bodyText={ledAlarmText} />
                    <CheckBoxGroupControlPanel
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="ledAlarm-operations"
                    />
                </div>
                <MeasurementFieldControlPanel propertyName="ledAlarm-operations" showValue={false} />
            </div>
        </form>
    )
}

export default UVSOperationCheckForm