import React from 'react'
import { useTranslation } from 'react-i18next';
import { Label } from 'reactstrap';
import { useTkalDataContext } from '../../../../context/tkalData';
import { returnDateFromDateString } from '../../CommonFunctions';

const OzoneGeneratorIdentity = () => {
    const { t } = useTranslation();
    const { ozonatorTest } = useTkalDataContext();

    return (
        <div className="flexboxAlignedColumn mainPageForm ahutest-container">
            <Label className="mainPageForm-label" style={{ marginTop: "5%" }}>{t("Seadme seerianumber")}</Label>
            <div className="flexboxAlignedRow" style={{ gap: "5%" }}>
                <input className="input-orderNr" style={{ width: "400px" }} readOnly={true} name="serialNumber" value={ozonatorTest.serialNumber ?? ""} placeholder={t("Automaatselt genereeritud")} />
            </div>
            <Label className="mainPageForm-label" style={{ marginTop: "5%" }}>{t("Artikkel")}</Label>
            <div className="flexboxAlignedRow" style={{ gap: "5%" }}>
                <input className="input-orderNr" style={{ width: "400px " }} readOnly={true} name="electricalSchemaVersion" value={ozonatorTest.productArticleNumber ?? ""} placeholder={t("Artikkel")} />
            </div>
            <Label className="mainPageForm-label" style={{ marginTop: "5%" }}>{t("Seade")}</Label>
            <div className="flexboxAlignedRow" style={{ gap: "5%" }}>
                <input className="input-orderNr" style={{ width: "400px " }} readOnly={true} type="text" name="softwareVersion" value={ozonatorTest.productName ?? ""} placeholder={t("Seade")} />
            </div>
            <Label className="mainPageForm-label" style={{ marginTop: "5%" }}>{t("Testi teostaja")}</Label>
            <div className="flexboxAlignedRow" style={{ gap: "5%" }}>
                <input className="input-orderNr" style={{ width: "400px " }} readOnly={true} type="text" name="softwareVersion" value={ozonatorTest.createdBy ?? ""} placeholder={t("Testi teostaja")} />
            </div>
            <Label className="mainPageForm-label" style={{ marginTop: "5%" }}>{t("Testi kuupäev")}</Label>
            <div className="flexboxAlignedRow" style={{ gap: "5%" }}>
                <input className="input-orderNr" style={{ width: "400px " }} readOnly={true} type="text" name="softwareVersion" value={returnDateFromDateString(ozonatorTest.createdDate.toString()) ?? ""} placeholder={t("Testi kuupäev")} />
            </div>
        </div>
    )
}

export default OzoneGeneratorIdentity