import React from 'react'
import { Label } from 'reactstrap';
import { useTranslation } from "react-i18next";
import MeasurementField from '../../MeasurementField';
import { CheckboxOptions, CheckboxDefaultValue } from '../../../ahu/common/checkboxConfig';
import CheckboxGroup from '../../CheckboxGroup';
import InfoModal from '../../InfoModal';
import { externalSignalsText, coolingCoilText, preHeaterControlText } from '../../ModalTexts';


const OtherChecksForm = () => {
    const { t } = useTranslation();
    return (
        <form className="ahutest-container" >
            <Label className="ahutest-from--title">{t("Other checks")}</Label>
            <div className="flexboxAlignedColumn" style={{ padding: "2%" }}>

                <div className="ahutest-form__label-info-button--grid"  >
                    <Label className="grid-item-label" >{t("Test external signals for outdoor and exhaust dampers")}</Label>
                    <InfoModal title={t("Juhend")} bodyText={externalSignalsText} />
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="damperSignal-other"
                    />
                </div>
                <MeasurementField properyName="damperSignal-other" showValue={false} />

                <div className="ahutest-form__label-info-button--grid"  >
                    <Label className="grid-item-label" >{t("Test cooling coil valve control signal")}</Label>
                    <InfoModal title={t("Juhend")} bodyText={coolingCoilText} />
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="valveSignal-other"
                    />
                </div>
                <MeasurementField properyName="valveSignal-other" showValue={false} />

                <div className="ahutest-form__label-info-button--grid"  >
                    <Label className="grid-item-label" >{t("Test external preheater control signal")}</Label>
                    <InfoModal title={t("Juhend")} bodyText={preHeaterControlText} />
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="preheaterSignal-other"
                    />
                </div>
                <MeasurementField properyName="preheaterSignal-other" showValue={false} />

            </div>
        </form>
    )
}

export default OtherChecksForm