import React, { useState } from 'react'
import { Button, Label } from 'reactstrap'
import '../../../styles/testForm.css'
import '../../../styles/select-order.css'
import { useAhuDataContext } from '../../../context/ahuData';
import { useTranslation } from 'react-i18next';
import { deleteAhuEstTest, deleteRecairAhuTest, getAhuEstTestForOrder, getPdf, getPurchaseOrderAhuEstonia, loadAhuEstTest } from '../../queries/testQueries';
import { PurchaseOrder, PurchaseOrderRowDto } from '../../../types/ahu-estonia/AhuPurchaseOrder';
import { AxiosResponse } from 'axios';
import SelectTestCategory from './SelectTestCategory';
import { IconButton } from '@mui/material';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { AhuTestEstonia } from '../../../types/ahu-estonia/AhuTestEstonia';
import saveAs from 'file-saver';
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import PrinterModal from '../../common/PrinterModal';
import { printProductCode } from '../../queries/adminQueries';
import ClearIcon from '@mui/icons-material/Clear';

const SelectOrder = () => {
    const { isLoading, setIsLoading, ahuTestDataEst, setAhuTestDataEst, ahuEstOrderData, setAhuEstOrderData, setItemNumber, setItemIndex, itemIndex, itemNumber, setOrderNumber, orderNumber, setTestType, setCreatedAhuEstTests, createdAhuEstTests } = useAhuDataContext();
    const { t } = useTranslation();
    const [showInput, setShowInput] = useState(false);

    const handleLoadPdf = (directoCode: string, rowNumber: string) => {

        if (directoCode === (null || undefined)) return;
        setIsLoading(true)
        getPdf(directoCode, rowNumber, "event").then((response) => {
            const filename = `AHU Test no. ${directoCode}.pdf`;
            saveAs(response.data, filename);
        })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const handleChangeLtoType = (event: any) => {
        setAhuTestDataEst({ ...ahuTestDataEst, "radiatorType": event.target.value })
    }

    const handleItemSelect = async (e: any, orderItemNumber: string, index: number, description: string, uniqueItemCode: string) => {
        e.preventDefault();
        if (!orderItemNumber) return alert("Please enter correct item number");
        setAhuTestDataEst({ ...ahuTestDataEst, itemNumber: orderItemNumber, itemName: description, uniqueItemNumber: uniqueItemCode, radiatorType: "", testType: "" })
        setItemNumber(orderItemNumber);
        setItemIndex(index);
        setTestType("")
        setCreatedAhuEstTests([])
    }

    const handleOrderSubmit = (e: any, orderNumber: string) => {
        e.preventDefault();
        if (orderNumber) {
            setIsLoading(true);
            getPurchaseOrderAhuEstonia(orderNumber)
                .then((response: AxiosResponse<PurchaseOrder>) => {
                    setAhuEstOrderData(response.data);
                    setAhuTestDataEst({ ...ahuTestDataEst, orderNumber: orderNumber, supplierName: response.data.supplierName, supplierCode: response.data.supplierCode, })
                    getAhuEstTestForOrder(orderNumber).then((res) => {
                        setCreatedAhuEstTests(res.data);
                    }).catch((err) => {
                        console.log(err.response.status)
                    }).finally(() => {
                        setIsLoading(false);
                    })
                })
                .catch(err => {
                    console.log(err);
                    if (err.response.status === 401 || err.response.status === 403) return alert("You dont have enough permissions to access this data")
                    if (err.response.status === 404) return alert("Ei leidnud ühtegi õiget toodet")
                })
                .finally(() => setIsLoading(false))

        }
    }

    const handleLoadTestData = (selectedTest: AhuTestEstonia) => {
        setIsLoading(true)
        if (selectedTest.id === null) return;
        loadAhuEstTest(selectedTest.id).then((res: AxiosResponse<AhuTestEstonia>) => {
            setAhuTestDataEst(res.data)
        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const handleOpenWebsite = (directoCode: string) => {
        window.open(`https://login.directo.ee/ocra_ets_nord/event.asp?KOOD=${directoCode}`, '_blank');
    }

    function returnDate(dateString: string) {
        if (dateString === null) return;
        const year = dateString.substring(0, 4);
        const month = dateString.substring(5, 7);
        const day = dateString.substring(8, 10);
        return `${day}.${month}.${year}`
    }

    const isPrintingAllowed = (item: PurchaseOrderRowDto) => {
        if (item?.productCode !== null && item?.elMeasurementsStatus === "Finished" && item?.inspectionLogStatus === "Finished") return true;
        else {
            return false;
        }
    }

    const handleRemoveTest = async (id: number) => {
        deleteAhuEstTest(id).then((res: any) => {
            getAhuEstTestForOrder(orderNumber!).then((response: any) => {
                setCreatedAhuEstTests(response.data)
            });
        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    return (
        <div className="flexboxAlignedColumn mainPageForm" style={{ marginTop: "3%" }}>
            <form className="flexboxAlignedColumn mainPageForm" style={{ marginTop: "10%" }} onSubmit={e => handleOrderSubmit(e, orderNumber ?? `${alert(t("ItemNotSelectedAlert"))}`)}>

                <div className="flexboxAlignedColumn" style={{ padding: "2%" }}>
                    <Label className="mainPageForm-label">{t("InsertOrder")}
                    </Label>
                    <div className="flexboxAlignedRow" style={{ gap: "5%" }}>
                        <input className="input-orderNr" type="number" onChange={e => setOrderNumber(e.target.value)} value={orderNumber ?? ""} placeholder={t("InsertNumber")} />
                        <Button className="button-primary" style={{ width: "94px" }} type="submit">{t("Search")}</Button>
                    </div>
                </div>

                {ahuEstOrderData.items && (
                    <React.Fragment key={ahuEstOrderData.orderNumber}>
                        <Label className="mainPageForm-label" style={{ marginTop: "2%" }}>{t("SelectItemToTest")}</Label>
                        <div className="orderData">
                            {
                                Object.values(ahuEstOrderData.items).map((x, idx) => {
                                    return <div className='select-order-form__grid' key={idx} >
                                        <PrinterModal item={x} isPrintingAllowed={isPrintingAllowed} printFunction={printProductCode} printFunctionArgs={[x.productCode]} defaultPrinter="Tootmine_AHU" defaultQuantity={2} />
                                        <div className={`select-order-grid__label${(x.itemCode === itemNumber && (idx === itemIndex)) ? " active" : ""}`}
                                            onClick={(e: any) => handleItemSelect(e, x.itemCode, idx, x.description, x.uniqueItemCode)}>
                                            <div className="itemData" >{x.uniqueItemCode} {x.description}</div>
                                        </div>
                                        <div className="select-order-grid__icon2"  >
                                            {x.elMeasurementsStatus === "Finished" ?
                                                <IconButton title="Elektriliste mõõdisituste staatus">
                                                    <DoneIcon style={{ fill: "green" }} />
                                                </IconButton> :
                                                <IconButton title="Elektriliste mõõdisituste staatus">
                                                    <CloseIcon style={{ fill: '#FF0000' }} />
                                                </IconButton>}
                                            {x.inspectionLogStatus === "Finished" ?
                                                <IconButton title="Kontrolllogi staatus">
                                                    <DoneIcon style={{ fill: "green" }} />
                                                </IconButton> :
                                                <IconButton title="Kontrolllogi staatus">
                                                    <CloseIcon style={{ fill: '#FF0000' }} />
                                                </IconButton>}
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                        {itemNumber && <SelectTestCategory testName={["electrical measurement", "inspection log"]} />}
                    </React.Fragment>
                )}

                {ahuTestDataEst.testType === "inspection log" &&
                    <>
                        <Label className="mainPageForm-label" style={{ marginTop: "5%" }}>{t("Vali küttekeha")}</Label>
                        <select className="mainPageForm-select" style={{ width: "50%" }} name="heaterType" title={t("Vali küttekeha")} placeholder={t("Vali küttekeha")} value={ahuTestDataEst.radiatorType || ""} onChange={(e: any) => handleChangeLtoType(e)}>
                            <option value="" disabled hidden>{t("Vali küttekeha")}</option>
                            <option value="waterRadiator">{t("Water radiator")}</option>
                            <option value="electricRadiator">{t("Electric radiator")}</option>
                        </select>
                    </>
                }
                {createdAhuEstTests && createdAhuEstTests.length > 0 ? (
                    <div className="orderData" style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>

                        {createdAhuEstTests.filter(x => x.testStatus === "Created").length > 0 &&
                            <>
                                <Label className="mainPageForm-label" style={{ marginTop: "5%" }}>{t("TestsInProgress")}</Label>
                                {createdAhuEstTests.filter(x => x.testStatus === "Created").map((x, idx) =>
                                    <div key={idx} style={{ height: "100%", minHeight: "70px", width: "80%", display: "flex", justifyContent: "center" }}>
                                        <div style={{ height: "100%", minHeight: "70px",/*  width: "70%", */ display: "flex", justifyContent: "center" }} className={`orderDataOrders${x.id === ahuTestDataEst.id ? " active" : ""}`}
                                            onClick={() => handleLoadTestData(x)}>{x.itemNumber} - {t(`${x.testType}`)} - {x.testPerformer} - {returnDate(x.startDate.toString())}
                                        </div>
                                        <IconButton style={{ marginTop: "4%" }} onClick={() => handleRemoveTest(x.id!)}>
                                            <ClearIcon />
                                        </IconButton>

                                    </div>)}
                            </>
                        }
                        {createdAhuEstTests.filter(x => x.testStatus === "Finished").length > 0 &&
                            <>
                                <Label className="mainPageForm-label" style={{ marginTop: "5%" }}>{t("TestsFinished")}</Label>

                                {createdAhuEstTests.filter(x => x.testStatus === "Finished").map((x, idx) =>
                                    <div key={idx} style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", gap: "25px" }}>

                                        <div key={idx} style={{ height: "100%", minHeight: "70px", width: "70%", display: "flex", justifyContent: "center" }} className={`orderDataOrders${x.id === ahuTestDataEst.id ? " active" : ""}`}
                                            onClick={() => handleLoadTestData(x)}>{x.itemNumber} - {t(`${x.testType}`)} - {x.testPerformer} - {returnDate(x.startDate.toString())}
                                        </div>

                                        <IconButton disabled={isLoading} style={{ marginTop: "4%", cursor: "pointer" }} onClick={() => handleLoadPdf(x.directoCode, "1643")}>
                                            <FileOpenIcon />
                                        </IconButton>
                                        <IconButton disabled={isLoading} style={{ marginTop: "4%", cursor: "pointer" }} onClick={() => handleOpenWebsite(x.directoCode)}>
                                            <OpenInNewIcon />
                                        </IconButton>
                                    </div>
                                )}
                            </>
                        }
                    </div>
                ) : null}
            </form>
        </div>
    )
}

export default SelectOrder

