import ThumbDown from '@mui/icons-material/ThumbDown';
import ThumbDownOffAltOutlined from '@mui/icons-material/ThumbDownOffAltOutlined';
import DoNotDisturb from '@mui/icons-material/DoNotDisturb';
import DoNotDisturbOutlined from '@mui/icons-material/DoNotDisturbOutlined';
import ThumbUpAltOutlined from '@mui/icons-material/ThumbUpAltOutlined';
import ThumbUp from '@mui/icons-material/ThumbUp';

interface CheckboxOption {
    value: string;
    icon: JSX.Element;
    checkedIcon: JSX.Element;
}

const checkboxOption1: CheckboxOption = { value: 'OK', icon: <ThumbUpAltOutlined fontSize='large' style={{ opacity: "0.5" }} />, checkedIcon: <ThumbUp fontSize='large' /> };
const checkboxOption2: CheckboxOption = { value: 'FAIL', icon: <ThumbDownOffAltOutlined fontSize='large' style={{ opacity: "0.5" }} />, checkedIcon: <ThumbDown fontSize='large' /> };
const checkboxOption3: CheckboxOption = { value: 'disabled', icon: <DoNotDisturbOutlined fontSize='large' style={{ opacity: "0.5" }} />, checkedIcon: <DoNotDisturb fontSize='large' /> };

export const CheckboxOptions: CheckboxOption[] = [
    checkboxOption1, checkboxOption2, checkboxOption3
];

export const CheckboxOptionsThumbsOnly: CheckboxOption[] = [
    checkboxOption1, checkboxOption2
];

export const CheckboxDefaultValue = 'thumbsDown--';