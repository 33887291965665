import '../../../../styles/testOverviewTable.css'
import { useDataContext } from '../../../../context/ductTestData';
import { useTranslation } from 'react-i18next';
import ReturnProgressBar from '../../../common/ReturnProgressBar';

const OverviewTestForm = (props: { children?: any }) => {
    const { testData, itemData } = useDataContext();
    const { t, i18n } = useTranslation();

    return (
        <div className="flexboxAlignedColumn mainPageForm">
            <ReturnProgressBar level="4" lang={i18n.language} />
            <table>
                <thead>
                    <tr>
                        <th style={{ fontSize: "40px" }}>{t("TestOverview")}</th>
                    </tr>
                </thead>
                <tbody >
                    <tr>
                        <td className="firstTd" style={{ width: "300px" }}>{t("OrderNumber")}</td>
                        <td style={{ textAlign: "left" }}>{testData.orderNumber}</td>
                    </tr>
                    <tr>
                        <td className="firstTd">{t("Item")}</td>
                        <td>{itemData.code !== undefined ? (itemData.code + " " + itemData.description) : ""}</td>
                    </tr>
                    <tr style={{ borderBottom: "2px solid white" }}>
                        <td className="firstTd">{t("TestType")}</td>
                        <td>{testData.testName ? t(`${testData.testName}`) : ""}</td>
                    </tr>
                    {props.children}
                    <tr>
                        <td className="firstTd">{t("Description")}</td>
                        <td>{testData.description}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}
export default OverviewTestForm 