import React from 'react'
import { useTranslation } from 'react-i18next';
import { useAhuDataContext } from '../../../context/ahuData';
import IsDisabled from './IsDisabled';

interface InputProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLInputElement>, HTMLInputElement> {

}
type MeasurementFieldProps = {
    properyName: string,
    unit?: string,
    placeholderKey?: string,
    inputProps?: InputProps,
    gap?: string,
    showValue?: boolean
}
export function MeasurementField({
    properyName,
    unit = "",
    placeholderKey = "Value",
    inputProps = { className: "ahutest-form__input --value" },
    gap = "25px",
    showValue = true
}: MeasurementFieldProps) {

    const { ahuTestDataEst, setAhuTestDataEst } = useAhuDataContext();
    const { t } = useTranslation();

    const handleAhuTestMeasurementsValue = (event: any, propertyName: string) => {
        setAhuTestDataEst({
            ...ahuTestDataEst,
            "measurements": { ...ahuTestDataEst.measurements, [event.target.name]: { ...ahuTestDataEst.measurements[event.target.name], [propertyName]: event.target.value } }
        })
    }

    const handleAhuTestMeasurementsValueUnit = (event: any, propertyName: string, unit: string) => {
        setAhuTestDataEst({
            ...ahuTestDataEst,
            "measurements": { ...ahuTestDataEst.measurements, [event.target.name]: { ...ahuTestDataEst.measurements[event.target.name], [propertyName]: event.target.value, unit: unit } }
        })
    }
    return (
        <>
            {IsDisabled(properyName) &&
                <div className="flexboxAlignedRow" style={{ gap: gap }}>
                    {showValue &&
                        <>
                            <input {...inputProps} name={properyName} onChange={e => handleAhuTestMeasurementsValueUnit(e, "value", unit)} value={ahuTestDataEst.measurements[properyName]?.value ?? ""} placeholder={t(placeholderKey)} />
                            {unit}</>}
                    <input className="ahutest-form__input" name={properyName} onChange={e => handleAhuTestMeasurementsValue(e, "description")} value={ahuTestDataEst.measurements[properyName]?.description ?? ""} placeholder={t("Notes")} />
                </div>}
        </>
    );
}

export default MeasurementField