import React from 'react'
import { useTranslation } from 'react-i18next';
import { Label } from 'reactstrap';
import { useTkalDataContext } from '../../../context/tkalData';

const OzonatorIdentityForm = () => {
    const { t } = useTranslation();
    const { ozonatorTest, setOzonatorTest } = useTkalDataContext();

    const handleChange = (e: any) => {
        setOzonatorTest({ ...ozonatorTest, [e.target.name]: e.target.value })
    }

    return (
        <div className="flexboxAlignedColumn mainPageForm ahutest-container">
            <Label className="mainPageForm-label" style={{ marginTop: "5%" }}>{t("Seadme seerianumber")}</Label>
            <div className="flexboxAlignedRow" style={{ gap: "5%" }}>
                <input className="input-orderNr" style={{ width: "300px" }} readOnly={true} name="serialNumber" onChange={e => handleChange(e)} value={ozonatorTest.serialNumber ?? ""} placeholder={t("Automaatselt genereeritud")} />
            </div>
            <Label className="mainPageForm-label" style={{ marginTop: "5%" }}>{t("Elektriskeemi versioon")}</Label>
            <div className="flexboxAlignedRow" style={{ gap: "5%" }}>
                <input className="input-orderNr" style={{ width: "300px " }} name="electricalSchemaVersion" onChange={e => handleChange(e)} value={ozonatorTest.electricalSchemaVersion ?? ""} placeholder={t("Electrical schema version")} />
            </div>
            <Label className="mainPageForm-label" style={{ marginTop: "5%" }}>{t("Programmi versioon")}</Label>
            <div className="flexboxAlignedRow" style={{ gap: "5%" }}>
                <input className="input-orderNr" style={{ width: "300px " }} type="text" name="softwareVersion" onChange={e => handleChange(e)} value={ozonatorTest.softwareVersion ?? ""} placeholder={t("Insert software version")} />
            </div>
        </div>
    )
}

export default OzonatorIdentityForm