import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Label } from "reactstrap";

type SelectTestTypeProps<T extends number> = {
    testType: T;
    handleTestType: (value: T) => void;
    enumType: { [key: string]: T | string };
};

const SelectTestType = <T extends number>({ handleTestType, testType, enumType }: SelectTestTypeProps<T>) => {
    const { t } = useTranslation();

    const testTypeOptions = Object.entries(enumType)
        .filter(([key, value]) => !isNaN(Number(value)) && Number(value) >= 1)
        .map(([key, value]) => ({
            label: key, // Assuming you have translation keys that match
            value: Number(value) as T, // Ensuring type safety
        }));

    if (testTypeOptions.length === 0) return <></>;

    return (
        <>
            <Label className="mainPageForm-label" style={{ marginTop: '5%' }}>
                {t('SelectTest')}
            </Label>
            {testTypeOptions.map((option) => (
                <Button
                    key={option.value}
                    name="testType"
                    className={`flexboxAlignedRow orderDataOrders button-selection-1 ${testType === option.value ? 'active' : ''}`}
                    style={{ minWidth: '250px', width: '57%' }}
                    onClick={() => handleTestType(option.value)}
                >
                    {option.label}
                </Button>
            ))}
        </>
    );
};

export default SelectTestType;
