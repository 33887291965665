import { AxiosResponse } from 'axios';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { Button, Label } from 'reactstrap';
import { useTkalDataContext } from '../../../context/tkalData';
import '../../../styles/testForm.css'
import { Kalorifeer, TEST_TYPE } from '../../../types/kalorifeer/Kalorifeer';
import { OrderItem, TkalPurchaseOrder } from '../../../types/kalorifeer/TkalPurchaseOrder';
import { getPurchaseOrderRequestTkal, getSavedKalorifeerTests, removeTkalUnfinishedTest, updateTkalTest } from '../../queries/testQueries';
import TkalTestCategory from './TkalTestCategory';
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import '../../../styles/select-order.css'
import PrinterModal from '../../common/PrinterModal';
import { printKalorifeerTestLabel } from '../../queries/adminQueries';

const SelectTkalOrder = () => {
    const { t } = useTranslation();
    const { orderData, setIsLoading, orderNumber, setOrderData, setOrderNumber, setItemNumber, tkalData, setTkalData, setSavedTests, savedTests } = useTkalDataContext();

    const handleGetOrderData = async (e: any) => {
        e.preventDefault();
        setIsLoading(true);

        if (!orderNumber) return alert(t("SalesorderNumberAlert"));
        getPurchaseOrderRequestTkal(orderNumber).then((res: AxiosResponse<TkalPurchaseOrder>) => {
            setOrderData(res.data);
            setTkalData({ ...tkalData, purchaseOrderNumber: orderNumber, supplierCode: res.data.supplierCode, supplierName: res.data.supplierName })
        }).catch((err) => {
            if (err.response.status === 401 || err.response.status === 403) return alert("You dont have enough permissions to access this data")
            else {
                alert("Did not find any items");
                console.log(err.response.data)
            }
        }).finally(() => {
            setIsLoading(false);
        })
    }

    const handleItemRequest = async (orderItemNumber: string, itemUniqueId: string, description: string) => {
        if (!orderItemNumber) return alert("Please enter correct item number");
        setItemNumber(orderItemNumber);
        setTkalData({ ...tkalData, productArticleNumber: orderItemNumber, productUniqueId: itemUniqueId, productName: description, testType: TEST_TYPE.EMPTY })
        if (!orderNumber) return null;
        getSavedKalorifeerTests(orderNumber, itemUniqueId).then((res: AxiosResponse<Kalorifeer[]>) => {
            const modifiedData = res.data.map((item) => {
                return {
                    ...item,
                    createdDate: new Date(item.createdDate)
                };
            });
            setSavedTests(modifiedData);
        }).catch(() => { }).finally(() => { })

    }

    const handleLoadTestData = (selectedTest: Kalorifeer) => {
        if (selectedTest.id === null) return;
        setTkalData(selectedTest)
    }

    const returnTestType = (testType: number) => {
        if (testType === 0) return "Tühi"
        if (testType === 1) return "Visual test"
        if (testType === 2) return "Leak test"
    }

    function returnDate(dateString: string) {
        if (dateString === null) return;
        const year = dateString.substring(0, 4);
        const month = dateString.substring(5, 7);
        const day = dateString.substring(8, 10);
        return `${day}.${month}.${year}`
    }


    const handleRemoveTest = (id: number, orderNumber: string, productUniqueId: string) => {
        removeTkalUnfinishedTest(id).then((res) => {
            getSavedKalorifeerTests(orderNumber, productUniqueId).then((res: AxiosResponse<Kalorifeer[]>) => {
                const modifiedData = res.data.map((item) => {
                    return {
                        ...item,
                        createdDate: new Date(item.createdDate)
                    };
                });
                setSavedTests(modifiedData);
            })
        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            setIsLoading(false)
        })
    }
    const isPrintingAllowed = (itemData: OrderItem) => {
        if (itemData?.leakTestStatus !== null && itemData?.visualTestStatus !== null) return true;
        return false;
    }
    return (
        <div className="flexboxAlignedColumn mainPageForm" style={{ marginTop: "3%" }}>
            <form className="flexboxAlignedColumn mainPageForm" style={{ marginTop: "10%" }} onSubmit={e => handleGetOrderData(e ?? `${alert(t("ItemNotSelectedAlert"))}`)}>

                <div className="flexboxAlignedColumn" style={{ padding: "2%" }}>
                    <Label className="mainPageForm-label">{t("InsertOrder")}
                    </Label>
                    <div className="flexboxAlignedRow" style={{ gap: "5%" }}>
                        <input className="input-orderNr" type="number" onChange={e => setOrderNumber(e.target.value)} value={orderNumber ?? ""} placeholder={t("InsertNumber")} />
                        <Button className="button-primary" style={{ width: "94px" }} type="submit">{t("Search")}</Button>
                    </div>
                </div>

                {orderData.orderItems && (
                    <React.Fragment key={orderData.orderNumber}>
                        <Label className="mainPageForm-label" style={{ marginTop: "2%" }}>{t("SelectItemToTest")}</Label>
                        <div className="orderData">
                            {
                                Object.values(orderData.orderItems).map((x, idx) => {
                                    return <div className='select-order-form__grid' key={idx}>
                                        <div>
                                            <PrinterModal item={x} isPrintingAllowed={isPrintingAllowed} printFunction={printKalorifeerTestLabel} printFunctionArgs={[x.id, orderNumber]} defaultPrinter="Tootmine_TKAL" />
                                            {/* <AddPictureModal item={x} isVisualTestCompleted={isPrintingAllowed} functionArgs={[x.id, orderNumber]} />*/}
                                        </div>
                                        <div className={`select-order-grid__label${(x.id === tkalData.productUniqueId) ? " active" : ""}`}
                                            onClick={() => handleItemRequest(x.itemCode, x.id, x.description)}>
                                            <div className="itemData" >{x.itemCode} {x.description}</div>
                                        </div>
                                        <div className="select-order-grid__icon2" >
                                            {x.leakTestStatus === "Finished" ?
                                                <IconButton title="Lekke testi staatus">
                                                    <DoneIcon style={{ fill: "green" }} />
                                                </IconButton> :
                                                <IconButton title="Lekke testi staatus">
                                                    <CloseIcon style={{ fill: '#FF0000' }} />
                                                </IconButton>}
                                            {x.visualTestStatus === "Finished" ?
                                                <IconButton title="Visuaalse testi staatus">
                                                    <DoneIcon style={{ fill: "green" }} />
                                                </IconButton> :
                                                <IconButton title="Visuaalse testi staatus">
                                                    <CloseIcon style={{ fill: '#FF0000' }} />
                                                </IconButton>}
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                        {/* Show only 'visuaalne test' when 'lekketest' is completed, otherwise show only 'lekketest' */}
                        {tkalData.productArticleNumber && <TkalTestCategory testName={[{ label: "Visuaalne test", val: 1 }, { label: "Lekke test", val: 2 }]} />}
                    </React.Fragment>
                )}

                {savedTests && savedTests.length > 0 ? (
                    <div className="orderData" style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>

                        {savedTests.filter(x => x.testStatus === "Saved").length > 0 &&
                            <>
                                <Label className="mainPageForm-label" style={{ marginTop: "5%" }}>Poolikud testid</Label>

                                {savedTests.filter(x => x.testStatus === "Saved").map((x, idx) =>
                                    <div key={idx} style={{ height: "100%", minHeight: "70px", width: "80%", display: "flex", justifyContent: "center" }}>
                                        <div style={{ height: "100%", minHeight: "70px", display: "flex", justifyContent: "center" }} className={`orderDataOrders${x.id === tkalData.id ? " active" : ""}`}
                                            onClick={() => handleLoadTestData(x)}>{x.productName} - {x.testName} - {x.createdBy} {returnDate(x.createdDate.toISOString())}
                                        </div>
                                        <IconButton style={{ marginTop: "4%" }} onClick={() => handleRemoveTest(x.id, x.purchaseOrderNumber, x.productUniqueId)}>
                                            <ClearIcon />
                                        </IconButton>
                                    </div>)}
                            </>
                        }
                        {savedTests.filter(x => x.testStatus === "Finished").length > 0 &&
                            <>
                                <Label className="mainPageForm-label" style={{ marginTop: "5%" }}>Lõpetatud testid</Label>

                                {savedTests.filter(x => x.testStatus === "Finished").map((x, idx) =>
                                    <div key={idx} style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", gap: "25px" }}>

                                        <div key={idx} style={{ height: "100%", minHeight: "70px", width: "70%", display: "flex", justifyContent: "center" }} className={`orderDataOrders${x.id === tkalData.id ? " active" : ""}`}
                                            onClick={() => handleLoadTestData(x)}>{x.productName} - {x.testName} - {x.finishedBy} {returnDate(x.finishedDate.toString())}
                                        </div>
                                    </div>
                                )}
                            </>
                        }
                    </div>
                ) : null}

            </form>
        </div>
    )
}

export default SelectTkalOrder