import '../../../../styles/testOverviewTable.css'
import { useDataContext } from '../../../../context/ductTestData';
import { useTranslation } from 'react-i18next';

const NtoOverviewTestForm = () => {
    const { testData } = useDataContext();
    const { t } = useTranslation();
    const { stiffnessProblem, rollWidthProblem, whiteRustProblem } = useDataContext();
    const showData = (prop: any, text: string) => {
        return prop && `${text} - ${prop}\n`
    }

    return (
        <>
            <tr>
                <td className="firstTd">{t("ItemOrder")}</td>
                <td>{testData.itemToTest ? t(`${testData.itemToTest}`) : ""}</td>
            </tr>
            <tr>
                <td className="firstTd">{t("Diameter")}</td>
                <td>{testData.diameter}</td>
            </tr>
            <tr>
                <td className="firstTd">{t("Length")}</td>
                <td>{testData.length}</td>
            </tr>
            <tr style={{ borderBottom: "2px solid white" }}>
                <td className="firstTd">{t("LengthTestResult")}</td>
                <td>
                    {testData.lengthTestResult === "Ok" ? t(`TestPassed`) : ""}
                    {testData.lengthTestResult === "NotOk" ? t(`TestFailed`) : ""}
                </td>
            </tr>
            <tr style={{ borderBottom: "2px solid white" }}>
                <td className="firstTd">{t("SmokeTest")}</td>
                <td>{testData.fileName ?? "-"}</td>
            </tr>

            {testData.testFileType === "rigidityTest" &&
                <>
                    <tr >
                        <td className="firstTd">{t("rigidityTest")}</td>
                        <td>{testData.rigidityTestResult}</td>
                    </tr>
                    <tr>
                        <td className="firstTd">{t("Kasutatud raskus (kg)")}</td>
                        <td>{testData.rigidityTestWeight}</td>
                    </tr>
                    <tr>
                        <td className="firstTd">{t("Läbipaine")} (mm)</td>
                        <td>{testData.measuredDeflection}</td>
                    </tr>
                    <tr style={{ borderBottom: "2px solid white" }}>
                        <td className="firstTd">{t("Deformatsioon")} (mm)</td>
                        <td>{testData.residualDeformation}</td>
                    </tr>
                </>
            }
            <tr>
                <td className="firstTd">{t("BatchID")}</td>
                <td>{testData.batchId}</td>
            </tr>
            <tr>
                <td className="firstTd">{t("MaterialThickness")}</td>
                <td>{testData.materialThickness}</td>
            </tr>
            <tr style={{ borderBottom: "2px solid white" }}>
                <td className="firstTd">{t("MaterialStatus")}</td>
                <td>
                    {rollWidthProblem && <tr className="materialStatusTd">{`${showData(rollWidthProblem, "Roll width ")}`}</tr>}
                    {whiteRustProblem && <tr className="materialStatusTd">{`${showData(whiteRustProblem, "White rust ")}`}</tr>}
                    {stiffnessProblem && <tr className="materialStatusTd">{`${showData(stiffnessProblem, "Stiffness ")}`}</tr>}
                </td>
            </tr>
        </>
    )
}
export default NtoOverviewTestForm 