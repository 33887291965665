import React from 'react'
import { useTranslation } from 'react-i18next';
import { Label } from 'reactstrap';
import InfoModal from '../ahu-estonia/InfoModal';
import { CheckboxDefaultValue, CheckboxOptions } from '../ahu/common/checkboxConfig';
import CheckboxGroupOzonator from './common/CheckboxGroupOzonator';
import MeasurementFieldOzonator from './common/MeasurementFieldOzonator';

const OzonatorReinstallSoftware = () => {
    const { t } = useTranslation();

    return (
        <form className="ahutest-container" >
            <Label className="ahutest-from--title">{t("Peale testi")}</Label>
            <div className="flexboxAlignedColumn" style={{ padding: "2%" }}>
                <div className="ahutest-form__label-info-button--grid" >
                    <Label className="grid-item-label" >{t("Osonaatori tarkvara on uuesti peale pandud")}</Label>
                    <InfoModal title={t("Juhend")} bodyText={"Pane osonaatorile peale programm mis on leitav serverist."} />
                    <CheckboxGroupOzonator
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="reinstall-afterTest"
                    />
                </div>
                <MeasurementFieldOzonator propertyName="reinstall-afterTest" showValue={false} />
            </div>
        </form>
    )
}

export default OzonatorReinstallSoftware