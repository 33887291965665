import { Checkbox } from '@mui/material';
import React from 'react';
import { useTkalDataContext } from '../../../../context/tkalData';
import '../../../../styles/ahu-test.css'

interface CheckboxOption {
    value: string;
    icon: JSX.Element;
    checkedIcon: JSX.Element;
}

interface CheckboxGroupProps {
    options: CheckboxOption[];
    defaultValue: string;
    measurementKey: string;
}

const CheckBoxGroupControlPanel = ({ options, defaultValue, measurementKey }: CheckboxGroupProps) => {
    const { controlPanelTest, setControlPanelTest } = useTkalDataContext();

    const handleChange = (value: string, key: string) => {
        setControlPanelTest({
            ...controlPanelTest,
            "measurements": { ...controlPanelTest.measurements, [key]: { ...controlPanelTest.measurements[key], "statusNew": value } }
        })
    }
    return (
        <>
            {options.map((option, idx) => (

                <Checkbox
                    className={`grid-item-icon${idx + 1}`}
                    key={option.value}
                    icon={option.icon}
                    checkedIcon={option.checkedIcon}
                    checked={controlPanelTest.measurements[measurementKey]?.statusNew === option.value}
                    onChange={() => handleChange(option.value, measurementKey)}
                />
            ))}
        </>
    );
}

export default CheckBoxGroupControlPanel;
