import React from 'react'
import { Label } from 'reactstrap'
import { useTranslation } from "react-i18next";
import MeasurementField from '../MeasurementField';
import { CheckboxOptions, CheckboxDefaultValue } from '../../ahu/common/checkboxConfig';
import CheckboxGroup from '../CheckboxGroup';

const ExhaustFanForm = () => {
    const { t } = useTranslation();
    return (
        <form className="ahutest-container" >
            <Label className="ahutest-from--title">{t("Exhaust fan")}</Label>

            <div className="flexboxAlignedColumn" style={{ padding: "2%" }}>
                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Visual inspection")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="visualInspection-exFan"
                    />
                </div>
                <MeasurementField properyName="visualInspection-exFan" showValue={false} />

                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Flowgrid is installed")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="flowGrid-exFan"
                    />
                </div>
                <MeasurementField properyName="flowGrid-exFan" showValue={false} />

                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Wiring is done correctly")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="wiring-exFan"
                    />
                </div>
                <MeasurementField properyName="wiring-exFan" showValue={false} />

            </div>
        </form>
    )
}

export default ExhaustFanForm