import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Label } from "reactstrap";
import { useTkalDataContext } from "../../../context/tkalData";

type Props = {
  testName: string[];
  testType: string | undefined;
  handleTestType: (value: any) => void;
};
const AhuTestCategory = ({
  testName,
  handleTestType,
  testType,
}: Props) => {
  const { t } = useTranslation();
  /*  const { ozItemData } = useTkalDataContext() */
  if (testName[0] === (undefined || "")) return <></>;
  return (
    <React.Fragment>
      {testName[0] === "unknown" ? (
        <div className="message-testName-unkown">No suitable test found</div>
      ) : (
        <>
          <Label className="mainPageForm-label" style={{ marginTop: "5%" }}>
            {t("SelectTest")}
          </Label>

          {testName.map((value, idx) => (
            <Button
              key={idx}
              name="testType"
              className={`flexboxAlignedRow orderDataOrders button-selection-1 ${testType === value ? "active" : ""
                }`}
              style={{ minWidth: "250px", width: "57%" }}
              onClick={() => handleTestType(value)}
            >
              {t(`${value}`)}
            </Button>
          ))}
        </>
      )}
    </React.Fragment>
  );
};
export default AhuTestCategory;
