import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Label } from 'reactstrap'
import { useDataContext } from '../../../../context/ductTestData'

const TestCategoryState = (props: { testName: string[] }) => {
    const { t } = useTranslation();
    const { handleDynamicClick, testData } = useDataContext();

    if (props.testName[0] === (undefined || "")) return <></>;

    return (
        <React.Fragment>
            {props.testName[0] === "unknown" ?
                <div className="message-testName-unkown" >
                    No suitable test found
                </div>
                :
                (
                    <>
                        <Label className="mainPageForm-label" style={{ marginTop: "5%" }}>
                            {t("SelectTest")}
                        </Label>

                        {props.testName.map((value, idx) =>
                            <Button key={idx} name="testName" className={`flexboxAlignedRow orderDataOrders button-selection-1 ${testData.testName === value ? "active" : ""}`} style={{ minWidth: "250px" }} onClick={(event: any) => handleDynamicClick(event, value)}>
                                {value}
                            </Button>
                        )}

                    </>
                )
            }
        </React.Fragment>
    )
}
export default TestCategoryState