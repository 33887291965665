import { useTranslation } from "react-i18next";
import { Label } from 'reactstrap'
import { useAhuDataContext } from "../../../../context/ahuData";

const IdentityFormElectrical = () => {

    const { t } = useTranslation();
    const { electricalMeasurementData, handleCommonData, salesOrderData, itemDescription } = useAhuDataContext();

    return (
        <div className="flexboxAlignedColumn mainPageForm ahutest-container">
            <Label className="mainPageForm-label" style={{ marginTop: "5%" }}>{t("Target")}</Label>
            <div className="flexboxAlignedRow" style={{ gap: "5%" }}>
                <input className="input-orderNr" style={{ width: "300px " }} type="text" name="target" onChange={e => handleCommonData(e)} value={electricalMeasurementData.commonTestData.target ?? salesOrderData.comment} placeholder="Insert target" />
            </div>
            <Label className="mainPageForm-label" style={{ marginTop: "5%" }}>{t("JobNumber")}</Label>
            <div className="flexboxAlignedRow" style={{ gap: "5%" }}>
                <input className="input-orderNr" style={{ width: "300px " }} name="jobNumber" onChange={e => handleCommonData(e)} value={electricalMeasurementData.commonTestData.jobNumber ?? salesOrderData.recairProjectNr} placeholder="Insert job number" />
            </div>
            <Label className="mainPageForm-label" style={{ marginTop: "5%" }}>{t("MachineId")}</Label>
            <div className="flexboxAlignedRow" style={{ gap: "5%" }}>
                <input className="input-orderNr" style={{ width: "300px " }} name="machineId" onChange={e => handleCommonData(e)} value={electricalMeasurementData.commonTestData.machineId ?? itemDescription} placeholder="Insert machine ID" />
            </div>
        </div>
    )
}

export default IdentityFormElectrical