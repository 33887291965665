import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import { CreateControlPanelTest, FinishControlPanelTest, SaveControlPanelTest } from '../../components/queries/testQueries';
import { printProductCode } from '../../components/queries/adminQueries';
import { useTkalDataContext } from '../../context/tkalData';
import { IconButton } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import { ControlPanelTest, ControlPanelCreateTestDto, ControlPanelTestType } from '../../types/control-panel/ControlPanelTest';
import SelectControlPanel from '../../components/configurationForms/control-panel/SelectControlPanel';
import ControlPanelIdentityForm from '../../components/configurationForms/control-panel/ControlPanelIdentityForm';
import ControlPanelCasingForm from '../../components/configurationForms/control-panel/ControlPanelCasingForm';
import UVSPreCheckForm from '../../components/configurationForms/control-panel/uvl/UVSPreCheckForm';
import UVLPreCheckForm from '../../components/configurationForms/control-panel/uvs/UVLPreCheckForm';
import ControlPanelHardwareForm from '../../components/configurationForms/control-panel/ControlPanelHardwareForm';
import UVSOperationCheckForm from '../../components/configurationForms/control-panel/uvl/UVSOperationCheckForm';
import UVLOperationCheckForm from '../../components/configurationForms/control-panel/uvs/UVLOperationCheckForm';
import ControlPanelPackageForm from '../../components/configurationForms/control-panel/ControlPanelPackageForm';
import ControlPanelAfterTestForm from '../../components/configurationForms/control-panel/ControlPanelAfterTestForm';
import '../../styles/buttons.css'
import HomeIcon from '@mui/icons-material/Home';
import { OzonatorPurchaseOrder } from '../../types/directo/OzonatorPurchaseOrder';

const ControlPanelTestPage = () => {
    const { t } = useTranslation();
    const { setIsLoading, isLoading, setTestType, setItemNumber, setSavedOzTests, setOzOrderData, defaultPrinter, controlPanelTest, setControlPanelTest } = useTkalDataContext();
    const [step, setStep] = useState(1);
    const uvsTestReportSteps = 8;
    const uvlTestReportSteps = 8;

    const chooseFirstStepButtonKey = () => {
        if (step === 1) {
            if (controlPanelTest.id && controlPanelTest.testStatus !== "") return t("Open")
            if (controlPanelTest.id && controlPanelTest.testStatus === "") return t("Next")
            return t("Next")
        }
        if (step > 1) return t("Next")
    }

    const handleCreateTest = () => {
        setIsLoading(true)

        if (controlPanelTest.testType !== ControlPanelTestType.EMPTY) {
            var createDto: ControlPanelCreateTestDto = {
                purchaseOrder: controlPanelTest.purchaseOrder,
                productArticleNumber: controlPanelTest.productArticleNumber, productName: controlPanelTest.productName, productUniqueId: controlPanelTest.productUniqueId, testType: controlPanelTest.testType
            }
            CreateControlPanelTest(createDto).then((res) => {
                setControlPanelTest(res.data)
                setStep(step => step + 1)
            }).catch((err) => {
                console.log(err.response.data);
                alert(err.response.data)
            }).finally(() => {
                setIsLoading(false)
            })
        }
    }

    const handleNextButton = () => {
        if (step >= 2) {
            handleSaveTestData()
        }
        if (controlPanelTest.testType === ControlPanelTestType.EMPTY) {
            alert("Select test type")
            return;
        }
        if (step === 1 && controlPanelTest.id === (null || undefined || 0)) {

            handleCreateTest()
            return;
        }
        else {
            setStep(step => step + 1)
            return;
        }
    }

    const handleBackButton = () => {
        if (step >= 2) {
            setStep(step => step - 1)
        }
    }

    const validateNextStep = () => {
        if (step < uvsTestReportSteps && controlPanelTest.testType === ControlPanelTestType.UVSTESTREPORT) {
            return true;
        }
        if (step < uvlTestReportSteps && controlPanelTest.testType === ControlPanelTestType.UVLTESTREPORT) {
            return true;
        }
        return false;
    }

    const validateLastStep = () => {
        if (step === uvsTestReportSteps && controlPanelTest.testType === ControlPanelTestType.UVSTESTREPORT) {
            return true;
        }
        if (step === uvlTestReportSteps && controlPanelTest.testType === ControlPanelTestType.UVLTESTREPORT) {
            return true;
        }
        return false;
    }

    const handleSendTest = async () => {
        setIsLoading(true)
        try {
            if (controlPanelTest.serialNumber !== null) {
                await printProductCode(1, defaultPrinter, controlPanelTest.serialNumber, "OzProductCodeLabel")
            }
            await SaveControlPanelTest(controlPanelTest)
            await FinishControlPanelTest(controlPanelTest)
        } catch (err: any) {
            console.log(err.response.data);

        } finally {
            setControlPanelTest({ measurements: {} } as ControlPanelTest);
            setOzOrderData({} as OzonatorPurchaseOrder)
            setSavedOzTests([])
            setTestType(0)
            setItemNumber("")
            setStep(1);
            alert("Success")
            setIsLoading(false);
        }
    }

    const handleSaveTestData = () => {
        try {
            setIsLoading(true)
            SaveControlPanelTest(controlPanelTest);
        }
        catch (err: any) {
            console.log(err.response.data);
            setIsLoading(false);
            return;
        }
        finally {
            setIsLoading(false);
        }
    }

    return (
        <div className="mainPageLayout">
            {
                (() => {
                    if (step === 1) {
                        return <SelectControlPanel />
                    }
                    switch (controlPanelTest.testType) {
                        case ControlPanelTestType.UVSTESTREPORT:
                            switch (step) {
                                case 2:
                                    return <ControlPanelIdentityForm />
                                case 3:
                                    return <ControlPanelCasingForm />
                                case 4:
                                    return <UVSPreCheckForm />
                                case 5:
                                    return <ControlPanelHardwareForm />
                                case 6:
                                    return <UVSOperationCheckForm />
                                case 7:
                                    return <ControlPanelAfterTestForm />
                                case 8:
                                    return <ControlPanelPackageForm />
                            }
                            break;
                        case ControlPanelTestType.UVLTESTREPORT:
                            switch (step) {
                                case 2:
                                    return <ControlPanelIdentityForm />
                                case 3:
                                    return <ControlPanelCasingForm />
                                case 4:
                                    return <UVLPreCheckForm />
                                case 5:
                                    return <ControlPanelHardwareForm />
                                case 6:
                                    return <UVLOperationCheckForm />
                                case 7:
                                    return <ControlPanelAfterTestForm />
                                case 8:
                                    return <ControlPanelPackageForm />
                            }
                            break;
                    }
                })()
            }

            <div className="navigation-buttons-container" >
                {step >= 2 && <Button className="button-primary" onClick={handleBackButton}>{t("Back")}</Button>}

                {step > 1 &&
                    <>
                        <IconButton onClick={() => setStep(1)}>
                            <HomeIcon />
                        </IconButton>
                        <IconButton onClick={() => handleSaveTestData()}>
                            <SaveIcon />
                        </IconButton>
                    </>
                }
                {validateNextStep() && <Button className="button-primary" onClick={handleNextButton}>{chooseFirstStepButtonKey()}</Button>}
                {validateLastStep() && <Button className="button-primary" style={{ width: "170px", backgroundColor: "white", color: "red" }} disabled={isLoading || controlPanelTest.testStatus === "Finished"} onClick={handleSendTest}>{t("ConfirmAndSend")} </Button>}
            </div>
        </div>

    )
}

export default ControlPanelTestPage