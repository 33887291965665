import { AxiosResponse } from "axios";
import { createContext, useContext, useState } from "react";
import Loading from "../components/common/Loading";
import { getAllOrderTests, getItemRequest, getSalesOrderRequest } from "../components/queries/testQueries";
import { AhuElectricalMeasurements } from "../types/ahu/AhuElectricalMeasurements";
import { CommonTestData } from "../types/ahu/CommonTestData";
import { Item } from "../types/directo/Item";
import { SalesOrder } from "../types/directo/SalesOrder";
import { useTranslation } from "react-i18next";
import { AhuTestEstonia } from "../types/ahu-estonia/AhuTestEstonia";
import { PurchaseOrder } from "../types/ahu-estonia/AhuPurchaseOrder";

interface props {
    electricalMeasurementData: AhuElectricalMeasurements,
    setElectricalMeasurementData: React.Dispatch<React.SetStateAction<AhuElectricalMeasurements>>,
    salesOrderData: SalesOrder,
    setSalesOrderData: React.Dispatch<React.SetStateAction<SalesOrder>>,
    setItemData: React.Dispatch<React.SetStateAction<Item>>,
    itemData: Item,
    setOrderNumber: React.Dispatch<React.SetStateAction<string | undefined>>,
    orderNumber: string | undefined,
    itemNumber: string | undefined,
    setItemNumber: React.Dispatch<React.SetStateAction<string | undefined>>,
    isLoading: boolean,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    createdTests: AhuElectricalMeasurements[] | undefined
    setCreatedTests: React.Dispatch<React.SetStateAction<AhuElectricalMeasurements[] | undefined>>,
    itemIndex: number | undefined,
    setItemIndex: React.Dispatch<React.SetStateAction<number | undefined>>,
    testType: string | undefined,
    setTestType: React.Dispatch<React.SetStateAction<string | undefined>>,
    itemDescription: string | undefined,

    ahuTestDataEst: AhuTestEstonia,
    setAhuTestDataEst: React.Dispatch<React.SetStateAction<AhuTestEstonia>>,
    ahuEstOrderData: PurchaseOrder,
    setAhuEstOrderData: React.Dispatch<React.SetStateAction<PurchaseOrder>>,
    createdAhuEstTests: AhuTestEstonia[] | undefined,
    setCreatedAhuEstTests: React.Dispatch<React.SetStateAction<AhuTestEstonia[] | undefined>>,

    handleAhuTestMeasurementsValue: (event: any, propertyName: string) => void,
    handleAhuTestMeasurementsBool: (event: any) => void,
    handleCommonData: (event: any) => void,
    handleDynamicChangeElectricalMeasurement: (event: any) => void,
    handleGetOrderData: (e: any) => Promise<void>,
    handleItemRequest: (e: any, orderItemNumber: string, index: number, snCode: string) => Promise<void>,
    handleAhuTestMeasurementsValueUnit: (event: any, propertyName: string, unit: string) => void,
}

export const AhuDataContext = createContext({} as props);

export const useAhuDataContext = () => useContext(AhuDataContext);

export const AhuDataContextWrapper = ({ children }: any) => {
    const [electricalMeasurementData, setElectricalMeasurementData] = useState<AhuElectricalMeasurements>({ commonTestData: {} as CommonTestData, measurements: {} } as AhuElectricalMeasurements);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [salesOrderData, setSalesOrderData] = useState<SalesOrder>({} as SalesOrder);
    const [itemData, setItemData] = useState<Item>({} as Item);
    const [orderNumber, setOrderNumber] = useState<string>();
    const [itemNumber, setItemNumber] = useState<string>();
    const [createdTests, setCreatedTests] = useState<AhuElectricalMeasurements[]>();
    const [itemIndex, setItemIndex] = useState<number>();
    const [testType, setTestType] = useState<string>();
    const [itemDescription, setItemDescription] = useState<string>();

    const [ahuTestDataEst, setAhuTestDataEst] = useState<AhuTestEstonia>({ measurements: {} } as AhuTestEstonia);
    const [ahuEstOrderData, setAhuEstOrderData] = useState<PurchaseOrder>({} as PurchaseOrder);
    const [createdAhuEstTests, setCreatedAhuEstTests] = useState<AhuTestEstonia[]>();
    const { t } = useTranslation();

    const handleGetOrderData = async (e: any) => {
        e.preventDefault();
        setIsLoading(true);

        if (!orderNumber) return alert(t("SalesorderNumberAlert"));
        getSalesOrderRequest(orderNumber).then((res: AxiosResponse<SalesOrder>) => {
            setSalesOrderData(res.data);
            setElectricalMeasurementData({ ...electricalMeasurementData, orderNumber: orderNumber, commonTestData: { ...electricalMeasurementData.commonTestData, target: res.data.comment, jobNumber: res.data.recairProjectNr } })

            getAllOrderTests(orderNumber).then((res: AxiosResponse<any[]>) => {
                setCreatedTests(res.data);
            }).catch((err) => {
                if (err.response.status === 401 || err.response.status === 403) return alert("You dont have enough permissions to access this data")
                alert(err.response.data)
            }).finally(() => {
                setIsLoading(false);
            })
        }).catch((err) => {
            if (err.response.status === 401 || err.response.status === 403) return alert("You dont have enough permissions to access this data")
            alert(err.response.data)
        }).finally(() => {
            setIsLoading(false);
        })
    }

    const handleItemRequest = async (e: any, orderItemNumber: string, index: number, snCode: string) => {
        e.preventDefault();
        if (!orderItemNumber) return alert("Please enter correct item number");
        setItemIndex(index);
        setItemNumber(orderItemNumber);
        setIsLoading(true);
        setCreatedTests([])
        /* set test type in electrical measurement data */
        setElectricalMeasurementData(prev => ({ ...prev, testType: "", itemDescription: salesOrderData.items[index].description }))
        setTestType("")
        setItemDescription(salesOrderData.items[index].description)
        getItemRequest(orderItemNumber).then((response: AxiosResponse<Item>) => {
            setItemData(response.data);
            setElectricalMeasurementData({ ...electricalMeasurementData, itemNumber: orderItemNumber, itemDescription: salesOrderData.items[index].description, snCode: snCode, testType: "" })
        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            setIsLoading(false)
        });
    }

    const handleDynamicChangeElectricalMeasurement = (event: any) => {
        setElectricalMeasurementData({
            ...electricalMeasurementData,
            "measurements": { ...electricalMeasurementData.measurements, [event.target.name]: event.target.value }
        })
    }

    const handleCommonData = (event: any) => {
        setElectricalMeasurementData({
            ...electricalMeasurementData,
            "commonTestData": { ...electricalMeasurementData.commonTestData, [event.target.name]: event.target.value }
        })
    };
    const handleAhuTestMeasurementsBool = (event: any) => {

        if (event.target.checked) {
            setElectricalMeasurementData({
                ...electricalMeasurementData,
                "measurements": { ...electricalMeasurementData.measurements, [event.target.name]: { ...electricalMeasurementData.measurements[event.target.name], "status": true } }
            })

        } else {
            setElectricalMeasurementData({
                ...electricalMeasurementData,
                "measurements": { ...electricalMeasurementData.measurements, [event.target.name]: { ...electricalMeasurementData.measurements[event.target.name], "status": false } }
            })
        }
    }

    const handleAhuTestMeasurementsValue = (event: any, propertyName: string) => {
        setElectricalMeasurementData({
            ...electricalMeasurementData,
            "measurements": { ...electricalMeasurementData.measurements, [event.target.name]: { ...electricalMeasurementData.measurements[event.target.name], [propertyName]: event.target.value } }
        })
    }

    const handleAhuTestMeasurementsValueUnit = (event: any, propertyName: string, unit: string) => {
        setElectricalMeasurementData({
            ...electricalMeasurementData,
            "measurements": { ...electricalMeasurementData.measurements, [event.target.name]: { ...electricalMeasurementData.measurements[event.target.name], [propertyName]: event.target.value, unit: unit } }
        })
    }

    return (
        <AhuDataContext.Provider value={{
            electricalMeasurementData, setElectricalMeasurementData, handleCommonData, handleDynamicChangeElectricalMeasurement,
            itemData, setItemData, salesOrderData, setSalesOrderData, handleItemRequest, handleGetOrderData, orderNumber, setOrderNumber, itemNumber, setItemNumber, isLoading, setIsLoading, setCreatedTests, createdTests, itemIndex,
            setItemIndex, handleAhuTestMeasurementsValue, handleAhuTestMeasurementsBool, testType, setTestType, itemDescription, handleAhuTestMeasurementsValueUnit, ahuTestDataEst, setAhuTestDataEst, ahuEstOrderData, setAhuEstOrderData,
            createdAhuEstTests, setCreatedAhuEstTests
        }}>
            <>
                {isLoading && <Loading />}
                {children}
            </>
        </AhuDataContext.Provider>
    );
};