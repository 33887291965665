import React from 'react'
import { useTranslation } from 'react-i18next'
import { Label } from 'reactstrap'
import { CheckboxDefaultValue, CheckboxOptions } from '../common/checkboxConfig'
import CheckboxGroup from '../common/CheckboxGroup'
import { MeasurementField } from '../common/MeasurementField'

const CoolerTestForm2 = () => {
    const { t } = useTranslation();

    return (
        <form className="ahutest-container" >
            <Label className="ahutest-from--title">{t("Cooler test protocol")}</Label>
            <div className="flexboxAlignedColumn" style={{ padding: "2%" }}>

                <Label className="ahutest-cooler-from__label">{t("Outdoor temperature measurement")}</Label>
                <div className="ahutest-form__label--grid2">
                    <Label className="grid-item-label" >{t("Outdoor temperature limit for the visit permit")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="limit-outDoorTemp"
                    />
                </div>
                <MeasurementField properyName='limit-outDoorTemp' unit='°C' gap="10px" />

                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Immobilizer tested")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="immo-outDoorTemp"
                    />
                </div>
                <MeasurementField properyName='immo-outDoorTemp' showValue={false} />

                <Label className="ahutest-cooler-from__label">{t("Evaporator differential pressure measurement")}</Label>
                <div className="ahutest-form__label--grid2" style={{ gap: "10px" }}>
                    <Label className="grid-item-label" >{t("Pressure loss limit of operating permit")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="pressureLoss-evap"
                    />
                </div>
                <MeasurementField properyName='pressureLoss-evap' unit='Pa' gap="10px" />

                <div className="ahutest-form__label--grid2" style={{ gap: "10px" }}>
                    <Label className="grid-item-label" >{t("Immobilizer tested")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="immo-evap"
                    />
                </div>
                <MeasurementField properyName='immo-evap' showValue={false} />

                <Label className="ahutest-cooler-from__label">{t("Compressor")}</Label>
                <div className="ahutest-form__label--grid2" style={{ gap: "10px" }}>
                    <Label className="grid-item-label" >{t("Maximum adjustment value")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="maxValue-comp"
                    />
                </div>
                <MeasurementField properyName='maxValue-comp' unit='%' gap="10px" />

                <div className="ahutest-form__label--grid2" style={{ gap: "10px" }}>
                    <Label className="grid-item-label" >{t("The compressor stops at suction pressure")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="suction-comp"
                    />
                </div>
                <MeasurementField properyName='suction-comp' unit='bar' gap="10px" />

                <Label className="ahutest-cooler-from__label">{t("Measurements with maximum power")}</Label>
                <div className="ahutest-form__label--grid2" style={{ gap: "10px" }}>
                    <Label className="grid-item-label" >{t("Air temperature before the evaporator")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="airTempAfter-maxPower"
                    />
                </div>
                <MeasurementField properyName='airTempAfter-maxPower' unit='°C' gap="10px" />

                <div className="ahutest-form__label--grid2" style={{ gap: "10px" }}>
                    <Label className="grid-item-label" >{t("Air temperature after the evaporator")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="airTempBefore-maxPower"
                    />
                </div>
                <MeasurementField properyName='airTempBefore-maxPower' unit='°C' gap="10px" />

                <div className="ahutest-form__label--grid2" style={{ gap: "10px" }}>
                    <Label className="grid-item-label" >{t("Inlet air flow")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="inletAir-maxPower"
                    />
                </div>
                <MeasurementField properyName='inletAir-maxPower' unit='l/s' gap="10px" />

                <div className="ahutest-form__label--grid2" style={{ gap: "10px" }}>
                    <Label className="grid-item-label" >{t("Compressor current (measurement before frequency converter)")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="compCurrent-maxPower"
                    />
                </div>
                <MeasurementField properyName='compCurrent-maxPower' unit='A' gap="10px" />

                <div className="ahutest-form__label--grid2" style={{ gap: "10px" }}>
                    <Label className="grid-item-label" >{t("Adjustment value of the expansion valve")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="adjust-maxPower"
                    />
                </div>
                <MeasurementField properyName='adjust-maxPower' unit='%' gap="10px" />

                <div className="ahutest-form__label--grid2" style={{ gap: "10px" }}>
                    <Label className="grid-item-label" >{t("Fever")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="fever-maxPower"
                    />
                </div>
                <MeasurementField properyName='fever-maxPower' unit='K' gap="10px" />

                <div className="ahutest-form__label--grid2" style={{ gap: "10px" }}>
                    <Label className="grid-item-label" >{t("Power limitation function tested")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="powerLimit-maxPower"
                    />
                </div>
                <MeasurementField properyName='powerLimit-maxPower' showValue={false} />

                <div className="ahutest-form__label--grid2" style={{ gap: "10px" }}>
                    <Label className="grid-item-label" >{t("Humidity of the surrounding air")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="humidity-maxPower"
                    />
                </div>
                <MeasurementField properyName='humidity-maxPower' unit='%' gap="10px" />

            </div>
        </form>
    )
}

export default CoolerTestForm2