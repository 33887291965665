import React from 'react'
import { useTranslation } from 'react-i18next';
import { Label } from 'reactstrap';
import InfoModal from '../ahu-estonia/InfoModal';
import { CheckboxDefaultValue, CheckboxOptions } from '../ahu/common/checkboxConfig';
import CheckBoxGroupControlPanel from './common/CheckBoxGroupControlPanel';
import MeasurementFieldControlPanel from './common/MeasurementFieldControlPanel';


const ControlPanelHardwareForm = () => {
    const { t } = useTranslation();

    return (
        <form className="ahutest-container" >
            <Label className="ahutest-from--title">{t("Riistvara ja tarkvara ühilduvus")}</Label>
            <div className="flexboxAlignedColumn" style={{ padding: "2%" }}>
                <div className="ahutest-form__label-info-button--grid" >
                    <Label className="grid-item-label" >{t("UV juhtimiskilp saab toite kui ühendada vooluvõrku")}</Label>
                    <CheckBoxGroupControlPanel
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="start-hardware"
                    />
                </div>
                <MeasurementFieldControlPanel propertyName="start-hardware" showValue={false} />

                <div className="ahutest-form__label-info-button--grid" >
                    <Label className="grid-item-label" >{t("Juhtpaneeli ja kilbivaheline ühendus toimib")}</Label>
                    <InfoModal title={t("Juhend")} bodyText={"Ühenda juhtimiskilbi järgi juhtpaneel 'LCD' pistikusse"} />
                    <CheckBoxGroupControlPanel
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="display-hardware"
                    />
                </div>
                <MeasurementFieldControlPanel propertyName="display-hardware" showValue={false} />

                <div className="ahutest-form__label-info-button--grid" >
                    <Label className="grid-item-label" >{t("Tarkvara uuendamine toimib SD kaardiga.")}</Label>
                    <InfoModal title={t("Juhend")} bodyText={"Pane kontrollerile peale testimise tarkvara SD-kaardiga. Kui tarkvara on peal, eemalda SD-kaart kontrolleri seest. Tarkvara on leitav Tootmise automaatika serverist"} />
                    <CheckBoxGroupControlPanel
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="sdCard-hardware"
                    />
                </div>
                <MeasurementFieldControlPanel propertyName="sdCard-hardware" showValue={false} />

                <div className="ahutest-form__label-info-button--grid" >
                    <Label className="grid-item-label" >{t("M-link ja kilbivaheline ühendus toimib")}</Label>
                    <InfoModal title={t("Juhend")} bodyText={"Ühenda juhtimiskilp M-Linki C pordiga ja oota, et M-Linki C COM tuli hakkaks vilkuma. Vilkumine näitab, et ühendus on olemas"} />
                    <CheckBoxGroupControlPanel
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="mLink-hardware"
                    />
                </div>
                <MeasurementFieldControlPanel propertyName="mLink-hardware" showValue={false} />
            </div>
        </form>
    )
}

export default ControlPanelHardwareForm