import React from 'react'
import { Label } from 'reactstrap';
import { useTranslation } from "react-i18next";
import MeasurementField from '../../MeasurementField';
import { CheckboxOptions, CheckboxDefaultValue } from '../../../ahu/common/checkboxConfig';
import CheckboxGroup from '../../CheckboxGroup';
import { controllerScrewsText, extractFanModbusText, softwareLoadedText, supplyFanModbusText } from '../../ModalTexts';
import InfoModal from '../../InfoModal';

const ControllerTestForm = () => {
    const { t } = useTranslation();

    return (
        <form className="ahutest-container" >
            <Label className="ahutest-from--title">{t("Controller test")}</Label>

            <div className="flexboxAlignedColumn" style={{ padding: "2%" }}>

                <div className="ahutest-form__label-info-button--grid" >
                    <Label className="grid-item-label" >{t("Controller box screws tight")}</Label>
                    <InfoModal title={t("Juhend")} bodyText={controllerScrewsText} />
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="screws-ctr"
                    />
                </div>
                <MeasurementField properyName="screws-ctr" showValue={false} />

                <div className="ahutest-form__label-info-button--grid" >
                    <Label className="grid-item-label" >{t("Extract fan Modbus configuration")}</Label>
                    <InfoModal title={t("Juhend")} bodyText={extractFanModbusText} />
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="exFan-ctr"
                    />
                </div>
                <MeasurementField properyName="exFan-ctr" showValue={false} />

                <div className="ahutest-form__label-info-button--grid" >
                    <Label className="grid-item-label" >{t("Supply fan Modbus configuration")}</Label>
                    <InfoModal title={t("Juhend")} bodyText={supplyFanModbusText} />
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="supFan-ctr"
                    />
                </div>
                <MeasurementField properyName="supFan-ctr" showValue={false} />

                <div className="ahutest-form__label-info-button--grid" >
                    <Label className="grid-item-label" >{t("Software loaded to unit controller")}</Label>
                    <InfoModal title={t("Juhend")} bodyText={softwareLoadedText} />
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="softLoad-ctr"
                    />
                </div>
                <MeasurementField properyName="softLoad-ctr" showValue={false} />

                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Insert software version to report")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="insertSoft-ctr"
                    />
                </div>
                <MeasurementField properyName="insertSoft-ctr" />

            </div>
        </form>
    )
}

export default ControllerTestForm