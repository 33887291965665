import axios from "axios";
import { apiUrl } from "../../config/apiconfig";

export let instance = axios.create({

    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Credentials': '*', //true?      
    }, withCredentials: true
})

const authqueries = {
    login: (guid: string) => {
        return instance.get(`${apiUrl}/api/authentication/${guid}`);
    },
    refresh: (accessToken:string|null, refreshToken:string|null) => {

        /* if(!refreshToken || !accessToken) return Promise.reject("No refresh token found"); */
        /* var encodedRefreshToken = encodeURIComponent(refreshToken);
        var encodedAccessToken = encodeURIComponent(accessToken); */
        return instance.post(`${apiUrl}/api/authentication/refresh?accessToken=${accessToken}&refreshToken=${refreshToken}`);
    },
    logout: () => {
        return instance.get(`${apiUrl}/api/authentication/logout`)
    }
};

export default authqueries;