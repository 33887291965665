import { useDataContext } from "../../../../context/ductTestData";
import { Label } from "reactstrap"
import { useTranslation } from "react-i18next";
import ReturnProgressBar from "../../../common/ReturnProgressBar";

const MaterialTestForm = () => {
    const { testData, handleDynamicChange } = useDataContext();
    const { t, i18n } = useTranslation();
    return (
        <form className="flexboxAlignedColumn mainPageForm">
            <ReturnProgressBar level="4" lang={i18n.language} />
            <div className="flexboxAlignedColumn" style={{ padding: "2%" }}>
                <Label className="mainPageForm-label3" style={{ marginBottom: "5%", marginTop: "5%" }}>{t("Description")}</Label>
                <textarea className="material-textarea-description" value={testData.description ? testData.description : ""} name="description" placeholder={t("OverallDescription")} maxLength={200} onChange={handleDynamicChange}></textarea>
            </div>
        </form >
    )
}
export default MaterialTestForm;