import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import './i18n';
import React, { Suspense } from 'react';

ReactDOM.render(
  <BrowserRouter>
    <Suspense fallback={null}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </Suspense>
  </BrowserRouter>,
  document.getElementById('root')
);

//serviceWorkerRegistration.register(swConfig);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();

