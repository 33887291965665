import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Label } from "reactstrap";
import { useTkalDataContext } from "../../../context/tkalData";
import { CheckboxDefaultValue, CheckboxOptionsThumbsOnly } from "../ahu/common/checkboxConfig";
import CheckboxGroupSepu from "./CheckboxGroupSepu";
import MeasurementFieldSepu from "./MeasurementFieldSepu";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";

const SepuHatchTest = () => {
  const { t } = useTranslation();
  const { sepuTest, setSepuTest } = useTkalDataContext();

  const handleChange = (e: any) => {
    setSepuTest({ ...sepuTest, [e.target.name]: e.target.value })
  }
  const handleCheckDimensions = (propertyName: string) => {
    var value = +sepuTest.measurements[propertyName].value
    if (propertyName === 'openAngle-hatch') {
      if ((Math.abs(value - sepuTest.correctOpeningAngle) <= 2)) {
        setSepuTest({
          ...sepuTest,
          "measurements": { ...sepuTest.measurements, [propertyName]: { ...sepuTest.measurements[propertyName], "statusNew": "OK" } }
        })
      }
      if ((Math.abs(value - sepuTest.correctOpeningAngle) > 2)) {
        setSepuTest({
          ...sepuTest,
          "measurements": { ...sepuTest.measurements, [propertyName]: { ...sepuTest.measurements[propertyName], "statusNew": "FAIL" } }
        })
      }
    }
    if (propertyName === 'openTime-hatch') {
      /* hatchOpeningTime can be +/- 5s of correct opening time, must not be over 60s */
      if ((Math.abs(value - sepuTest.correctOpeningTime) <= 5) && value <= 60) {
        setSepuTest({
          ...sepuTest,
          "measurements": { ...sepuTest.measurements, [propertyName]: { ...sepuTest.measurements[propertyName], "statusNew": "OK" } }
        })
      }
      if ((Math.abs(value - sepuTest.correctOpeningTime) > 5) || value > 60) {
        setSepuTest({
          ...sepuTest,
          "measurements": { ...sepuTest.measurements, [propertyName]: { ...sepuTest.measurements[propertyName], "statusNew": "FAIL" } }
        })
      }
    }
  }
  return (
    <form className="ahutest-container" >
      <Label className="ahutest-from--title">{t("Kettaajami testimine")}</Label>
      <div className="flexboxAlignedColumn" style={{ padding: "2%" }}>

        <Label className="mainPageForm-label" style={{ marginTop: "5%", fontSize: "23px" }}>{t("Kettaajami tootmise kuupäev")}</Label>
        <div className="flexboxAlignedRow" style={{ gap: "5%" }}>
          <input className="input-orderNr sepu-test--input" name="gearingProductionDate" onChange={e => handleChange(e)} value={sepuTest.gearingProductionDate ?? ""} placeholder={t("dd.mm.YYYY")} />
        </div>

        <div className="ahutest-form__label--grid2" >
          <Label className="grid-item-label" >{t("Kettaajam on visuaalselt korras")}</Label>
          <CheckboxGroupSepu
            options={CheckboxOptionsThumbsOnly}
            defaultValue={CheckboxDefaultValue}
            measurementKey="gearingVisual-hatch"
          />
        </div>
        <MeasurementFieldSepu propertyName="gearingVisual-hatch" showValue={false} />

        <div className="ahutest-form__label--grid2" >
          <Label className="grid-item-label" >{t("Kettaajam töötab korrektselt")}</Label>
          <CheckboxGroupSepu
            options={CheckboxOptionsThumbsOnly}
            defaultValue={CheckboxDefaultValue}
            measurementKey="gearingWorks-hatch"
          />
        </div>
        <MeasurementFieldSepu propertyName="gearingWorks-hatch" showValue={false} />

        <div className="flexboxAlignedColumn">
          <div className="flexboxAlignedRow">
            <div className='ahutest-form__input --value' style={{ border: "none", width: "380px", textAlign: "left", marginBottom: "1%" }}>{t("Avanemise aeg")}</div>
            <Button className="ahutest-form__input --value range button-selection-1" style={{ border: "2px solid white", fontSize: "15px", marginTop: "0", height: "45px" }} onClick={() => handleCheckDimensions("openTime-hatch")} >Kontrolli</Button>
          </div>
        </div>
        <Label style={{ display: `${sepuTest.measurements["openTime-hatch"]?.statusNew === undefined ? 'none' : 'block'} ` }}>
          {sepuTest.measurements["openTime-hatch"]?.statusNew === "OK" ? <div style={{ fontSize: "18px" }}>{t("Test läbitud")} <DoneIcon style={{ fill: "green" }} /></div> : ""}
          {sepuTest.measurements["openTime-hatch"]?.statusNew === "FAIL" ? <div style={{ fontSize: "18px" }}>{t("Test ebaõnnestunud")}  <CloseIcon style={{ fill: '#FF0000' }} />  </div> : ""}
        </Label>
        <MeasurementFieldSepu propertyName="openTime-hatch" showValue={true} placeholderKey={"Sekundid"} type={"number"} />

        <div className="flexboxAlignedColumn" >
          <div className="flexboxAlignedRow">
            <div className='ahutest-form__input --value' style={{ border: "none", width: "380px", textAlign: "left", marginBottom: "1%" }}>{t("Avanemise nurk")}</div>
            <Button className="ahutest-form__input --value range button-selection-1" style={{ border: "2px solid white", fontSize: "15px", marginTop: "0", height: "45px" }} onClick={() => handleCheckDimensions("openAngle-hatch")} >Kontrolli</Button>
          </div>
          <Label style={{ display: `${sepuTest.measurements["openAngle-hatch"]?.statusNew === undefined ? 'none' : 'block'} ` }}>
            {sepuTest.measurements["openAngle-hatch"]?.statusNew === "OK" ? <div style={{ fontSize: "18px" }}>{t("Test läbitud")} <DoneIcon style={{ fill: "green" }} /></div> : ""}
            {sepuTest.measurements["openAngle-hatch"]?.statusNew === "FAIL" ? <div style={{ fontSize: "18px" }}>{t("Test ebaõnnestunud")}  <CloseIcon style={{ fill: '#FF0000' }} />  </div> : ""}
          </Label>

        </div>
        <MeasurementFieldSepu propertyName="openAngle-hatch" showValue={true} placeholderKey={"Nurk °"} type={"number"} />

      </div>
    </form>
  )
};

export default SepuHatchTest;
