import { useDataContext } from "../../../../context/ductTestData";
import { Label } from "reactstrap"
import ThumbsdownState from "./ThumbsdownState";
import { useTranslation } from "react-i18next";
import ReturnProgressBar from "../../../common/ReturnProgressBar";

const MaterialTestForm = () => {
    const { testData, handleDynamicChange } = useDataContext();
    const { setStiffnessProblem, setRollWidthProblem, setWhiteRustProblem, stiffnessProblem, whiteRustProblem, rollWidthProblem, isStiffnessBoxOpen, isRollWidthBoxOpen, isWhiteRustBoxOpen } = useDataContext();
    const { t, i18n } = useTranslation();

    return (
        <form className="flexboxAlignedColumn mainPageForm">
            <ReturnProgressBar level="4" lang={i18n.language} />
            <div className="flexboxAlignedColumn" style={{ padding: "2%" }}>
                <Label className="mainPageForm-label3">{t("BatchID")}</Label>
                <div className="flexboxAlignedRow" style={{ padding: "2%", width: "25vw" }}>
                    <input className="input-primary" name="batchId" type="number" value={testData.batchId ? testData.batchId : ""} placeholder={t("BatchIdAlert")} onChange={handleDynamicChange} />
                </div>
                <Label className="mainPageForm-label3">{t("MaterialThickness")} (mm)</Label>
                <div className="flexboxAlignedRow" style={{ padding: "2%", width: "25vw" }}>
                    <input className="input-primary" name="materialThickness" type="number" value={testData.materialThickness ? testData.materialThickness : ""} placeholder={t("MaterialThicknessAlert")} onChange={handleDynamicChange} />
                </div>
                <Label className="mainPageForm-label3" style={{ marginTop: "2%" }}>{t("MaterialStatus")}</Label>
                <div>
                    <div className="flexboxAlignedRow" style={{ fontSize: "25px" }}>
                        <ThumbsdownState categoryState={isRollWidthBoxOpen} materialCategory="RollWidth" titleThumbsDown="Problem with roll width" titleThumbsUp="No problem" label={() => <Label className="material-label">{t("RollWidth")}</Label>}>
                            <input value={rollWidthProblem ? rollWidthProblem : ""} className="input-primary" type="text" style={{ fontSize: "17px" }} placeholder={t("DescribeProblem")} onChange={e => setRollWidthProblem(e.target.value)} />
                        </ThumbsdownState>
                    </div>
                    <div className="flexboxAlignedRow" style={{ fontSize: "25px" }}>
                        <ThumbsdownState categoryState={isWhiteRustBoxOpen} materialCategory="WhiteRust" titleThumbsDown="Problem with white rust" titleThumbsUp="No problem" label={() => <Label className="material-label">{t("WhiteRust")}</Label>}>
                            <input value={whiteRustProblem ? whiteRustProblem : ""} className="input-primary" type="text" style={{ fontSize: "17px" }} placeholder={t("DescribeProblem")} onChange={e => setWhiteRustProblem(e.target.value)} />
                        </ThumbsdownState >
                    </div>
                    <div className="flexboxAlignedRow" style={{ fontSize: "25px" }}>
                        <ThumbsdownState categoryState={isStiffnessBoxOpen} materialCategory="Stiffness" titleThumbsDown="Problem with stiffness" titleThumbsUp="No problem" label={() => <Label className="material-label">{t("Stiffness")}</Label>}>
                            <input value={stiffnessProblem ? stiffnessProblem : ""} className="input-primary" type="text" style={{ fontSize: "17px" }} placeholder={t("DescribeProblem")} onChange={e => setStiffnessProblem(e.target.value)} />
                        </ThumbsdownState >
                    </div>
                </div>
                <Label className="mainPageForm-label3">{t("Description")}</Label>
                <textarea className="material-textarea-description" value={testData.description ? testData.description : ""} name="description" placeholder={t("OverallDescription")} maxLength={200} onChange={handleDynamicChange}></textarea>
            </div>
        </form >
    )
}
export default MaterialTestForm;