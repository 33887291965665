import React from 'react'
import { Label } from 'reactstrap';
import { useTranslation } from "react-i18next";
import MeasurementField from '../MeasurementField';
import { CheckboxDefaultValue, CheckboxOptions } from '../../ahu/common/checkboxConfig';
import CheckboxGroup from '../CheckboxGroup';

const StructureForm = () => {
    const { t } = useTranslation();

    return (
        <form className="ahutest-container" >
            <Label className="ahutest-from--title">{t("Konstruktsioon")}</Label>
            <div className="flexboxAlignedColumn" style={{ padding: "2%" }}>
                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Handiness according to scale")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="handiness-struct"
                    />
                </div>
                <MeasurementField properyName="handiness-struct" showValue={false} />

                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Stickers")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="stickers-struct"
                    />
                </div>
                <MeasurementField properyName="stickers-struct" showValue={false} />
            </div>
        </form>
    )
}

export default StructureForm