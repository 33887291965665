import { useTranslation } from "react-i18next";
import { Label } from 'reactstrap'
import '../../../../styles/ahu-test.css'
import '../../../../styles/main.scss'
import { CheckboxOptions, CheckboxDefaultValue } from '../common/checkboxConfig'
import CheckboxGroup from "../common/CheckboxGroup";
import { MeasurementField } from "../common/MeasurementField";

const StressTestForm = () => {
    const { t } = useTranslation();

    return (
        <form className="ahutest-container" >
            <Label className="ahutest-from--title">{t("StressTest")}</Label>

            <div className="flexboxAlignedColumn" style={{ padding: "2%" }}>
                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label">{t("ElectricCenter")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="stressTestElectricCenter-st"
                    />
                </div>
                <MeasurementField properyName="stressTestElectricCenter-st" showValue={false} />
            </div>
        </form>
    )
}

export default StressTestForm


