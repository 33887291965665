import { apiUrl } from "../../config/apiconfig";
import { AddUserRequest } from "../../types/admin/AddUserRequest";
import { PrinterDevice } from "../../types/admin/PrinterDevice";
import { AhuElectricalMeasurements } from "../../types/ahu/AhuElectricalMeasurements";
import { Recipe } from "../../types/directo/Recipe";
import { instance } from './auth'

export const addUser = (addUserRequest: AddUserRequest) => {
    return instance.post(`${apiUrl}/admin/add/user`, JSON.stringify(addUserRequest), { headers: { "Content-Type": "application/json" } })
}

//find user
export const findUser = (fullName: string) => {
    return instance.get(`${apiUrl}/admin/find/user?fullName=${fullName}`, { headers: { "Content-Type": "application/json" } })
}

//remove user
export const removeUser = (id: string) => {
    return instance.post(`${apiUrl}/admin/remove/user?id=${id}`, { headers: { "Content-Type": "application/json" } })
}

export const getRoles = () => {
    return instance.get<string[]>(`${apiUrl}/admin/get/roles`, { headers: { "Content-Type": "application/json" } })
}

export const printKalorifeerTestLabel = (printCount: number, selectedPrinter: string, itemCode: string, orderNumber: string) => {
    return instance.get(`${apiUrl}/printer/print?printCount=${printCount}&selectedPrinter=${selectedPrinter}&itemUniqueId=${itemCode}&orderNumber=${orderNumber}`, { headers: { "Content-Type": "application/json" } })
}

export const sendEmail = (ahuTestDto: AhuElectricalMeasurements, ccAddress: string) => {
    return instance.post(`${apiUrl}/api/email/send?ccAddress=${ccAddress}`, JSON.stringify(ahuTestDto), { headers: { "Content-Type": "application/json" } })
}

export const getEmailPreview = (ahuTestDto: AhuElectricalMeasurements) => {
    return instance.post<string>(`${apiUrl}/api/email/preview`, JSON.stringify(ahuTestDto), { headers: { "Content-Type": "application/json" } })
}

export const printProductCode = (printCount: number, selectedPrinter: string, productCode: string, labelName?: string) => {
    // Create an object to store the parameters
    const params: any = {
        printCount,
        selectedPrinter,
        productCode,
    };

    // Add labelName to params if it is defined
    if (labelName !== undefined) {
        params.labelName = labelName;
    }

    // Use URLSearchParams to build the query string
    const queryString = new URLSearchParams(params).toString();

    // Make the GET request
    return instance.get<string>(`${apiUrl}/printer/print/productCode?${queryString}`, { headers: { "Content-Type": "application/json" } });
};

export const addPrinterDevice = (printerDevice: PrinterDevice) => {
    return instance.post<PrinterDevice>(`${apiUrl}/printer/add/device`, JSON.stringify(printerDevice), { headers: { "Content-Type": "application/json" } })
}

export const getPrinters = () => {
    return instance.get<string[]>(`${apiUrl}/printer/get/devices`, { headers: { "Content-Type": "application/json" } })
}

export const printProductNameList = (selectedPrinter: string, recipeList: Recipe[]) => {
    return instance.post(`${apiUrl}/printer/print/productName?selectedPrinter=${selectedPrinter}`, JSON.stringify(recipeList), { headers: { "Content-Type": "application/json" } })
}