import React from 'react'
import { Label } from 'reactstrap';
import { useAhuDataContext } from '../../../../context/ahuData';
import { useTranslation } from "react-i18next";
import { CheckboxOptions, CheckboxDefaultValue } from '../common/checkboxConfig';
import CheckboxGroup from '../common/CheckboxGroup';
import { MeasurementField } from '../common/MeasurementField';
import IsDisabled from '../common/isDisabled';

const LtoForm = () => {
    const { electricalMeasurementData, handleAhuTestMeasurementsValue } = useAhuDataContext();
    const ltoType = electricalMeasurementData.commonTestData.ltoType;
    const { t } = useTranslation();
    return (
        <form className="ahutest-container" >
            <Label className="ahutest-from--title">{t("LTO")}</Label>

            <div className="flexboxAlignedColumn" style={{ padding: "2%" }}>
                {ltoType === "glycol" &&
                    <>
                        <div className="ahutest-form__label--grid2" >
                            <Label className="grid-item-label" >{t("Valve actuator")}</Label>
                            <CheckboxGroup
                                options={CheckboxOptions}
                                defaultValue={CheckboxDefaultValue}
                                measurementKey="valveActuator-lto"
                            />
                        </div>
                        <MeasurementField properyName='valveActuator-lto' />

                        <div className="ahutest-form__label--grid2" >
                            <Label className="grid-item-label" >{t("Current of pump motor protection switch")}</Label>
                            <CheckboxGroup
                                options={CheckboxOptions}
                                defaultValue={CheckboxDefaultValue}
                                measurementKey="currentPumpMotor-lto"
                            />
                        </div>
                        <MeasurementField properyName='currentPumpMotor-lto' />

                        <div className="ahutest-form__label--grid2" >
                            <Label className="grid-item-label" >{t("Pump operating mode")}</Label>
                            <CheckboxGroup
                                options={CheckboxOptions}
                                defaultValue={CheckboxDefaultValue}
                                measurementKey="pumpOperatingMode-lto"
                            />
                        </div>
                        <MeasurementField properyName='pumpOperatingMode-lto' />


                        <div className="ahutest-form__label--grid2" >
                            <Label className="grid-item-label" >{t("Pump alarm")}</Label>
                            <CheckboxGroup
                                options={CheckboxOptions}
                                defaultValue={CheckboxDefaultValue}
                                measurementKey="pumpAlarm-lto"
                            />
                        </div>
                        <MeasurementField properyName='pumpAlarm-lto' />

                        <div className="ahutest-form__label--grid2" >
                            <Label className="grid-item-label" >{t("Temperature sensor going to the extraction coil")}</Label>
                            <CheckboxGroup
                                options={CheckboxOptions}
                                defaultValue={CheckboxDefaultValue}
                                measurementKey="temperatureSensor-lto"
                            />
                        </div>
                        <MeasurementField properyName='temperatureSensor-lto' />
                    </>
                }

                {ltoType === "cube" &&
                    <>
                        <div className="ahutest-form__label--grid2" >
                            <Label className="grid-item-label" >{t("Bypass damper operation")}</Label>
                            <CheckboxGroup
                                options={CheckboxOptions}
                                defaultValue={CheckboxDefaultValue}
                                measurementKey="damperOperation-lto"
                            />
                        </div>
                        <MeasurementField properyName='damperOperation-lto' />

                        <div className="ahutest-form__label--grid2" >
                            <Label className="grid-item-label" >{t("Operation of block damper")}</Label>
                            <CheckboxGroup
                                options={CheckboxOptions}
                                defaultValue={CheckboxDefaultValue}
                                measurementKey="blockDamperOperation-lto"
                            />
                        </div>
                        <MeasurementField properyName='blockDamperOperation-lto' />
                    </>
                }
                {ltoType === "rotor" &&
                    <>
                        <div className="ahutest-form__label--grid2" >
                            <Label className="grid-item-label" >{t("DIP switch positions")}</Label>
                            <CheckboxGroup
                                options={CheckboxOptions}
                                defaultValue={CheckboxDefaultValue}
                                measurementKey="dipSwitch-lto"
                            />
                        </div>
                        <MeasurementField properyName='dipSwitch-lto' showValue={false} />

                        <div className="ahutest-form__label--grid2" >
                            <Label className="grid-item-label" >{t("Direction of rotation")}</Label>
                            <CheckboxGroup
                                options={CheckboxOptions}
                                defaultValue={CheckboxDefaultValue}
                                measurementKey="rotationDirection-lto"
                            />
                        </div>
                        <MeasurementField properyName='rotationDirection-lto' showValue={false} />

                        <div className="ahutest-form__label--grid2" >
                            <Label className="grid-item-label" >{t("Rotation watch")}</Label>
                            <CheckboxGroup
                                options={CheckboxOptions}
                                defaultValue={CheckboxDefaultValue}
                                measurementKey="rotationWatch-lto"
                            />
                        </div>
                        <MeasurementField properyName='rotationWatch-lto' showValue={false} />


                        <div className="ahutest-form__label--grid2" >
                            <Label className="grid-item-label" >{t("Rotation speed (6s/rev)")}</Label>
                            <CheckboxGroup
                                options={CheckboxOptions}
                                defaultValue={CheckboxDefaultValue}
                                measurementKey="rotationSpeed-lto"
                            />
                        </div>
                        <MeasurementField properyName='rotationSpeed-lto' showValue={false} />

                        <div className="ahutest-form__label--grid2" >
                            <Label className="grid-item-label" >{t("Alarm output")}</Label>
                            <CheckboxGroup
                                options={CheckboxOptions}
                                defaultValue={CheckboxDefaultValue}
                                measurementKey="alarmOutput-lto"
                            />
                        </div>
                        <MeasurementField properyName='alarmOutput-lto' showValue={false} />
                    </>
                }

                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Pressure transmitter")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="pressureTransmitter-lto"
                    />
                </div>
                <MeasurementField inputProps={{ className: "ahutest-form__input --value smaller" }} properyName='pressureTransmitter-lto' gap="10px" unit="Pa" placeholderKey='Pressure range' />


                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Measuring hoses")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="measuringHoses-lto"
                    />
                </div>
                <MeasurementField placeholderKey='Value' properyName='measuringHoses-lto' />

                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Air volume / pressure drop on the outlet side")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="airMeasurementsLto"
                    />
                </div>
                {IsDisabled("airMeasurementsLto") &&
                    <>
                        <div className="ahutest-form__label--grid3" style={{ marginTop: "5%" }} >
                            <Label className="grid-item-label" style={{ textAlign: "center" }}>  {t("1. air volume / pressure drop on the outlet side")}</Label>
                        </div>
                        <div className="flexboxAlignedRow" style={{ gap: "25px" }}>

                            <input className="ahutest-form__input --value range" name="airMeasurement1:af" onChange={e => handleAhuTestMeasurementsValue(e, "value")} value={electricalMeasurementData.measurements["airMeasurement1:af"]?.value ?? ""} placeholder={t("Airflow")} /> l/s
                            <input className="ahutest-form__input --value range" name="airMeasurement1:pd" onChange={e => handleAhuTestMeasurementsValue(e, "value")} value={electricalMeasurementData.measurements["airMeasurement1:pd"]?.value ?? ""} placeholder={t("Pressure drop")} /> Pa
                            <input className="ahutest-form__input --value range" name="airMeasurement1" onChange={e => handleAhuTestMeasurementsValue(e, "description")} style={{ width: "300px" }} value={electricalMeasurementData.measurements["airMeasurement1"]?.description ?? ""} placeholder={t("Notes")} />
                        </div>

                        <div className="ahutest-form__label--grid3" >
                            <Label className="grid-item-label" style={{ textAlign: "center" }}>{t("2. air volume / pressure drop on the outlet side")}</Label>
                        </div>
                        <div className="flexboxAlignedRow" style={{ gap: "25px" }}>

                            <input className="ahutest-form__input --value range" name="airMeasurement2:af" onChange={e => handleAhuTestMeasurementsValue(e, "value")} value={electricalMeasurementData.measurements["airMeasurement2:af"]?.value ?? ""} placeholder={t("Airflow")} /> l/s
                            <input className="ahutest-form__input --value range" name="airMeasurement2:pd" onChange={e => handleAhuTestMeasurementsValue(e, "value")} value={electricalMeasurementData.measurements["airMeasurement2:pd"]?.value ?? ""} placeholder={t("Pressure drop")} /> Pa
                            <input className="ahutest-form__input --value range" name="airMeasurement2" onChange={e => handleAhuTestMeasurementsValue(e, "description")} style={{ width: "300px" }} value={electricalMeasurementData.measurements["airMeasurement2"]?.description ?? ""} placeholder={t("Notes")} />
                        </div>

                        <div className="ahutest-form__label--grid3" >
                            <Label className="grid-item-label" style={{ textAlign: "center" }}>{t("3. air volume / pressure drop on the outlet side")}</Label>
                        </div>
                        <div className="flexboxAlignedRow" style={{ gap: "25px" }}>

                            <input className="ahutest-form__input --value range" name="airMeasurement3:af" onChange={e => handleAhuTestMeasurementsValue(e, "value")} value={electricalMeasurementData.measurements["airMeasurement3:af"]?.value ?? ""} placeholder={t("Airflow")} /> l/s
                            <input className="ahutest-form__input --value range" name="airMeasurement3:pd" onChange={e => handleAhuTestMeasurementsValue(e, "value")} value={electricalMeasurementData.measurements["airMeasurement3:pd"]?.value ?? ""} placeholder={t("Pressure drop")} /> Pa
                            <input className="ahutest-form__input --value range" name="airMeasurement3" onChange={e => handleAhuTestMeasurementsValue(e, "description")} style={{ width: "300px" }} value={electricalMeasurementData.measurements["airMeasurement3"]?.description ?? ""} placeholder={t("Notes")} />
                        </div>

                        <div className="ahutest-form__label--grid3" >
                            <Label className="grid-item-label" style={{ textAlign: "center" }}>{t("4. air volume / pressure drop on the outlet side")}</Label>
                        </div>
                        <div className="flexboxAlignedRow" style={{ gap: "25px" }}>
                            <input className="ahutest-form__input --value range" name="airMeasurement4:af" onChange={e => handleAhuTestMeasurementsValue(e, "value")} value={electricalMeasurementData.measurements["airMeasurement4:af"]?.value ?? ""} placeholder={t("Airflow")} /> l/s
                            <input className="ahutest-form__input --value range" name="airMeasurement4:pd" onChange={e => handleAhuTestMeasurementsValue(e, "value")} value={electricalMeasurementData.measurements["airMeasurement4:pd"]?.value ?? ""} placeholder={t("Pressure drop")} /> Pa
                            <input className="ahutest-form__input --value range" name="airMeasurement4" onChange={e => handleAhuTestMeasurementsValue(e, "description")} style={{ width: "300px" }} value={electricalMeasurementData.measurements["airMeasurement4"]?.description ?? ""} placeholder={t("Notes")} />
                        </div>
                    </>}
            </div>
        </form>
    )
}

export default LtoForm


