import { IconButton } from '@mui/material';
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import ElectricalMeasurementsForm1 from '../../components/configurationForms/ahu-estonia/electrical-measurements/ElectricalMeasurementsForm1';
import ElectricalMeasurementsForm2 from '../../components/configurationForms/ahu-estonia/electrical-measurements/ElectricalMeasurementsForm2';
import SelectOrder from '../../components/configurationForms/ahu-estonia/SelectOrder'
import AhuMeasurementsOverview from '../../components/configurationForms/ahu/measurements/AhuMeasurementsOverview';
import { useAhuDataContext } from '../../context/ahuData';
import HomeIcon from '@mui/icons-material/Home';
import SaveIcon from '@mui/icons-material/Save';
import WaterRadiatorForm from '../../components/configurationForms/ahu-estonia/inspection-log/WaterRadiatorForm';
import FilterForm from '../../components/configurationForms/ahu-estonia/inspection-log/FilterForm';
import OtherChecksForm from '../../components/configurationForms/ahu-estonia/inspection-log/controller/OtherChecksForm';
import ExhaustFanForm from '../../components/configurationForms/ahu-estonia/inspection-log/ExhaustFanForm';
import InletFanForm from '../../components/configurationForms/ahu-estonia/inspection-log/InletFanForm';
import LtoForm from '../../components/configurationForms/ahu-estonia/inspection-log/LtoForm';
import StructureForm from '../../components/configurationForms/ahu-estonia/inspection-log/StructureForm';
import ElectricRadiatorForm from '../../components/configurationForms/ahu-estonia/inspection-log/ElectricRadiatorForm';
import MeasurementsForm from '../../components/configurationForms/ahu-estonia/inspection-log/controller/MeasurementsForm';
import FactorySettingsForm from '../../components/configurationForms/ahu-estonia/inspection-log/controller/FactorySettingsForm';
import ControllerTestForm from '../../components/configurationForms/ahu-estonia/inspection-log/controller/ControllerTestForm';
import ControllerFunctionsForm from '../../components/configurationForms/ahu-estonia/inspection-log/controller/ControllerFunctionsForm';
import { createAhuEstTest, saveAhuTest, sendAhuEstTestToDirecto } from '../../components/queries/testQueries';
import { AhuTestEstonia } from '../../types/ahu-estonia/AhuTestEstonia';
import { PurchaseOrder } from '../../types/ahu-estonia/AhuPurchaseOrder';
import { Item } from '../../types/directo/Item';

const AhuTestingEstoniaPage = () => {
    const { t } = useTranslation();
    const [step, setStep] = useState(1);
    const { setAhuTestDataEst, ahuTestDataEst, isLoading, setIsLoading, setAhuEstOrderData, setItemData, setItemNumber, setTestType, setCreatedTests } = useAhuDataContext();
    let electricalTestSteps = 4;
    let inspectionLogSteps = 14;

    const handleNextButton = () => {
        if (step >= 2) {
            //save test
            handleSaveTestData()
        }
        if ((ahuTestDataEst.radiatorType === (undefined) || ahuTestDataEst.radiatorType === ("")) && ahuTestDataEst.testType === "inspection log") {
            alert(t("Please select radiator type"))
            return;
        }
        if (ahuTestDataEst.radiatorType === "waterRadiator" && step === 5) {
            setStep(prev => prev + 2)
            return;
        }
        if (ahuTestDataEst.radiatorType === "electricRadiator" && step === 6) {
            setStep(prev => prev + 2)
            return;
        }
        if ((step === 1 && ahuTestDataEst.measurements === (undefined || null)) || (step === 1 && !ahuTestDataEst.id)) {
            handleCreateAhuTest();
            setStep(prev => prev + 1)
        }
        else {
            setStep(prev => prev + 1)
        }
    }

    const handleBackButton = () => {
        if (ahuTestDataEst.radiatorType === "waterRadiator" && step === 7) {
            setStep(prev => prev - 2)
            return;
        }
        if (ahuTestDataEst.radiatorType === "electricRadiator" && step === 8) {
            setStep(prev => prev - 2)
            return;
        }
        if (step >= 2) {
            setStep(prev => prev - 1);
        }
    }
    const validateNextStep = () => {

        if (step < electricalTestSteps && ahuTestDataEst.testType === "electrical measurement") {
            return true;
        }
        if (step < inspectionLogSteps && ahuTestDataEst.testType === "inspection log") {
            return true;
        }

        return false;
    }

    const validateLastStep = () => {
        if (step === electricalTestSteps && ahuTestDataEst.testType === "electrical measurement") {
            return true;
        }

        if (step === inspectionLogSteps && ahuTestDataEst.testType === "inspection log") {
            return true;
        }

        return false;
    }

    const chooseFirstStepButtonKey = () => {
        if (step === 1) {
            if (ahuTestDataEst.id) return t("Open")
            if (!ahuTestDataEst.id) return t("Next")
        }
        if (step > 1) return t("Next")
    }

    const handleCreateAhuTest = () => {
        if (ahuTestDataEst.testType)
            createAhuEstTest(ahuTestDataEst, ahuTestDataEst.testType).then((res) => {
                setAhuTestDataEst(res.data)
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                setIsLoading(false)
            })
    }

    const handleSaveTestData = () => {
        var data: AhuTestEstonia = {
            ...ahuTestDataEst,
            /* double check if this is correct */
            /*  itemName : ahuTestDataEst.itemName, */
        }

        try {
            setIsLoading(true)
            saveAhuTest(data);
        }
        catch (err: any) {
            console.log(err.response.data);
            setIsLoading(false);
            return;
        }
        finally {
            setIsLoading(false);
        }
    }


    const handleSendData = async () => {
        setIsLoading(true)

        /*         try {
                    await sendAhuEstTestToDirecto(ahuTestDataEst);
                }
                catch (err: any) {
                    console.log(err.response.data);
                    setIsLoading(false);
                    return;
                } */

        try {

            await sendAhuEstTestToDirecto(ahuTestDataEst);
            // Reset the form data and go back to the first step
            setAhuTestDataEst({ measurements: {} } as AhuTestEstonia);
            setAhuEstOrderData({} as PurchaseOrder);
            setItemData({} as Item);
            setCreatedTests([])
            setTestType("")
            setItemNumber("")
            setStep(1);
            alert("Success")
        } catch (err: any) {
            console.log(err.response.data);

        } finally {
            setIsLoading(false);
        }
    }

    return (
        <div className="mainPageLayout">
            {
                (() => {
                    if (step === 1) {
                        return <SelectOrder />
                    }
                    switch (ahuTestDataEst.testType) {
                        case "electrical measurement":
                            switch (step) {
                                case 2:
                                    return <ElectricalMeasurementsForm1 />
                                case 3:
                                    return <ElectricalMeasurementsForm2 />
                                case 4:
                                    return <AhuMeasurementsOverview testType={ahuTestDataEst.testType} />
                            }
                            break;
                        case "inspection log":
                            switch (step) {
                                case 2:
                                    return <StructureForm />
                                case 3:
                                    return <LtoForm />
                                case 4:
                                    return <InletFanForm />
                                case 5:
                                    return <ExhaustFanForm />
                                case 6:
                                    return <ElectricRadiatorForm />
                                case 7:
                                    return <WaterRadiatorForm />
                                case 8:
                                    return <FilterForm />
                                case 9:
                                    return <ControllerTestForm />
                                case 10:
                                    return <FactorySettingsForm />
                                case 11:
                                    return <MeasurementsForm />
                                case 12:
                                    return <ControllerFunctionsForm />
                                case 13:
                                    return <OtherChecksForm />
                                case 14:
                                    return <AhuMeasurementsOverview testType={ahuTestDataEst.testType} />
                            }
                    }
                })()
            }

            <div className="navigation-buttons-container">
                {step >= 2 && <Button className="button-primary" onClick={handleBackButton}>{t("Back")}</Button>}
                {step > 1 &&
                    <>
                        <IconButton onClick={() => setStep(1)}>
                            <HomeIcon />
                        </IconButton>
                        <IconButton onClick={() => handleSaveTestData()}>
                            <SaveIcon />
                        </IconButton>
                    </>
                }

                {validateNextStep() && <Button className="button-primary" onClick={handleNextButton}>{chooseFirstStepButtonKey()}</Button>}
                {validateLastStep() && <Button className="button-primary" style={{ width: "170px", backgroundColor: "white", color: "red" }} onClick={handleSendData} disabled={isLoading || ahuTestDataEst.directoCode != null}>{t("ConfirmAndSend")} </Button>}
            </div>
        </div>
    )
}

export default AhuTestingEstoniaPage