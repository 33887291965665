import React from 'react'
import { useTranslation } from 'react-i18next';
import { Label } from 'reactstrap';
import { CheckboxOptions, CheckboxDefaultValue } from '../ahu/common/checkboxConfig';
import CheckBoxGroupControlPanel from './common/CheckBoxGroupControlPanel';
import MeasurementFieldControlPanel from './common/MeasurementFieldControlPanel';

const ControlPanelCasingForm = () => {
    const { t } = useTranslation();

    return (
        <form className="ahutest-container" >
            <Label className="ahutest-from--title">{t("Korpus")}</Label>
            <div className="flexboxAlignedColumn" style={{ padding: "2%" }}>
                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Seadme korpus on vigastamata")}</Label>
                    <CheckBoxGroupControlPanel
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="intact-case"
                    />
                </div>
                <MeasurementFieldControlPanel propertyName="intact-case" showValue={false} />

                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Graveeringud on peal")}</Label>
                    <CheckBoxGroupControlPanel
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="gravings-case"
                    />
                </div>
                <MeasurementFieldControlPanel propertyName="gravings-case" showValue={false} />

                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Komponendid on paigaldatud korrektselt etalontoote järgi")}</Label>
                    <CheckBoxGroupControlPanel
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="placement-case"
                    />
                </div>
                <MeasurementFieldControlPanel propertyName="placement-case" showValue={false} />

                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Elektriohu kleebis on kilbi põhjal olemas")}</Label>
                    <CheckBoxGroupControlPanel
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="elSticker-case"
                    />
                </div>
                <MeasurementFieldControlPanel propertyName="elSticker-case" showValue={false} />

                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Tootekleebis on olemas")}</Label>
                    <CheckBoxGroupControlPanel
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="productSticker-case"
                    />
                </div>
                <MeasurementFieldControlPanel propertyName="productSticker-case" showValue={false} />
            </div>
        </form>
    )
}

export default ControlPanelCasingForm