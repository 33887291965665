export const OZONATOR_TEST_TYPE = {
    EMPTY:0,
    TESTRAPORT: 1,
    OZONEGENERATOR: 2,
} as const;

export type OzonatorTestDto = {
    id: number,
    purchaseOrderNumber:string
    productArticleNumber:string
    productName:string
    productUniqueId:string
    finishedBy:string
    finishedDate:Date,
    directoCode:string,
    /* testType: string, */
    testType: typeof OZONATOR_TEST_TYPE[keyof typeof OZONATOR_TEST_TYPE],
    testStatus:string
    testName:string
    createdBy:string
    createdByUserId:string
    createdDate:Date
    supplierCode:string
    supplierName:string
    softwareVersion:string,
    electricalSchemaVersion:string,
    serialNumber:string,
    rootJson:string,
    description:string
    measurements: { [key: string]: Measurement },
}

export type Measurement = {
    name: string,
    status: boolean,
    statusNew: string,
    value: string,
    description:string,
    unit:string
}

export type OzonatorCreateDto = {
    purchaseOrderNumber:string
    productArticleNumber:string
    productName:string
    productUniqueId:string
    testType: typeof OZONATOR_TEST_TYPE[keyof typeof OZONATOR_TEST_TYPE],
}