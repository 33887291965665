import React from 'react'
import { useTranslation } from 'react-i18next';
import { Button, Label } from 'reactstrap';
import { useDataContext } from '../../../../context/ductTestData';
import { DuctRigidity } from '../../../../types/DuctTestType';
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
type Props = {
    ductRigidityMeasurements: DuctRigidity | undefined
}
const RigidityTestForm = ({ ductRigidityMeasurements }: Props) => {
    const { setTestData, testData, handleDynamicChange, itemData } = useDataContext();
    const { t } = useTranslation();
    const handleTestRigidity = () => {
        if (ductRigidityMeasurements === undefined) return alert("NTO jäikuse andmed puuduvad")
        if (!testData.measuredDeflection || !testData.residualDeformation) return alert("Täidke kõik vajalikud väljad")
        if (testData.measuredDeflection > ductRigidityMeasurements.permittedDeformation) {
            setTestData({ ...testData, rigidityTestResult: "Fail" })
            return;
        }
        else {
            setTestData({ ...testData, rigidityTestResult: "Ok" })
        }
    }
    return (
        <div className='flexboxAlignedColumn mainPageForm' style={{ marginTop: "2%", }}>
            <div className='rigidity-test--row'>
                <div style={{ fontSize: "18px" }}>{t("Kasutatud raskus (kg)")}: </div>
                <div className="ahutest-form__input --value">{testData.rigidityTestWeight}kg</div>
            </div>
            <div className='rigidity-test--row'>
                <div style={{ fontSize: "18px" }}>{t("Läbimõõt (mm)")}:</div>
                <div className="ahutest-form__input --value">{itemData.dimensions.split("-")[0]}</div>
            </div>
            <div className='rigidity-test--row'>
                <div style={{ fontSize: "18px" }}>{t("Sisesta mõõdetud läbipaine (mm)")}:</div>
                <input className="ahutest-form__input --value" placeholder='(mm)' type="number" name="measuredDeflection" value={testData.measuredDeflection} onChange={(e: any) => handleDynamicChange(e)}></input>
            </div>
            <div className='rigidity-test--row'>
                <div style={{ fontSize: "18px" }}>{t("Sisesta mõõdetud deformatsioon (mm)")}:</div>
                <input className="ahutest-form__input --value" placeholder='(mm)' type="number" name="residualDeformation" value={testData.residualDeformation} onChange={(e: any) => handleDynamicChange(e)}></input>
            </div>
            <div className='rigidity-test--row' style={{ marginBottom: "5%" }}>
                <div style={{ fontSize: "18px" }}>{t("Testi tulemus")}:</div>
                <Label style={{ display: `${testData.rigidityTestResult === undefined ? 'none' : 'block'} ` }}>
                    {testData.rigidityTestResult === "Ok" ? <div style={{ fontSize: "18px" }}>{t("Jäikuse test läbitud")} <DoneIcon style={{ fill: "green" }} /></div> : ""}
                    {testData.rigidityTestResult === "Fail" ? <div style={{ fontSize: "18px" }}>{t("Jäikuse test ebaõnnestunud")} <CloseIcon style={{ fill: '#FF0000' }} />  </div> : ""}
                </Label>
            </div>
            <div className='rigidity-test--row' style={{ justifyContent: "center" }}>
                <Button className="ahutest-form__input --value button-selection-1" style={{ border: "2px solid white", fontSize: "15px", marginBottom: "0", height: "50px", minWidth: "fit-content" }} onClick={handleTestRigidity}>{t("Kontrolli")}</Button>
            </div>
        </div >
    )
}

export default RigidityTestForm