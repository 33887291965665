import { useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import { Box, Button, IconButton, Input, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import '../../../styles/ahu-test.css'
import '../../../styles/select-order.css'
import EmailIcon from '@mui/icons-material/Email';
import { AhuElectricalMeasurements } from '../../../types/ahu/AhuElectricalMeasurements';
import { getEmailPreview, sendEmail } from '../../queries/adminQueries';

const useStyles = makeStyles({
    root: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#000000a8',
        border: '2px solid #000',
        width: "600px",
        whiteSpace: "pre-line",
    },
    title: {
        marginTop: "10px",
        fontSize: "24px",
        fontFamily: 'Banschrift'
    },
    bodyText: {
        padding: '30px',
        color: 'white',
        textAlign: 'left',
        fontFamily: 'Banschrift',
        fontSize: '18px',
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        justifyContent: "center",
        gap: "10px"
    },
    emailInput: {
        padding: '30px',
        paddingBottom: "0px",
        color: 'white',
        textAlign: 'left',
        fontFamily: 'Banschrift',
        fontSize: '18px',
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        gap: "10px",
    },
    label: {
        color: "white",
        textAlign: "left",
        fontSize: '18px',
        fontFamily: 'Banschrift'
    },
    input: {
        color: "black",
        width: "50%",
        textAlign: "left",
        background: "white",
        borderRadius: "5px"
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "center",
        gap: "10px",
        marginTop: "25px"
    }
});

type Props = { testDto: AhuElectricalMeasurements };

const SendEmailModal = ({ testDto }: Props) => {
    const [isOpen, setIsOpen] = useState(false);
    const styles = useStyles();
    const [emailPreview, setEmailPreview] = useState<string>("");
    const [ccAddress, setCcAddress] = useState<string>('paavo.kujala@recair.fi');

    const handleOpen = () => {
        setIsOpen(true);
        getEmailPreview(testDto).then((res) => { setEmailPreview(res.data) }).catch((err) => { console.log(err) })
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    const handleSendEmail = (e: any, testDto: AhuElectricalMeasurements) => {
        e.preventDefault();
        sendEmail(testDto, ccAddress).then((res) => { console.log(res) }).catch((err) => { console.log(err) }).finally(() => { setIsOpen(false) });
    }

    return (
        <>
            <IconButton style={{ marginTop: "4%", cursor: "pointer" }} onClick={handleOpen}>
                <EmailIcon />
            </IconButton>

            <Modal
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className={styles.root}>
                    <Typography className={styles.title} id="modal-modal-title" variant="h6" component="h2">
                        Email preview
                    </Typography>
                    <form onSubmit={(e: any) => handleSendEmail(e, testDto)}>
                        <div className={styles.emailInput} id="modal-modal-description">
                            <label className={styles.label}>
                                Send email copy to:
                            </label>
                            <Input
                                className={styles.input}
                                type="email"
                                value={ccAddress}
                                onChange={(event) => setCcAddress(event.target.value)}
                            />
                        </div>
                        <div className={styles.bodyText} id="modal-modal-description">
                            {emailPreview}
                            <div className={styles.buttonContainer}>
                                <Button style={{ color: "white", background: "green" }} type="submit">Send</Button>
                                <Button style={{ color: "white", background: "#c00" }} onClick={() => setIsOpen(false)}>Cancel</Button>
                            </div>
                        </div>
                    </form>
                </Box>
            </Modal>
        </>
    );
}
export default SendEmailModal;
