import { Label } from 'reactstrap'
import '../../../../styles/ahu-test.css'
import '../../../../styles/main.scss'
import { useTranslation } from "react-i18next";
import { CheckboxOptions, CheckboxDefaultValue } from '../common/checkboxConfig'
import CheckboxGroup from "../common/CheckboxGroup";
import { MeasurementField } from '../common/MeasurementField';

const ElectricalMeasurementsForm = () => {
    const { t } = useTranslation();

    return (
        <form className="ahutest-container" >
            <Label className="ahutest-from--title">{t("Insulation")}</Label>
            <div className="flexboxAlignedColumn" style={{ padding: "2%" }}>

                <div className="ahutest-form__label--grid2">
                    <Label className="grid-item-label">{t("PowerSupplyTerminals")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="powerSupplyTerminals-resistance"
                    />
                </div>
                <MeasurementField properyName='powerSupplyTerminals-resistance' unit="MΩ" gap="10px" />

                <div className="ahutest-form__label--grid2">
                    <Label className="grid-item-label">{t("Inlet fan")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="inletFan-resistance"
                    />
                </div>
                <MeasurementField properyName='inletFan-resistance' unit="MΩ" gap="10px" />


                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Exhaust fan")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="exhaustFan-resistance"
                    />
                </div>
                <MeasurementField properyName='exhaustFan-resistance' unit="MΩ" gap="10px" />

                <div className="ahutest-form__label--grid2" style={{ gap: "10px" }}>
                    <Label className="grid-item-label" >{t("LTO")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="lto-resistance"
                    />
                </div>
                <MeasurementField properyName='lto-resistance' unit="MΩ" gap="10px" />


                <div className="ahutest-form__label--grid2" style={{ gap: "10px" }}>
                    <Label className="grid-item-label" >{t("Electric heater")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="electricHeater-resistance"
                    />
                </div>
                <MeasurementField properyName='electricHeater-resistance' unit="MΩ" gap="10px" />


                <div className="ahutest-form__label--grid2" style={{ gap: "10px" }}>
                    <Label className="grid-item-label" >{t("Circulating water pump")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="circulatingWaterPump-resistance"
                    />
                </div>
                <MeasurementField properyName='circulatingWaterPump-resistance' unit="MΩ" gap="10px" />


                <div className="ahutest-form__label--grid2" style={{ gap: "10px" }}>
                    <Label className="grid-item-label" >{t("Damper motors")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="damperMotors-resistance"
                    />
                </div>
                <MeasurementField properyName='damperMotors-resistance' unit="MΩ" gap="10px" />


                <div className="ahutest-form__label--grid2" style={{ gap: "10px" }}>
                    <Label className="grid-item-label" >{t("Lighting fixtures")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="lightningFixtures-resistance"
                    />
                </div>
                <MeasurementField properyName='lightningFixtures-resistance' unit="MΩ" gap="10px" />


                <div className="ahutest-form__label--grid2" style={{ gap: "10px" }}>
                    <Label className="grid-item-label" >{t("Compressor")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="compressor-resistance"
                    />
                </div>
                <MeasurementField properyName='compressor-resistance' unit="MΩ" gap="10px" />

            </div>
        </form>
    )
}

export default ElectricalMeasurementsForm