import React from 'react'
import { Label } from 'reactstrap';
import { useTranslation } from "react-i18next";
import { CheckboxOptions, CheckboxDefaultValue } from '../common/checkboxConfig';
import CheckboxGroup from '../common/CheckboxGroup';
import { MeasurementField } from '../common/MeasurementField';

const DamperForm = () => {
    const { t } = useTranslation();

    return (
        <form className="ahutest-container" >
            <Label className="ahutest-from--title">{t("Dampers")}</Label>

            <div className="flexboxAlignedColumn" style={{ padding: "2%" }}>
                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Outdoor air damper actuator operation")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="outdoorAirDampers-damp"
                    />
                </div>
                <MeasurementField properyName="outdoorAirDampers-damp" showValue={false} />

                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Exhaust air damper actuator operation")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="exhaustAirDampers-damp"
                    />
                </div>
                <MeasurementField properyName="exhaustAirDampers-damp" showValue={false} />

                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Operation of the recirculation damper actuator")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="operationAirDampers-damp"
                    />
                </div>
                <MeasurementField properyName="operationAirDampers-damp" showValue={false} />
            </div>
        </form>
    )
}

export default DamperForm