import { IconButton } from "@mui/material";
import { AxiosResponse } from "axios";
import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Label } from "reactstrap";
import {
  PurchaseOrderCommon,
  PurchaseOrderRowCommon,
} from "../../../types/directo/PurchaseOrderCommon";
import ClearIcon from "@mui/icons-material/Clear";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useTkalDataContext } from "../../../context/tkalData";
import { DeleteSepuTest, GetSavedSepuTests, GetSepuTest, GetUniversalPurchaseOrder } from "../../queries/testQueries";
import SelectTestType from "../../common/SelectTestType";
import { SepuTest, SepuTestType } from "../../../types/sepu/SepuTest";
import { returnDateFromDateString } from "../CommonFunctions";
import { handleOpenWebsite } from '../CommonFunctions';
const SelectSepuTest = () => {
  const { t } = useTranslation();
  const {
    setItemNumber,
    itemNumber,
    setIsLoading,
    orderNumber,
    setOrderNumber,
    isLoading,
    savedSepuTests,
    setSavedSepuTests,
    setSepuTest,
    sepuTest,
    purchaseOrderData,
    setPurchaseOrderData
  } = useTkalDataContext();

  const handleGetOrderData = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    if (!orderNumber) return alert(t("SalesorderNumberAlert"));
    GetUniversalPurchaseOrder(+orderNumber).then((res: AxiosResponse<PurchaseOrderCommon>) => {
      setSepuTest({ ...sepuTest, purchaseOrderNumber: orderNumber })
      setPurchaseOrderData(res.data)
    }).catch((err) => {
      if (err.response.status === 401 || err.response.status === 403) return alert("You dont have enough permissions to access this data")
      else {
        alert("Did not find any items");
        console.log(err.response.data)
      }
    }).finally(() => {
      setIsLoading(false);
    })

  };

  const handleItemRequest = async (item: PurchaseOrderRowCommon) => {
    setItemNumber(item.itemCode);
    setSepuTest({
      ...sepuTest,
      purchaseOrderNumber: orderNumber!.toString(),
      productArticleNumber: item.itemCode,
      productName: item.description,
      productUniqueId: item.id,
      testType: SepuTestType.EMPTY
    });
    if (!orderNumber) return null;
    GetSavedSepuTests(orderNumber, item.id).then((res) => {
      setSavedSepuTests(res.data)
    }).catch(() => { }).finally(() => { })
  };

  const handleTestType = (value: any) => {
    setSepuTest(prev => ({ ...prev, testType: value, id: 0 }))
  };

  const handleLoadTestData = (id: number) => {
    setIsLoading(true)
    if (sepuTest.id === null) return;
    GetSepuTest(id).then((res) => {
      setSepuTest(res.data)
    }).catch((err) => {
      console.log(err)
    }).finally(() => {
      setIsLoading(false)
    })
  }

  const handleRemoveTest = (e: any, id: number) => {
    DeleteSepuTest(id).then(() => {
      setSavedSepuTests(prev => prev!.filter((item, _) => item.id !== id));
      setSepuTest({ measurements: {} } as SepuTest)
    }).catch((err) => {
      console.log(err)
    }).finally(() => {
      e.stopPropagation()
      setIsLoading(false)
    })
  }

  return (
    <div className="mainPageLayout">
      <div
        className="flexboxAlignedColumn mainPageForm"
        style={{ marginTop: "3%" }}
      >
        <form
          className="flexboxAlignedColumn mainPageForm"
          style={{ marginTop: "10%" }}
          onSubmit={(e) =>
            handleGetOrderData(e ?? `${alert(t("ItemNotSelectedAlert"))}`)
          }
        >
          <div className="flexboxAlignedColumn" style={{ padding: "2%" }}>
            <Label className="mainPageForm-label">{t("InsertOrder")}</Label>
            <div className="flexboxAlignedRow" style={{ gap: "5%" }}>
              <input
                className="input-orderNr"
                type="number"
                onChange={(e) => setOrderNumber(e.target.value)}
                value={orderNumber ?? ""}
                placeholder={t("InsertNumber")}
              />
              <Button
                className="button-primary"
                style={{ width: "94px" }}
                type="submit"
              >
                {t("Search")}
              </Button>
            </div>
          </div>

          {purchaseOrderData.items && (
            <React.Fragment>
              <Label className="mainPageForm-label" style={{ marginTop: "2%" }}>
                {t("Vali tooted")}
              </Label>
              <div className="orderData">
                {(purchaseOrderData.items).map((x, idx) => {
                  return (
                    <div className="select-order-form__grid" key={idx}>
                      <div>
                        {/* <PrinterModal item={x} isPrintingAllowed={isPrintingAllowed} printFunction={printProductCode} printFunctionArgs={[x.serialNumber]} defaultPrinter="OZ_Printer" printerLabeL={x.isOzoneGenerator ? "OzGeneratorSerialNr" : "OzProductCodeLabel"} /> */}
                      </div>
                      <div className={`select-order-grid__label${(x.id === sepuTest.productUniqueId) ? " active" : ""}`}>
                        <div className="itemData" onClick={() => handleItemRequest(x)}>{x.itemCode} {x.description}</div>

                      </div>
                      <div className="select-order-grid__icon2">
                        {/* {x.testStatus === "Finished" ?
                                          <IconButton title="Testi staatus">
                                              <DoneIcon style={{ fill: "green" }} />
                                          </IconButton> :
                                          <IconButton title="Testi staatus">
                                              <CloseIcon style={{ fill: '#FF0000' }} />
                                          </IconButton>} */}
                      </div>
                    </div>
                  );
                })}
              </div>

              {itemNumber && (
                <SelectTestType
                  handleTestType={handleTestType}
                  testType={sepuTest.testType}
                  enumType={SepuTestType}
                />
              )}

              {savedSepuTests && savedSepuTests.length > 0 ? (
                <div className="orderData" style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>

                  {savedSepuTests.filter(x => x.testStatus === "Created").length > 0 &&
                    <>
                      <Label className="mainPageForm-label" style={{ marginTop: "5%" }}>{t("TestsInProgress")}</Label>
                      {savedSepuTests.filter(x => x.testStatus === "Created").map((x, idx) =>
                        <div key={idx} style={{ height: "100%", minHeight: "70px", width: "80%", display: "flex", justifyContent: "center" }}>
                          <div style={{ height: "100%", minHeight: "70px", display: "flex", justifyContent: "center" }} className={`orderDataOrders${x.id === sepuTest.id ? " active" : ""}`}
                            onClick={() => handleLoadTestData(x.id)}>{x.productName} - {t(`${x.testName}`)} - {x.createdBy} - {returnDateFromDateString(x.createdDate.toString())}
                          </div>
                          <IconButton style={{ marginTop: "4%" }} onClick={(e: any) => handleRemoveTest(e, x.id!)}>
                            <ClearIcon />
                          </IconButton>
                        </div>)}
                    </>
                  }
                  {savedSepuTests.filter(x => x.testStatus === "Finished").length > 0 &&
                    <>
                      <Label className="mainPageForm-label" style={{ marginTop: "5%" }}>{t("TestsFinished")}</Label>
                      {savedSepuTests.filter(x => x.testStatus === "Finished").map((x, idx) =>
                        <div key={idx} style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", gap: "25px" }}>
                          <div key={idx} style={{ height: "100%", minHeight: "70px", width: "70%", display: "flex", justifyContent: "center" }} className={`orderDataOrders${x.id === sepuTest.id ? " active" : ""}`}
                            onClick={() => handleLoadTestData(x.id)}>{x.productName} - {t(`${x.testName}`)} - {x.createdBy} - {returnDateFromDateString(x.createdDate.toString())}
                          </div>
                          <IconButton disabled={isLoading} style={{ marginTop: "4%", cursor: "pointer" }} onClick={() => handleOpenWebsite(x.directoCode)}>
                            <OpenInNewIcon />
                          </IconButton>
                        </div>
                      )}
                    </>
                  }
                </div>
              ) : null}
            </React.Fragment>
          )}
        </form>
      </div>
    </div>
  );
};

export default SelectSepuTest;
