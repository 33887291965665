import { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap'
import ElectricalMeasurementsForm from '../../components/configurationForms/ahu/measurements/ElectricalMeasurementsForm';
import ElectricalMeasurementsForm2 from '../../components/configurationForms/ahu/measurements/ElectrialMeasurementsForm2';
import StressTestForm from '../../components/configurationForms/ahu/measurements/StressTestForm';
import IdentityFormElectrical from '../../components/configurationForms/ahu/measurements/IdentityFormElectricaL';
import { useAhuDataContext } from '../../context/ahuData';
import SelectAhuTestForm from '../../components/configurationForms/ahu/SelectOrderForm';
import { createAhuTest, postAhuTest, sendAhuTestToDirecto } from '../../components/queries/testQueries';
import { AxiosResponse } from 'axios';
import { AhuElectricalMeasurements } from '../../types/ahu/AhuElectricalMeasurements';
import AhuMeasurementsOverview from '../../components/configurationForms/ahu/measurements/AhuMeasurementsOverview';
import StructureForm from '../../components/configurationForms/ahu/inspection-log/StructureForm';
import InletFanForm from '../../components/configurationForms/ahu/inspection-log/InletFanForm';
import ExhaustFanForm from '../../components/configurationForms/ahu/inspection-log/ExhaustFanForm';
import PreheatingCoilForm from '../../components/configurationForms/ahu/inspection-log/PreheatingCoilForm';
import RadiatorForm from '../../components/configurationForms/ahu/inspection-log/RadiatorForm';
import DamperForm from '../../components/configurationForms/ahu/inspection-log/DamperForm';
import WaterRadiatorForm from '../../components/configurationForms/ahu/inspection-log/WaterRadiatorForm';
import FilterForm from '../../components/configurationForms/ahu/inspection-log/FilterForm';
import TemperatureSensorsForm from '../../components/configurationForms/ahu/inspection-log/TemperatureSensorsForm';
import OtherChecksForm from '../../components/configurationForms/ahu/inspection-log/OtherChecksForm';
import { SalesOrder } from '../../types/directo/SalesOrder';
import { Item } from '../../types/directo/Item';
import { CommonTestData } from '../../types/ahu/CommonTestData';
import LtoForm from '../../components/configurationForms/ahu/inspection-log/LtoForm';
import HomeIcon from '@mui/icons-material/Home';
import { IconButton } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import IdentityFormCooler from '../../components/configurationForms/ahu/cooler-protocol/IdentityFormCooler';
import MeasurementsFormCooler from '../../components/configurationForms/ahu/cooler-protocol/MeasurementsFormCooler';
import CoolerTestForm1 from '../../components/configurationForms/ahu/cooler-test/CoolerTestForm1';
import IdentityFormCoolerTest from '../../components/configurationForms/ahu/cooler-test/IdentityFormCoolerTest';
import CoolerTestForm2 from '../../components/configurationForms/ahu/cooler-test/CoolerTestForm2';
import CoolerTestForm3 from '../../components/configurationForms/ahu/cooler-test/CoolerTestForm3';

const AhuTestStartPage = () => {
    const { t } = useTranslation();
    const [step, setStep] = useState(1);
    const { electricalMeasurementData, setElectricalMeasurementData, setIsLoading, isLoading, itemDescription, setCreatedTests, setSalesOrderData, setItemData, setTestType, setItemNumber } = useAhuDataContext();
    let electricalTestSteps = 6;
    let inspectionLogSteps = 14;
    let coolerProtocolSteps = 3;
    const coolerTestProtocolSteps = 5;

    const handleSendData = async () => {
        setIsLoading(true)

        var data: AhuElectricalMeasurements = {
            ...electricalMeasurementData,
            itemDescription: itemDescription!
        }
        // Make the first HTTP request
        try {
            postAhuTest(data);
        }
        catch (err: any) {
            console.log(err.response.data);
            setIsLoading(false);
            return;
        }

        // Make the second HTTP request
        try {
            await sendAhuTestToDirecto(data);
            // Reset the form data and go back to the first step
            setElectricalMeasurementData({ commonTestData: {} as CommonTestData, measurements: {} } as AhuElectricalMeasurements)
            setSalesOrderData({} as SalesOrder);
            setItemData({} as Item);
            setCreatedTests([])
            setTestType("")
            setItemNumber("")
            setStep(1);
            alert("Success")
        } catch (err: any) {
            console.log(err.response.data);

        } finally {
            setIsLoading(false);
        }
    }

    const handleSaveTestData = () => {
        var data: AhuElectricalMeasurements = {
            ...electricalMeasurementData,

            itemDescription: itemDescription!
        }

        try {
            setIsLoading(true)
            postAhuTest(data);
        }
        catch (err: any) {
            console.log(err.response.data);
            setIsLoading(false);
            return;
        }
        finally {
            setIsLoading(false);
        }
    }

    const handleCreateAhuTest = () => {
        if (electricalMeasurementData.testType)
            createAhuTest(electricalMeasurementData, electricalMeasurementData.testType).then((res: AxiosResponse<AhuElectricalMeasurements>) => {
                setElectricalMeasurementData(res.data)
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                setIsLoading(false)
            })
    }

    const handleNextButton = () => {
        if (step >= 2) {
            //save test
            handleSaveTestData()
        }
        if (electricalMeasurementData.commonTestData.ltoType === (undefined) && electricalMeasurementData.testType === "inspection log") {
            alert("Please select LTO type")
            return;
        }
        if (electricalMeasurementData.commonTestData.preHeater === false && step === 6) {
            if (electricalMeasurementData.commonTestData.electricRadiator === false) {
                setStep(prev => prev + 3)
            }
            else {
                setStep(prev => prev + 2)
            }
            return;
        }
        if (electricalMeasurementData.commonTestData.electricRadiator === false && step === 7) {
            setStep(prev => prev + 2)
            return;
        }
        if (electricalMeasurementData.commonTestData.waterRadiator === false && step === 9) {
            setStep(prev => prev + 2)
            return;
        }
        if ((step === 1 && electricalMeasurementData.measurements === (undefined || null)) || (step === 1 && !electricalMeasurementData.id)) {
            handleCreateAhuTest();
            setStep(prev => prev + 1)
        }
        else {
            setStep(prev => prev + 1)
        }
    }

    const handleBackButton = () => {
        if (electricalMeasurementData.commonTestData.waterRadiator === false && step === 11) {
            setStep(prev => prev - 2)
            return;
        }
        if (electricalMeasurementData.commonTestData.electricRadiator === false && step === 9) {
            if (electricalMeasurementData.commonTestData.preHeater === false) {
                setStep(prev => prev - 3)
            }
            else {
                setStep(prev => prev - 2)
            }
            return;
        }
        if (step >= 2) {
            setStep(prev => prev - 1);
        }
    }

    const validateNextStep = () => {
        if (step < electricalTestSteps && electricalMeasurementData.testType === "electrical measurement") {
            return true;
        }
        if (step < inspectionLogSteps && electricalMeasurementData.testType === "inspection log") {
            return true;
        }
        if (step < coolerProtocolSteps && electricalMeasurementData.testType === "cooler protocol") {
            return true;
        }
        if (step < coolerTestProtocolSteps && electricalMeasurementData.testType === "cooler test protocol") {
            return true;
        }
        return false;
    }

    const validateLastStep = () => {
        if (step === electricalTestSteps && electricalMeasurementData.testType === "electrical measurement") {
            return true;
        }
        if (step === inspectionLogSteps && electricalMeasurementData.testType === "inspection log") {
            return true;
        }
        if (step === coolerProtocolSteps && electricalMeasurementData.testType === "cooler protocol") {
            return true;
        }
        if (step === coolerTestProtocolSteps && electricalMeasurementData.testType === "cooler test protocol") {
            return true;
        }
        return false;
    }

    const chooseFirstStepButtonKey = () => {
        if (step === 1) {
            if (electricalMeasurementData.id) return t("Open")
            if (!electricalMeasurementData.id) return t("Next")
        }
        if (step > 1) return t("Next")
    }

    return (
        <div className="mainPageLayout">
            {
                (() => {
                    if (step === 1) {
                        return <SelectAhuTestForm />
                    }
                    switch (electricalMeasurementData.testType) {
                        case "cooler test protocol":
                            switch (step) {
                                case 2:
                                    return <IdentityFormCoolerTest />
                                case 3:
                                    return <CoolerTestForm1 />
                                case 4:
                                    return <CoolerTestForm2 />
                                case 5:
                                    return <CoolerTestForm3 />
                            }
                            break;
                        case "cooler protocol":
                            switch (step) {
                                case 2:
                                    return <IdentityFormCooler />
                                case 3:
                                    return <MeasurementsFormCooler />
                            }
                            break;
                        case "electrical measurement":
                            switch (step) {
                                case 2:
                                    return <IdentityFormElectrical />
                                case 3:
                                    return <StressTestForm />
                                case 4:
                                    return <ElectricalMeasurementsForm />
                                case 5:
                                    return <ElectricalMeasurementsForm2 />
                                case 6:
                                    return <AhuMeasurementsOverview testType={electricalMeasurementData.testType} />
                            }
                            break;
                        case "inspection log":
                            switch (step) {
                                case 2:
                                    return <IdentityFormElectrical />
                                case 3:
                                    return <StructureForm />
                                case 4:
                                    return <LtoForm />
                                case 5:
                                    return <InletFanForm />
                                case 6:
                                    return <ExhaustFanForm />
                                case 7:
                                    return <PreheatingCoilForm />
                                case 8:
                                    return <RadiatorForm />
                                case 9:
                                    return <DamperForm />
                                case 10:
                                    return <WaterRadiatorForm />
                                case 11:
                                    return <FilterForm />
                                case 12:
                                    return <TemperatureSensorsForm />
                                case 13:
                                    return <OtherChecksForm />
                                case 14:
                                    return <AhuMeasurementsOverview testType={electricalMeasurementData.testType} />
                            }
                    }
                })()
            }

            <div className="navigation-buttons-container">
                {step >= 2 && <Button className="button-primary" onClick={handleBackButton}>{t("Back")}</Button>}
                {step > 1 &&
                    <>
                        <IconButton onClick={() => setStep(1)}>
                            <HomeIcon />
                        </IconButton>
                        <IconButton onClick={() => handleSaveTestData()}>
                            <SaveIcon />
                        </IconButton>
                    </>
                }

                {validateNextStep() && <Button className="button-primary" onClick={handleNextButton}>{chooseFirstStepButtonKey()}</Button>}
                {validateLastStep() && <Button className="button-primary" style={{ width: "170px", backgroundColor: "white", color: "red" }} onClick={handleSendData} disabled={isLoading /* || electricalMeasurementData.directoCode != null */}>{t("ConfirmAndSend")} </Button>}
            </div>
        </div>
    )
}

export default AhuTestStartPage


