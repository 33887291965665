import React, { createContext, useContext, useState } from "react";
import Loading from "../components/common/Loading";
import { getItemRequest, getItemRequestSE, getOrderRequest, getProductionOrderRequest } from "../components/queries/testQueries";
import { Item } from "../types/directo/Item";
import { DuctTestType } from "../types/DuctTestType";
import { Order } from "../types/directo/Order";

interface props {
    setOrderNumber: React.Dispatch<React.SetStateAction<string | undefined>>,
    orderNumber: string | undefined,
    itemNumber: string | undefined,
    setItemNumber: React.Dispatch<React.SetStateAction<string | undefined>>
    testInterface: string | undefined;
    setTestInterface: React.Dispatch<React.SetStateAction<string | undefined>>,

    itemData: Item,
    setItemData: React.Dispatch<React.SetStateAction<Item>>,
    orderData: Order,
    setOrderData: React.Dispatch<React.SetStateAction<Order>>,
    testData: DuctTestType,
    setTestData: React.Dispatch<React.SetStateAction<DuctTestType>>,
    handleDynamicChange: (event: React.ChangeEvent<{
        value: string | number;
        name: string;
    }>) => void
    handleDynamicClick: any,
    handleOrderSubmit: (e: any, country: string) => void,
    handleItemNumberChange: (itemCode: string, country: string) => void,

    whiteRustProblem: string,
    stiffnessProblem: string,
    rollWidthProblem: string,
    setStiffnessProblem: React.Dispatch<React.SetStateAction<string>>
    setRollWidthProblem: React.Dispatch<React.SetStateAction<string>>
    setWhiteRustProblem: React.Dispatch<React.SetStateAction<string>>

    isRollWidthBoxOpen: boolean,
    isWhiteRustBoxOpen: boolean,
    isStiffnessBoxOpen: boolean,
    setIsRollWidthBoxOpen: React.Dispatch<React.SetStateAction<boolean>>,
    setIsWhiteRustBoxOpen: React.Dispatch<React.SetStateAction<boolean>>,
    setIsStiffnessBoxOpen: React.Dispatch<React.SetStateAction<boolean>>,

    isLoading: boolean,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
}

export const TestDataContext = createContext({} as props);
export const useDataContext = () => useContext(TestDataContext);
export const DataContextWrapper = ({ children }: any) => {
    const [orderNumber, setOrderNumber] = useState<string>();
    const [itemNumber, setItemNumber] = useState<string>();
    const [testInterface, setTestInterface] = useState<string>();
    const [orderData, setOrderData] = useState<Order>({} as Order);
    const [itemData, setItemData] = useState<Item>({} as Item);
    const [testData, setTestData] = useState<DuctTestType>({ description: "" } as DuctTestType);

    const [isRollWidthBoxOpen, setIsRollWidthBoxOpen] = useState<boolean>(false);
    const [isWhiteRustBoxOpen, setIsWhiteRustBoxOpen] = useState<boolean>(false);
    const [isStiffnessBoxOpen, setIsStiffnessBoxOpen] = useState<boolean>(false);

    const [rollWidthProblem, setRollWidthProblem] = useState<string>("");
    const [whiteRustProblem, setWhiteRustProblem] = useState<string>("");
    const [stiffnessProblem, setStiffnessProblem] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleDynamicChange = (event: any) => {
        setTestData({
            ...testData,
            [event.target.name]: event.target.value
        })
    };

    const handleDynamicClick = (event: any, value: any) => {
        setTestData({
            ...testData,
            [event.target.name]: value
        })
    };
    const handleOrderSubmit = (e: any, country: string) => {
        if (country === null || undefined) return
        if (orderNumber && orderNumber?.length === 8) {
            setIsLoading(true);
            setItemNumber("")
            setItemData({} as Item)
            setTestData({ description: "" } as DuctTestType)
            clearMaterialProblems()

            handleOrderRequestByCountry(country, orderNumber)
                .then((response) => {
                    setTestData({
                        ...testData,
                        testName: "",
                        orderNumber: orderNumber,
                        customerName: response.data.supplierName,
                        customerCode: response.data.supplierCode
                    })

                    setOrderData(response.data);
                }).catch(err => {
                    console.log(err);
                })
                .finally(() => setIsLoading(false))
        }
        e.preventDefault();
    }
    const handleOrderRequestByCountry = (country: string, orderNumber: string) => {
        if (country === "sweden") {
            return getProductionOrderRequest(orderNumber)
        }
        return getOrderRequest(orderNumber)
    }

    const handleItemNumberChange = (itemCode: string, country: string) => {
        setItemNumber(itemCode);
        setTestData({
            ...testData,
            diameter: ("")
        })
        handleItemRequest(itemCode, country);
    }
    const handleItemRequest = async (itemCode: string, country: string) => {
        if (itemCode) {
            setIsLoading(true);

            handleItemRequestByCountry(itemCode, country).then((response) => {
                setItemData(response.data);
                setTestData({ ...testData, itemNumber: itemCode, itemName: response.data.description, testName: setTestType(response.data.category) });
            }).catch(err => console.log(err)).finally(() => setIsLoading(false));
        }
    }

    const handleItemRequestByCountry = (itemCode: string, country: string) => {
        if (country === "sweden") {
            return getItemRequestSE(itemCode)
        }
        return getItemRequest(itemCode)
    }

    const setTestType = (category: string) => {
        switch (category) {
            case "CB03 Ümarad kanalid":
                return "ntoTest"
            case "CB04 Ümarad kanaliosad":
                return "ductFittingsTest"
            case "CE01 Ümarad mürasummutid":
                return "ductFittingsTest"
            case "CB01 Kandilised kanalid":
                return "ductFittingsTest"
            case "CB02 Kandilised kanaliosad":
                return "ductFittingsTest"
            case "VREC":
                return "AHU commisioning test"
        }
        return "unknown"
    }

    const clearMaterialProblems = () => {
        setStiffnessProblem("");
        setRollWidthProblem("");
        setWhiteRustProblem("");
        setIsRollWidthBoxOpen(false);
        setIsStiffnessBoxOpen(false);
        setIsWhiteRustBoxOpen(false);
    }

    return (
        <TestDataContext.Provider value={{
            setOrderNumber, orderNumber, itemNumber, setItemNumber, testInterface, setTestInterface,
            itemData, setItemData, orderData, setOrderData,
            testData, setTestData, handleDynamicChange, handleDynamicClick,
            handleOrderSubmit, handleItemNumberChange,
            whiteRustProblem, setWhiteRustProblem, stiffnessProblem, setStiffnessProblem, rollWidthProblem, setRollWidthProblem,
            isRollWidthBoxOpen, isWhiteRustBoxOpen, isStiffnessBoxOpen, setIsRollWidthBoxOpen, setIsWhiteRustBoxOpen, setIsStiffnessBoxOpen,
            isLoading, setIsLoading
        }}>
            <>
                {isLoading && <Loading />}
                {children}
            </>
        </TestDataContext.Provider>
    );
}


