import React, { useState } from 'react'
import { Button, Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useTkalDataContext } from '../../context/tkalData';
import { AxiosResponse } from 'axios';
import { getPdf, getPurchaseOrderRequest, getRecipes } from '../../components/queries/testQueries';
import { CanopyPurchaseOrder, PurchaseOrderRow } from '../../types/directo/CanopyPurchaseOrder';
import { Recipe } from '../../types/directo/Recipe';
import { getPrinters, printProductNameList } from '../../components/queries/adminQueries';
import saveAs from 'file-saver';

const CanopyPage = () => {
    const { t } = useTranslation();
    const { setIsLoading, orderNumber, setOrderNumber, isLoading } = useTkalDataContext();
    const [orderData, setOrderData] = useState<CanopyPurchaseOrder>({} as CanopyPurchaseOrder);
    const [selectedItems, setSelectedItems] = useState<PurchaseOrderRow[]>([])

    const handleGetOrderData = async (e: any) => {
        e.preventDefault();
        setIsLoading(true);
        setSelectedItems([]);
        if (!orderNumber) return alert(t("SalesorderNumberAlert"));
        getPurchaseOrderRequest(orderNumber).then((res: AxiosResponse<CanopyPurchaseOrder>) => {
            setOrderData(res.data);
        }).catch((err) => {
            if (err.response.status === 401 || err.response.status === 403) return alert("You dont have enough permissions to access this data")
            else {
                alert("Did not find any items");
                console.log(err.response.data)
            }
        }).finally(() => {
            setIsLoading(false);
        })
    }

    const addSelectedItems = (x: PurchaseOrderRow) => {
        if (selectedItems.find(item => item.id === x.id) !== undefined) {
            //remove object from array
            setSelectedItems(prev => prev.filter((item, i) => item.id !== x.id));
        }
        else {
            //add object to array
            setSelectedItems(prev => [...prev, x]);

        }
    }

    const handleRecipeRequest = () => {
        if (selectedItems.length > 0) {
            setIsLoading(true)
            getRecipes(selectedItems).then((res: AxiosResponse<Recipe[]>) => {
                const selectedPrinter = "Tootmine_TKUBU"
                //const selectedPrinter = "2K_Kontor"
                printProductNameList(selectedPrinter, res.data)
            }).finally(() => { setIsLoading(false) })
        }
    }

    const handleLoadPdf = () => {
        if (selectedItems.length > 0) {
            setIsLoading(true)
            selectedItems.forEach((x, i) => {
                getPdf(x.itemCode, "1656", "retseptid").then((response) => {
                    const filename = `Kubu retsept ${x.itemCode}.pdf`;
                    saveAs(response.data, filename);
                })
                    .catch((err) => {
                        console.log(err)
                    })
                    .finally(() => {
                        setIsLoading(false)
                    })
            })
        }
    }

    const isItemSelected = (x: PurchaseOrderRow) => {
        const selectedItem = selectedItems.find(item =>
            item.id === x.id &&
            item.itemCode === x.itemCode
        );
        if (selectedItem !== undefined) return true
        return false;
    }

    return (
        <div className="mainPageLayout">
            <div className="flexboxAlignedColumn mainPageForm" style={{ marginTop: "3%" }}>
                <form className="flexboxAlignedColumn mainPageForm" style={{ marginTop: "10%" }} onSubmit={e => handleGetOrderData(e ?? `${alert(t("ItemNotSelectedAlert"))}`)}>

                    <div className="flexboxAlignedColumn" style={{ padding: "2%" }}>
                        <Label className="mainPageForm-label">{t("InsertOrder")}</Label>
                        <div className="flexboxAlignedRow" style={{ gap: "5%" }}>
                            <input className="input-orderNr" type="number" onChange={e => setOrderNumber(e.target.value)} value={orderNumber ?? ""} placeholder={t("InsertNumber")} />
                            <Button className="button-primary" style={{ width: "94px" }} type="submit">{t("Search")}</Button>
                        </div>
                    </div>

                    {orderData.items && (

                        <React.Fragment >

                            <Label className="mainPageForm-label" style={{ marginTop: "2%" }}>{t("Vali tooted")}</Label>
                            <div className="orderData">
                                {
                                    Object.values(orderData.items).map((x, idx) => {
                                        return <div className='select-order-form__grid' key={idx}>
                                            <div className={`select-order-grid__label${(isItemSelected(x) === true) ? " active" : ""}`}>
                                                <div className="itemData" onClick={(e) => addSelectedItems(x)}>{x.itemCode} {x.description}</div>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>

                            <div style={{ display: "flex", justifyContent: "center", width: "100%", gap: "15px", marginTop: "5%" }}>
                                <Button className="button-primary" style={{ width: "25%" }} onClick={handleRecipeRequest} disabled={isLoading}>Prindi sildid</Button>
                                <Button className="button-primary" style={{ width: "25%" }} onClick={handleLoadPdf} disabled={isLoading}>Prindi väljatrükk</Button>
                            </div>
                        </React.Fragment>
                    )}

                </form>
            </div>
        </div>
    )
}

export default CanopyPage