import { Button, Label } from "reactstrap"
import { useDataContext } from "../../../../context/ductTestData";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import '../../../../styles/buttons.css'
import '../../../../styles/input.css'
import { useTranslation } from "react-i18next";
import ReturnProgressBar from "../../../common/ReturnProgressBar";

const MeasurementTestForm = () => {
    const { itemData, testData, handleDynamicClick, setTestData, handleDynamicChange } = useDataContext();
    const { t, i18n } = useTranslation();

    const handleLengthTest = (e: any) => {
        e.preventDefault();
        var convertedLength = +itemData.length * 1000
        var errorRoom = 0.005 * convertedLength; //5mm per meter is valid
        if ((+testData.length! <= convertedLength) && (convertedLength - errorRoom) <= +testData.length!) {
            setTestData({
                ...testData,
                lengthTestResult: "Ok"
            })
        }
        else {
            setTestData({
                ...testData,
                lengthTestResult: "NotOk"
            })
        }
    }

    return (
        <div className="flexboxAlignedColumn mainPageForm">
            <ReturnProgressBar level="2" lang={i18n.language} />
            <Label className="mainPageForm-label2">{t("SelectItemToTest")}</Label>
            <div className="flexboxAlignedRow measurementTest-Row" style={{ padding: "2%" }}>
                <Button id="itemToTest" name="itemToTest" className={`flexboxAlignedRow orderData orderDataOrders button-selection-1 ${testData.itemToTest === "FirstItemTest" ? "active" : ""}`} style={{ marginRight: "2%", width: "30%" }} onClick={(e: any) => handleDynamicClick(e, "FirstItemTest")}>
                    {t("FirstItemTest")}
                </Button>
                <Button id="itemToTest" name="itemToTest" className={`flexboxAlignedRow orderData orderDataOrders button-selection-1 ${testData.itemToTest === "RandomItemTest" ? "active" : ""}`} style={{ marginRight: "2%", width: "30%" }} onClick={(e: any) => handleDynamicClick(e, "RandomItemTest")} >
                    {t("RandomItemTest")}
                </Button>
                <Button id="itemToTest" name="itemToTest" className={`flexboxAlignedRow orderData orderDataOrders button-selection-1 ${testData.itemToTest === "LastItemTest" ? "active" : ""}`} style={{ marginRight: "2%", width: "30%" }} onClick={(e) => handleDynamicClick(e, "LastItemTest")} >
                    {t("LastItemTest")}
                </Button>
            </div>

            <Label className="mainPageForm-label2">{t("Diameter")}</Label>
            <div className="flexboxAlignedRow measurementTest-Row" style={{ padding: "2%" }}>
                <Button id="diameter" name="diameter" className={`flexboxAlignedRow orderData orderDataOrders button-selection-1 ${testData.diameter === "Ok" ? "active" : ""}`} style={{ marginRight: "2%", width: "25%" }} onClick={(e: any) => handleDynamicClick(e, "Ok")} >
                    {t("DiameterCorrect")}
                </Button>
                <Button id="diameter" name="diameter" className={`flexboxAlignedRow orderData orderDataOrders button-selection-1 ${testData.diameter === "NotOk" ? "active" : ""}`} style={{ marginRight: "2%", width: "25%" }} onClick={(e: any) => handleDynamicClick(e, "NotOk")} >
                    {t("DiameterNotCorrect")}
                </Button>
            </div>
            <Label className="mainPageForm-label2">{t("Length")} (mm)
                {testData.lengthTestResult === "Ok" ? <div style={{ fontSize: "18px" }}>{t("TestPassed")} <DoneIcon style={{ fill: "green" }} /></div> : ""}
                {testData.lengthTestResult === "NotOk" ? <div style={{ fontSize: "18px" }}>{t("TestFailed")} <CloseIcon style={{ fill: '#CC0000' }} />  </div> : ""}
            </Label>
            <form className="flexboxAlignedRow" style={{ padding: "2%", width: "100%" }} onSubmit={handleLengthTest}>
                <input className="input-primary" name="length" type="number" value={testData.length || ''} placeholder={t("InsertLength")} onChange={handleDynamicChange} />
                {itemData.length && <Button className="button-lengthTest" type="submit">{t("TestLength")}</Button>}
            </form>
        </div>
    )
}
export default MeasurementTestForm