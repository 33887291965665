import React from 'react'
import { useTranslation } from 'react-i18next';
import { Button, Label } from 'reactstrap';
import { useTkalDataContext } from '../../../context/tkalData';
import InfoModal from '../ahu-estonia/InfoModal';
import { CheckboxDefaultValue, CheckboxOptions, CheckboxOptionsThumbsOnly } from '../ahu/common/checkboxConfig';
import CheckboxGroupOzonator from './common/CheckboxGroupOzonator';
import MeasurementFieldOzonator from './common/MeasurementFieldOzonator';
import { controlPanelCheckText, criticalErrorCheckText, masterSlaveConnectionText, operationStatusCheckText, ozonatorCalibrationText, pressureReadingText, serviceOutputCheckText, systemWorkPermissionText, thermometerCheckText } from './ModalTexts';
import '../../../styles/buttons.css'
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";

const OzonatorOperationCheckForm = () => {
    const { t } = useTranslation();
    const { ozonatorTest, setOzonatorTest } = useTkalDataContext()

    const handleChangeMeasurementsValueUnit = (event: any, propertyName: string) => {
        setOzonatorTest({
            ...ozonatorTest,
            "measurements": { ...ozonatorTest.measurements, [event.target.name]: { ...ozonatorTest.measurements[event.target.name], [propertyName]: event.target.value, unit: "" } }
        })
    }

    const handleCalculateMeasurementTolerance = () => {
        /*  var x = abs(abs(workCurrent)-abs(emptyCurrent) )
         var mõõteviga =abs(abs(x) - abs(ozCurrent) )
         mõõteviga<= +-50mA = ok */

        if (ozonatorTest.measurements["workCurrent-operations"]?.value && ozonatorTest.measurements["emptyCurrent-operations"]?.value) {
            var x = Math.abs(Math.abs(+ozonatorTest.measurements["workCurrent-operations"]?.value) - Math.abs(+ozonatorTest.measurements["emptyCurrent-operations"]?.value));

            if (x !== undefined && x !== null) {
                if (ozonatorTest.measurements["ozCurrent-operations"]?.value) {
                    var y = Math.abs(Math.abs(x) - Math.abs(+ozonatorTest.measurements["ozCurrent-operations"]?.value))
                    if (y > Math.abs(150)) {
                        setOzonatorTest({
                            ...ozonatorTest,
                            "measurements": {
                                ...ozonatorTest.measurements,
                                "toleranceTest-operations": { ...ozonatorTest.measurements["toleranceTest-operations"], "statusNew": "FAIL", unit: "", "value": y.toString() },
                                "powerCheck-operations": { ...ozonatorTest.measurements["powerCheck-operations"], "statusNew": "FAIL" }
                            }
                        })
                        return y;
                    }
                    else {
                        setOzonatorTest({
                            ...ozonatorTest,
                            "measurements": {
                                ...ozonatorTest.measurements,
                                "toleranceTest-operations": { ...ozonatorTest.measurements["toleranceTest-operations"], "statusNew": "OK", unit: "", "value": y.toString() },
                                "powerCheck-operations": { ...ozonatorTest.measurements["powerCheck-operations"], "statusNew": "OK" }
                            }
                        })
                        return y;
                    }
                }
            }
        }
        return "";
    }

    return (
        <form className="ahutest-container" >
            <Label className="ahutest-from--title">{t("Seadme töökorra testimine kambris")}</Label>
            <div className="flexboxAlignedColumn" style={{ padding: "2%" }}>
                <div className="flexboxAlignedRow" style={{ gap: "20px", marginBottom: "4%" }}>
                    <Label className="grid-item-label" style={{ width: "100%", marginLeft: "0" }}>{t("Tutvuge ohutusjuhendiga")}</Label>
                    <InfoModal title={t("Juhend")} bodyText={"Sulge seadme kaaned ning paigalda osonaator osoonikambrisse."} />
                </div>

                <div className="ahutest-form__label-info-button--grid" >
                    <Label className="grid-item-label" >{t("Master-Slave ühendus toimib")}</Label>
                    <InfoModal title={t("Juhend")} bodyText={masterSlaveConnectionText} />
                    <CheckboxGroupOzonator
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="msConnection-operations"
                    />
                </div>
                <MeasurementFieldOzonator propertyName="msConnection-operations" showValue={false} />

                <div className="ahutest-form__label-info-button--grid" >
                    <Label className="grid-item-label" >{t("Rõhu lugemine toimib")}</Label>
                    <InfoModal title={t("Juhend")} bodyText={pressureReadingText} />
                    <CheckboxGroupOzonator
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="pressure-operations"
                    />
                </div>
                <MeasurementFieldOzonator propertyName="pressure-operations" showValue={false} />

                <div className="ahutest-form__label-info-button--grid" >
                    <Label className="grid-item-label" >{t("Loarežiimi juhtimisviis")}</Label>
                    <InfoModal title={t("Juhend")} bodyText={systemWorkPermissionText} />
                    <CheckboxGroupOzonator
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="workPermit-operations"
                    />
                </div>
                <MeasurementFieldOzonator propertyName="workPermit-operations" showValue={false} />

                <div className="ahutest-form__label-info-button--grid" >
                    <Label className="grid-item-label" >{t("Osonaatori kalibreerimise kontroll")}</Label>
                    <InfoModal title={t("Juhend")} bodyText={ozonatorCalibrationText} />
                    <CheckboxGroupOzonator
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="calibrate-operations"
                    />
                </div>
                <MeasurementFieldOzonator propertyName="calibrate-operations" showValue={false} />

                <div className="ahutest-form__label-info-button--grid" >
                    <Label className="grid-item-label" >{t("Juhtimispaneelist (LCD ekraan) ON/OFF lülituse kontroll")}</Label>
                    <InfoModal title={t("Juhend")} bodyText={controlPanelCheckText} />
                    <CheckboxGroupOzonator
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="panel-operations"
                    />
                </div>
                <MeasurementFieldOzonator propertyName="panel-operations" showValue={false} />

                <div className="ahutest-form__label-info-button--grid" style={{ marginBottom: "4%" }}>
                    <Label className="grid-item-label" >{t("Voolu ja võimsuse mõõtmise kontroll")}</Label>
                    <InfoModal title={t("Juhend")} bodyText={"Ühenda osonaator voolu ja võimsuse mõõteseadmega. Osonaatori toitekaabel peab olema ühendatud läbi mõõteseadme."} />
                    <CheckboxGroupOzonator
                        options={CheckboxOptionsThumbsOnly}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="powerCheck-operations"
                    />
                </div>
                <div className="flexboxAlignedColumn" style={{ gap: "15px", width: "80%" }}>

                    <div className="flexboxAlignedRow">
                        <div className='ahutest-form__input --value' style={{ border: "none", width: "450px", textAlign: "left", fontSize: "18px", marginBottom: "2%" }}>{t("Voolu ja võimsuse tarbimine tühijooksul (mõõteseadme väärtus)")}</div>
                        <InfoModal title={t("Juhend")} bodyText={`Märkida voolu ja võimuse väärtus mõõteseadmelt kui System state on olekus -> "OFF".`} />
                    </div>
                    <div className="flexboxAlignedRow" style={{ gap: "25px" }}>
                        <div className="ahutest-form__input" style={{ display: "flex", justifyContent: "center", width: "50%" }}>
                            <input className="ahutest-form__input" style={{ border: "none", width: "50%" }} placeholder='Võimsus' name="emptyPower-operations" value={ozonatorTest.measurements["emptyPower-operations"]?.value ?? ""} onChange={e => handleChangeMeasurementsValueUnit(e, "value")} />
                            <div>W</div>
                        </div>
                        <div className="ahutest-form__input" style={{ display: "flex", justifyContent: "center", width: "50%" }}>
                            <input className="ahutest-form__input" style={{ border: "none", width: "50%" }} placeholder='Vool' name="emptyCurrent-operations" value={ozonatorTest.measurements["emptyCurrent-operations"]?.value ?? ""} onChange={e => handleChangeMeasurementsValueUnit(e, "value")} />
                            <div>mA</div>
                        </div>
                    </div>
                </div>

                <div className="flexboxAlignedColumn" style={{ gap: "15px", width: "80%" }}>
                    <div className="flexboxAlignedRow">
                        <div className='ahutest-form__input --value' style={{ border: "none", width: "450px", textAlign: "left", fontSize: "18px", marginBottom: "2%" }}>{t("Voolu ja võimsuse tarbimine osonaatori töötamisel (mõõteseadme väärtus)")}</div>
                        <InfoModal title={t("Juhend")} bodyText={`Märkida voolu ja võimuse väärtus mõõteseadmelt kui System state on olekus -> "ON" ja osooni generaatorid töötavad.\nMõõtetulemus kirjutada ülesse peale 2 minutit osonaatori töötamist`} />
                    </div>
                    <div className="flexboxAlignedRow" style={{ gap: "25px" }}>
                        <div className="ahutest-form__input" style={{ display: "flex", justifyContent: "center", width: "50%" }}>
                            <input className="ahutest-form__input" style={{ border: "none", width: "50%" }} placeholder='Võimsus' name="workPower-operations" value={ozonatorTest.measurements["workPower-operations"]?.value ?? ""} onChange={e => handleChangeMeasurementsValueUnit(e, "value")} />
                            <div>W</div>
                        </div>

                        <div className="ahutest-form__input" style={{ display: "flex", justifyContent: "center", width: "50%" }}>
                            <input className="ahutest-form__input" style={{ border: "none", width: "50%" }} placeholder='Vool' name="workCurrent-operations" value={ozonatorTest.measurements["workCurrent-operations"]?.value ?? ""} onChange={e => handleChangeMeasurementsValueUnit(e, "value")} />
                            <div>mA</div>
                        </div>
                    </div>
                </div>
                <div className="flexboxAlignedColumn" style={{ gap: "15px" }}>
                    <div className="flexboxAlignedRow" >
                        <div className='ahutest-form__input --value' style={{ border: "none", width: "450px", textAlign: "left", fontSize: "18px", marginBottom: "2%" }}>{t("Voolu tarbimine osonaatori töötamisel (osonaatori mõõteväärtus)")}</div>
                        <InfoModal title={t("Juhend")} bodyText={`Märkida voolu väärtus osonaatori LCD-s kui System state on olekus -> "ON" ja osooni generaatorid töötavad. Testing menu -> OZ electrical current.`} />
                    </div>
                    <div className="ahutest-form__input" style={{ display: "flex", justifyContent: "center", width: "50%" }}>
                        <input className="ahutest-form__input" style={{ border: "none", width: "50%" }} placeholder='Vool' name="ozCurrent-operations" value={ozonatorTest.measurements["ozCurrent-operations"]?.value ?? ""} onChange={e => handleChangeMeasurementsValueUnit(e, "value")} />
                        <div>mA</div>
                    </div>

                </div>
                <div className="flexboxAlignedColumn" style={{ gap: "15px" }}>
                    <Label className='ahutest-form__input --value' style={{ border: "none", width: "490px", textAlign: "left", fontSize: "18px", marginBottom: "2%" }}>{t("Voolu mõõtmise viga (arvutuslik)")}
                        {ozonatorTest.measurements["toleranceTest-operations"]?.statusNew === "OK" ? <div style={{ fontSize: "18px", marginTop: "1%", width: "100%", textAlign: "center" }}>{t("Voolu mõõtmise viga tolerantsis")} <DoneIcon style={{ fill: "green" }} /></div> : ""}
                        {ozonatorTest.measurements["toleranceTest-operations"]?.statusNew === "FAIL" ? <div style={{ fontSize: "18px", marginTop: "1%", width: "100%", textAlign: "center" }}>{t("Voolu mõõtmise viga üle tolerantsi")} <CloseIcon style={{ fill: '#CC0000' }} />  </div> : ""}
                    </Label>
                    <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <div className="ahutest-form__input" style={{ display: "flex", justifyContent: "center", width: "50%" }}>
                            <input readOnly={true} className="ahutest-form__input" style={{ border: "none", width: "40%" }} placeholder='Vool' name="toleranceTest-operations" value={ozonatorTest.measurements["toleranceTest-operations"]?.value ?? ""} />
                            <div>mA</div>
                        </div>
                        <Button className="button-lengthTest" onClick={handleCalculateMeasurementTolerance}>Kontrolli</Button>
                    </div>
                </div>

                <div className="ahutest-form__label-info-button--grid" >
                    <Label className="grid-item-label" >{t("Temperatuuri anduri kontroll")}</Label>
                    <InfoModal title={t("Juhend")} bodyText={thermometerCheckText} />
                    <CheckboxGroupOzonator
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="temp-operations"
                    />
                </div>
                <MeasurementFieldOzonator propertyName="temp-operations" showValue={false} />

                <div className="ahutest-form__label-info-button--grid" >
                    <Label className="grid-item-label" >{t("Operation status väljundi kontroll")}</Label>
                    <InfoModal title={t("Juhend")} bodyText={operationStatusCheckText} />
                    <CheckboxGroupOzonator
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="status-operations"
                    />
                </div>
                <MeasurementFieldOzonator propertyName="status-operations" showValue={false} />

                <div className="ahutest-form__label-info-button--grid" >
                    <Label className="grid-item-label" >{t("Critical error väljundi kontroll")}</Label>
                    <InfoModal title={t("Juhend")} bodyText={criticalErrorCheckText} />
                    <CheckboxGroupOzonator
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="error-operations"
                    />
                </div>
                <MeasurementFieldOzonator propertyName="error-operations" showValue={false} />

                <div className="ahutest-form__label-info-button--grid" >
                    <Label className="grid-item-label" >{t("Service väljundi kontroll")}</Label>
                    <InfoModal title={t("Juhend")} bodyText={serviceOutputCheckText} />
                    <CheckboxGroupOzonator
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="service-operations"
                    />
                </div>
                <MeasurementFieldOzonator propertyName="service-operations" showValue={false} />
            </div>
        </form>
    )
}

export default OzonatorOperationCheckForm