import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Button, Label } from 'reactstrap'
import { useTkalDataContext } from '../../context/tkalData';
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import AngleDiagram from '../../graphics/images/AngleDiagram.png'
import LengthDiagram from '../../graphics/images/LengthDiagram.png'
import KeevisteNäidis from '../../graphics/images/KeevisteNäidis_ringidega.png'
import '../../styles/tkal-test.css'

const VisualTest = () => {
    const { t } = useTranslation();
    const { tkalData, setTkalData } = useTkalDataContext();
    const [picture1, setPicture1] = React.useState("");
    const [picture2, setPicture2] = React.useState("");

    const saveFile = (e: any, name: string) => {
        var file = e.target.files[0]
        var reader = new FileReader();
        // Closure to capture the file information.
        reader.onload = (function (theFile) {
            return function (e: any) {
                var binaryData = e.target.result;
                //Converting Binary Data to base 64
                var base64String = window.btoa(binaryData);
                //showing file converted to base64
                if (name === "first") {
                    setPicture1(file.name);
                    setTkalData({ ...tkalData, visualTest: { ...tkalData.visualTest, weldingResultImage1: base64String } })
                }
                if (name === "second") {
                    setPicture2(file.name);
                    setTkalData({ ...tkalData, visualTest: { ...tkalData.visualTest, weldingResultImage2: base64String } })
                }
            };
        })(file);
        reader.readAsBinaryString(file);
    }

    const handleDynamicChangeNumber = (event: any) => {
        setTkalData({
            ...tkalData,
            visualTest: {
                ...tkalData.visualTest,
                [event.target.name]: +event.target.value
            }
        })
    };
    const handleDynamicChangeText = (event: any) => {
        setTkalData({
            ...tkalData,
            visualTest: {
                ...tkalData.visualTest,
                [event.target.name]: event.target.value
            }
        })
    };

    const handleDynamicClick = (event: any, value: any) => {
        setTkalData({
            ...tkalData,
            visualTest: {
                ...tkalData.visualTest,
                [event.target.name]: value
            }
        })
    };

    const checkLength = () => {
        const length = tkalData.visualTest.length;
        const crossLength = tkalData.visualTest.crossLength;
        if (length === undefined || length === null || length === 0 || crossLength === undefined || crossLength === null || crossLength === 0) { return alert("Sisestage mõõdud") }

        const arithmeticAverage = Math.abs((length + crossLength) / 2);
        const maxDeviationPercentage = 0.25;
        const deviationD1 = Math.abs((length / arithmeticAverage) - 1) * 100;;
        const deviationD2 = Math.abs((crossLength / arithmeticAverage) - 1) * 100;;

        if (deviationD1 <= maxDeviationPercentage && deviationD2 <= maxDeviationPercentage) {
            Math.round(deviationD1)
            setTkalData({
                ...tkalData,
                visualTest: {
                    ...tkalData.visualTest, crossLengthTestResult: "Ok", crossLengthDifference: parseFloat(deviationD1.toFixed(3))
                }
            })
        }
        if (deviationD1 > maxDeviationPercentage || deviationD2 > maxDeviationPercentage) {
            setTkalData({
                ...tkalData,
                visualTest: {
                    ...tkalData.visualTest, crossLengthTestResult: "Fail", crossLengthDifference: parseFloat(deviationD1.toFixed(3))
                }
            })
        }
    }
    const handleCheckAngle = () => {
        const angle = tkalData.visualTest.angle;

        if (angle === undefined || angle === null || angle === 0) { return alert("Sisestage mõõdud") }
        const angleDifference = parseFloat(Math.abs(90 - angle).toFixed(3))
        if (angle <= 91 && angle >= 89) {
            setTkalData({
                ...tkalData,
                visualTest: {
                    ...tkalData.visualTest, angleTestResult: "Ok", angleDifference: angleDifference
                }
            })
        }
        else {
            setTkalData({
                ...tkalData,
                visualTest: {
                    ...tkalData.visualTest, angleTestResult: "Fail", angleDifference: angleDifference
                }
            })
        }
    }

    function isReadonly() {
        if (tkalData.testStatus === "Finished") return true;
        return false;
    }
    const handleSelectMeasurementType = (measurementType: string) => {
        if (measurementType === "Kraadid") {
            setTkalData({ ...tkalData, visualTest: { ...tkalData.visualTest, measurementType: "Kraadid", length: 0, crossLength: 0, crossLengthDifference: 0, crossLengthTestResult: "" } })
        }
        if (measurementType === "Mõõdud") {
            setTkalData({ ...tkalData, visualTest: { ...tkalData.visualTest, measurementType: "Mõõdud", angle: 0, angleDifference: 0, angleTestResult: "" } })
        }
    }

    return (
        <div className="flexboxAlignedColumn mainPageForm ahutest-container">

            <Label className="mainPageForm-label">Visuaalne test</Label>
            <div className="test-row" style={{ gap: "25px" }}>
                <Button disabled={isReadonly()} className={`flexboxAlignedRow orderData orderDataOrders button-selection-1  ${tkalData.visualTest?.measurementType === "Kraadid" ? "active" : ""} `} style={{ width: "25%", marginTop: "0" }} name="measurementType" onClick={() => handleSelectMeasurementType("Kraadid")}>Kraadid</Button>
                <Button disabled={isReadonly()} className={`flexboxAlignedRow orderData orderDataOrders button-selection-1  ${tkalData.visualTest?.measurementType === "Mõõdud" ? "active" : ""} `} style={{ width: "25%", marginTop: "0" }} name="measurementType" onClick={() => handleSelectMeasurementType("Mõõdud")}>Mõõdud</Button>
            </div>
            <div style={{ marginTop: "1%" }}>
                <div className="test-row" >
                    <Label className="test-row--item" style={{ textAlign: "center" }}>Külje läbipaine</Label>
                </div>
                {tkalData.visualTest?.measurementType === "Mõõdud" ?
                    <>
                        <img alt="LengthDiagram" src={LengthDiagram} style={{ width: "350px", marginBottom: "2%" }}></img>

                        <Label style={{ display: `${tkalData.visualTest?.crossLengthTestResult === undefined ? 'none' : 'block'} ` }}>
                            {tkalData.visualTest?.crossLengthTestResult === "Ok" ? <div style={{ fontSize: "18px" }}>{t("Läbipaine test läbitud")} : {tkalData?.visualTest?.crossLengthDifference}%<DoneIcon style={{ fill: "green" }} /></div> : ""}
                            {tkalData.visualTest?.crossLengthTestResult === "Fail" ? <div style={{ fontSize: "18px" }}>{t("Läbipaine test ebaõnnestunud")} : {tkalData?.visualTest?.crossLengthDifference}%<CloseIcon style={{ fill: '#FF0000' }} />  </div> : ""}
                        </Label>
                        <div className="flexboxAlignedRow" style={{ gap: "25px" }}>
                            <input readOnly={isReadonly()} className="ahutest-form__input --value range" type="number" name="length" placeholder="Pikkus (AD)" value={tkalData.visualTest?.length || ""} onChange={handleDynamicChangeNumber} /> mm
                            <input readOnly={isReadonly()} className="ahutest-form__input --value range" type="number" name="crossLength" placeholder="Ristlõige (A'D')" value={tkalData.visualTest?.crossLength || ""} onChange={handleDynamicChangeNumber} /> mm
                            <Button disabled={isReadonly()} className="ahutest-form__input --value range button-selection-1" style={{ border: "2px solid white", fontSize: "15px", marginTop: "0", height: "50px" }} onClick={checkLength}>Kontrolli</Button>
                        </div>
                    </> :
                    <>
                        <img alt="AngleDiagram" src={AngleDiagram} style={{ width: "350px", marginBottom: "2%" }}></img>
                        <Label style={{ display: `${tkalData.visualTest?.angleTestResult === undefined ? 'none' : 'block'} ` }}>
                            {tkalData.visualTest?.angleTestResult === "Ok" ? <div style={{ fontSize: "18px" }}>{t("Läbipaine test läbitud")} {tkalData.visualTest?.angleDifference}° <DoneIcon style={{ fill: "green" }} /></div> : ""}
                            {tkalData.visualTest?.angleTestResult === "Fail" ? <div style={{ fontSize: "18px" }}>{t("Läbipaine test ebaõnnestunud")} {tkalData.visualTest?.angleDifference || ""}° <CloseIcon style={{ fill: '#FF0000' }} />  </div> : ""}
                        </Label>
                        <div className="flexboxAlignedRow" style={{ gap: "25px" }}>
                            <input readOnly={isReadonly()} className="ahutest-form__input --value range" type="number" name="angle" placeholder="Nurk A°" value={tkalData.visualTest?.angle || ""} onChange={handleDynamicChangeNumber} />
                            <Button disabled={isReadonly()} className="ahutest-form__input --value range button-selection-1" style={{ border: "2px solid white", fontSize: "15px", marginTop: "0", height: "50px" }} onClick={handleCheckAngle}>Kontrolli</Button>
                        </div>
                    </>
                }

                <div className='flexboxAlignedColumn'>
                    <div className="test-row" style={{ gap: "25px" }}>
                        <div className="test-row--item">Keeviste pilt 1</div>
                        <Label style={{ width: "125px", border: "2px solid rgba(255, 255, 255, 0.32)", fontSize: "17px", display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }}>Lisa pilt
                            <input readOnly={isReadonly()} title="Add image" type="file" id="weldingResultImage1" name="weldingResultImage1" onChange={(e: any) => saveFile(e, "first")} />
                        </Label>
                        {picture1 !== "" && <div style={{ width: "50px", fontSize: "15px", border: "none" }}>{picture1}</div>}
                    </div>
                    <div className="test-row" style={{ gap: "25px" }}>
                        <div className="test-row--item">Keeviste pilt 2</div>

                        <Label className="test-row--item" style={{ width: "125px", border: "2px solid rgba(255, 255, 255, 0.32)", fontSize: "17px", display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }}>Lisa pilt
                            <input readOnly={isReadonly()} title="Add image" type="file" id="weldingResultImage2" name="weldingResultImage2" onChange={(e: any) => saveFile(e, "second")} />
                        </Label>
                        {picture2 !== "" && <div style={{ width: "50px", fontSize: "15px", border: "none" }}>{picture2}</div>}
                    </div>
                </div>

                <div className="test-row">
                    <Label className="test-row--item" style={{ textAlign: "center" }}>Vali keeviste tegija</Label>
                </div>
                <div className="flexboxAlignedRow" style={{ gap: "25px" }}>
                    <select disabled={isReadonly()} className="ahutest-form__input select" title={t("Keevitaja")} style={{ outline: "none" }} name="welderName" onChange={handleDynamicChangeText} value={tkalData.visualTest?.welderName || ""}>
                        <option value="" disabled hidden>{t("Vali keevitaja")}</option>
                        <option className="option" value="Kurhinen Ivan">Kurhinen Ivan</option>
                        <option className="option" value="Koroljov Aleksandr">Koroljov Aleksandr</option>
                        <option className="option" value="Kozõrev Dmitri">Kozõrev Dmitri</option>
                        <option className="option" value="Mirgorodski Ivan">Mirgorodski Ivan</option>
                        <option className="option" value="Kazubski Sergei">Kazubski Sergei</option>
                        <option className="option" value="Gussev Vitali">Gussev Vitali</option>
                        <option className="option" value="Sergei Artjunin">Sergei Artjunin</option>
                        <option className="option" value="Oleg Katkov">Oleg Katkov</option>
                    </select>
                </div>

                <div className="test-row" >
                    <Label className="test-row--item" style={{ textAlign: "center" }}>Keeviste pind ühtlane</Label>
                </div>
                <img alt="DiametersDiagram" src={KeevisteNäidis} title="Halvad keevised" style={{ height: "300px" }}></img>
                <div className="test-row" style={{ gap: "25px", marginBottom: "4%" }}>
                    <Button disabled={isReadonly()} className={`flexboxAlignedRow orderData orderDataOrders button-selection-1  ${tkalData.visualTest?.weldingResult === "Ok" ? "active" : ""} `} style={{ width: "25%", marginTop: "0" }} name="weldingResult" onClick={(e: any) => handleDynamicClick(e, "Ok")}>Jah</Button>
                    <Button disabled={isReadonly()} className={`flexboxAlignedRow orderData orderDataOrders button-selection-1  ${tkalData.visualTest?.weldingResult === "Fail" ? "active" : ""} `} style={{ width: "25%", marginTop: "0" }} name="weldingResult" onClick={(e: any) => handleDynamicClick(e, "Fail")} >Ei</Button>
                </div>
            </div>
        </div >
    )
}

export default VisualTest