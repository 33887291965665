import React from 'react'
import { useTranslation } from 'react-i18next'
import { Label } from 'reactstrap'
import { CheckboxDefaultValue, CheckboxOptions } from '../common/checkboxConfig'
import CheckboxGroup from '../common/CheckboxGroup'
import { MeasurementField } from '../common/MeasurementField'

const CoolerTestForm1 = () => {
    const { t } = useTranslation();

    return (
        <form className="ahutest-container" >
            <Label className="ahutest-from--title">{t("Cooler test protocol")}</Label>
            <div className="flexboxAlignedColumn" style={{ padding: "2%" }}>
                <Label className="ahutest-cooler-from__label">{t("Cooler regulator")}</Label>
                <div className="ahutest-form__label--grid2">
                    <Label className="grid-item-label" >{t("Settings done")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="settings-coolerReg"
                    />
                </div>
                <MeasurementField properyName='settings-coolerReg' showValue={false} />

                <Label className="ahutest-cooler-from__label">{t("Heating control")}</Label>
                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Settings done")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="settings-heatingCtrl"
                    />

                </div>
                <MeasurementField properyName='settings-heatingCtrl' showValue={false} />

                <div className="ahutest-form__label--grid2" style={{ gap: "10px" }}>
                    <Label className="grid-item-label" >{t("The superheat controller alarm stops the compressor")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="superHeat-heatingCtrl"
                    />
                </div>
                <MeasurementField properyName='superHeat-heatingCtrl' showValue={false} />

                <Label className="ahutest-cooler-from__label">{t("Frequency converter")}</Label>
                <div className="ahutest-form__label--grid2" style={{ gap: "10px" }}>
                    <Label className="grid-item-label" >{t("Settings done")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="settings-frequency"
                    />
                </div>
                <MeasurementField properyName='settings-frequency' showValue={false} />


                <div className="ahutest-form__label--grid2" style={{ gap: "10px" }}>
                    <Label className="grid-item-label" >{t("Visit permit")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="permit-frequency"
                    />
                </div>
                <MeasurementField properyName='permit-frequency' showValue={false} />


                <div className="ahutest-form__label--grid2" style={{ gap: "10px" }}>
                    <Label className="grid-item-label" >{t("Adjustment")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="adjustment-frequency"
                    />
                </div>
                <MeasurementField properyName='adjustment-frequency' showValue={false} />


                <div className="ahutest-form__label--grid2" style={{ gap: "10px" }}>
                    <Label className="grid-item-label" >{t("Visit status information")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="status-frequency"
                    />
                </div>
                <MeasurementField properyName='status-frequency' showValue={false} />

                <div className="ahutest-form__label--grid2" style={{ gap: "10px" }}>
                    <Label className="grid-item-label" >{t("Alarm")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="alarm-frequency"
                    />
                </div>
                <MeasurementField properyName='alarm-frequency' showValue={false} />

                <Label className="ahutest-cooler-from__label">{t("External connections")}</Label>
                <div className="ahutest-form__label--grid2" style={{ gap: "10px" }}>
                    <Label className="grid-item-label" >{t("Visit permit")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="permit-external"
                    />
                </div>
                <MeasurementField properyName='permit-external' showValue={false} />

                <div className="ahutest-form__label--grid2" style={{ gap: "10px" }}>
                    <Label className="grid-item-label" >{t("Adjustment")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="adjustment-external"
                    />
                </div>
                <MeasurementField properyName='adjustment-external' showValue={false} />

                <div className="ahutest-form__label--grid2" style={{ gap: "10px" }}>
                    <Label className="grid-item-label" >{t("Visit status information")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="status-external"
                    />
                </div>
                <MeasurementField properyName='status-external' showValue={false} />

                <div className="ahutest-form__label--grid2" style={{ gap: "10px" }}>
                    <Label className="grid-item-label" >{t("Alarm")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="alarm-external"
                    />
                </div>
                <MeasurementField properyName='alarm-external' showValue={false} />

            </div>
        </form>
    )
}

export default CoolerTestForm1