import TestiQLogo from '../graphics/TESTiQ_logo.svg'
import '../styles/header.css'
import { useTranslation } from "react-i18next";
import { LangMenuSelect } from "./LangMenu";
import { useCustomer } from "../context/user";
import SwedenIcon from '../graphics/sweden-flag-icon.svg'

const languageOptions = [
    { value: "en", label: "English" },
    { value: "et", label: "Eesti keel" },
    { value: "fi", label: "Suomi" },
    { value: "sv", label: "Svenska" }
];
const Header = () => {
    const { t, i18n } = useTranslation();
    const { logged, logout, getCurrentCountry } = useCustomer();
    const changeLanguage = (code: any) => {
        i18n.changeLanguage(code.value);
    };

    const ShowIcon = () => {
        return (
            <>
                {(getCurrentCountry() === "sweden") &&
                    <img src={SwedenIcon} alt="SwedenIcon" style={{ width: "30px" }} />}
            </>
        )
    }

    return (
        <div className="header-container" >
            <div className="header">
                <div className="header-logo">
                    <img
                        src={TestiQLogo}
                        alt="etsNordLogo"
                        className="header-logo-img"
                    />
                </div>
            </div>
            <div className="subheader">

                {logged && (
                    <div className="subheader-logout-container">
                        <div className="subheader-logout"
                            onClick={logout}
                        >
                            {t("Logout")}
                        </div>
                        <ShowIcon />
                    </div>
                )}
                <div style={{ display: "flex" }}>
                    <LangMenuSelect
                        value={{ value: i18n.language, label: languageOptions.find(x => x.value === i18n.language)?.label ? languageOptions.find(x => x.value === i18n.language)?.label : "English" }}
                        isSearchable={false}
                        options={languageOptions.map((x) => {
                            return {
                                label: x.label,
                                value: x.value,
                            };
                        })}
                        onChange={(e: any) => changeLanguage(e)}
                    />
                    {!logged && <ShowIcon />}
                </div>
            </div>
        </div>
    );
};

export default Header