import React from 'react'
import { useTranslation } from 'react-i18next';
import { Button, Label } from 'reactstrap';
import { useTkalDataContext } from '../../../context/tkalData';
import { LeakTest, VisualTest } from '../../../types/kalorifeer/Kalorifeer';

const TkalTestCategory = (props: { testName: { label: string, val: number }[] }) => {
    const { t } = useTranslation();
    const { tkalData, setTkalData, savedTests } = useTkalDataContext();

    if (props.testName[0].label === (undefined || "")) return <></>;

    const handleTestType = (value: any) => {
        if (value === 1) {
            setTkalData({ ...tkalData, testType: value, leakTest: {} as LeakTest, visualTest: { measurementType: "Kraadid" } as VisualTest, id: 0, testStatus: "" })
        }
        else {
            setTkalData({ ...tkalData, testType: value, leakTest: {} as LeakTest, visualTest: {} as VisualTest, id: 0, testStatus: "" })
        }
    }
    const hasFinishedTest = () => {
        console.log(savedTests)
        if (savedTests?.length === 0) return true;
        return savedTests?.some((object) => object.testStatus === "finished");
    }

    return (
        <React.Fragment>
            {props.testName[0].label === "unknown" ?
                <div className="message-testName-unkown" >
                    No suitable test found
                </div>
                :
                (
                    <>
                        <Label className="mainPageForm-label" style={{ marginTop: "5%" }}>
                            {t("SelectTest")}
                        </Label>

                        {/*                         {props.testName.map((value, idx) =>
                            <Button key={idx} name="testType" className={`flexboxAlignedRow orderDataOrders button-selection-1 ${tkalData.testType === value.val ? "active" : ""}`} style={{ minWidth: "250px", width: "57%" }} onClick={() => handleTestType(value)}>
                                {t(`${value.label}`)}
                            </Button>
                        )} */}
                        <Button name="testType" className={`flexboxAlignedRow orderDataOrders button-selection-1 disable ${tkalData.testType === 2 ? "active" : ""}`} style={{ minWidth: "250px", width: "57%" }} onClick={() => handleTestType(2)}>Lekke test</Button>

                        <Button name="testType" className={`flexboxAlignedRow orderDataOrders button-selection-1 disable ${tkalData.testType === 1 ? "active" : ""}`} style={{ minWidth: "250px", width: "57%" }} onClick={() => handleTestType(1)} disabled={hasFinishedTest()}>Visuaalne test</Button>
                    </>
                )
            }
        </React.Fragment>
    )
}

export default TkalTestCategory