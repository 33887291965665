/* eslint-disable import/no-anonymous-default-export */
import * as React from "react";
import { Container } from "reactstrap";
import Header from "./Header";
import '../styles/container.css'

export default (props: { children?: React.ReactNode }) => (
    <React.Fragment>
        <Container className="container">
            <Header />
            <div style={{ paddingBottom: 100 }}>{props.children}</div>
        </Container>
    </React.Fragment>
);


