

import { useState } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import Modal from '@mui/material/Modal';
import { Box, IconButton, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import '../../../styles/ahu-test.css'
const useStyles = makeStyles({
    root: {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#000000a8',
        border: '2px solid #000',
        width: "600px",
        whiteSpace: "pre-line",
    },
    title: {
        marginTop: "10px",
        fontSize: "24px",
        fontFamily: 'Banschrift'
    },
    bodyText: {

        padding: '30px',
        color: 'white',
        textAlign: 'left',
        fontFamily: 'Banschrift',
        fontSize: '18px'
    }
})

type Props = { title: string, bodyText: string };

const InfoModal = ({ title, bodyText }: Props) => {
    const [isOpen, setIsOpen] = useState(false);
    const styles = useStyles();
    const handleOpen = () => {
        setIsOpen(true);
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    return (
        <>
            <div className="grid-item-info-button" onClick={handleOpen}>
                <IconButton><InfoIcon /></IconButton>
            </div>

            <Modal
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className={styles.root}>
                    <Typography className={styles.title} id="modal-modal-title" variant="h6" component="h2">
                        {title}
                    </Typography>
                    <Typography className={styles.bodyText} id="modal-modal-description">
                        {bodyText}
                    </Typography>
                </Box>
            </Modal>
        </>
    );
}
export default InfoModal;
