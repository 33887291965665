import React from 'react'
import { Label } from 'reactstrap';
import { useTranslation } from "react-i18next";
import { CheckboxOptions, CheckboxDefaultValue } from '../common/checkboxConfig';
import CheckboxGroup from '../common/CheckboxGroup';
import { MeasurementField } from '../common/MeasurementField';

const OtherChecksForm = () => {
    const { t } = useTranslation();
    return (
        <form className="ahutest-container" >
            <Label className="ahutest-from--title">{t("Other checks")}</Label>

            <div className="flexboxAlignedColumn" style={{ padding: "2%" }}>
                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Emergency stop switch")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="emergencyStopSwitch-other"
                    />
                </div>
                <MeasurementField properyName="emergencyStopSwitch-other" showValue={false} />

                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Overtime timer")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="overtimeTimer-other"
                    />
                </div>
                <MeasurementField properyName="overtimeTimer-other" showValue={false} />

                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Fire alarm")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="fireAlarmCheck-other"
                    />
                </div>
                <MeasurementField properyName="fireAlarmCheck-other" showValue={false} />

                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Operation of operation/maintenance switches")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="maintenanceSwitchCheck-other"
                    />
                </div>
                <MeasurementField properyName="maintenanceSwitchCheck-other" showValue={false} />

                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Other operations/equipment")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="otherEquip-other"
                    />
                </div>
                <MeasurementField properyName="otherEquip-other" showValue={false} />
            </div>
        </form>
    )
}

export default OtherChecksForm