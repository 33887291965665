import { Button, Label } from 'reactstrap';
import '../../../../styles/buttons.css'
import '../../../../styles/input.css'
import { useDataContext } from '../../../../context/ductTestData';
import { useTranslation } from 'react-i18next';
import ReturnProgressBar from '../../../common/ReturnProgressBar';
import RigidityTestForm from '../ntoTest/RigidityTestForm';
import { GetDuctRigidty } from '../../../queries/testQueries';
import { AxiosResponse } from 'axios';
import { DuctRigidity } from '../../../../types/DuctTestType';
import { useState } from 'react';

const SmokeTestForm = () => {
    const { setTestData, testData, handleDynamicClick, itemData } = useDataContext();
    const { t, i18n } = useTranslation();
    const [ductRigidityMeasurements, setDuctRigidityMeasurements] = useState<DuctRigidity>()
    const saveFile = (e: any) => {
        var file = e.target.files[0]
        var smokeTestName = ((file.name.split("-")[1] + "-" + file.name.split("-")[2]).split(".")[0]);
        var reader = new FileReader();
        // Closure to capture the file information.
        reader.onload = (function (theFile) {
            return function (e: any) {
                var binaryData = e.target.result;
                //Converting Binary Data to base 64
                var base64String = window.btoa(binaryData);
                //showing file converted to base64
                setTestData({ ...testData, fileForm: base64String, fileName: file.name, smokeTestFileName: smokeTestName });
            };
        })(file);
        reader.readAsBinaryString(file);
    }

    const handleOpenRigidityTets = () => {
        if (testData.testFileType === "rigidityTest") {
            setTestData({ ...testData, testFileType: "" })
            return;
        }
        if (itemData.diameter === undefined) return alert("Ei leidnut õiget läbimõõtu")
        GetDuctRigidty(+itemData.diameter).then((res: AxiosResponse<DuctRigidity>) => {
            setDuctRigidityMeasurements(res.data)
            setTestData({ ...testData, rigidityTestWeight: res.data.staticWeight, testFileType: "rigidityTest" })
            return;
        })
    }

    return (
        <form className="flexboxAlignedColumn mainPageForm">
            <ReturnProgressBar level="3" lang={i18n.language} />
            <div className="flexboxAlignedColumn" >
                <Label className="mainPageForm-label2" style={{ marginTop: "5%", marginBottom: "5%" }}>{t("SelectLeakTest")}</Label>
                <div className="orderData" style={{ flexDirection: "row", display: "flex", gap: "20px" }}>
                    <Button name="testFileType" value="smokeLeakTest" className={`smoketest-button-selection ${testData.testFileType === "smokeLeakTest" ? "-active" : ""}`} onClick={(event: any) => handleDynamicClick(event, event.target.value)}>
                        {t("smokeLeakTest")}
                    </Button>
                    <Button name="testFileType" value="vacuumLeakTest" className={`smoketest-button-selection ${testData.testFileType === "vacuumLeakTest" ? "-active" : ""}`} onClick={(event: any) => handleDynamicClick(event, event.target.value)} >
                        {t("vacuumLeakTest")}
                    </Button>
                </div>
                {(testData.testFileType === ("vacuumLeakTest") || testData.testFileType === ("smokeLeakTest")) && (
                    <div className="smokeTest-FileUploadArea" >
                        <Label className="flexboxAlignedRow custom-file-upload" style={{ display: "flex", justifyContent: "center", alignContent: "center", width: "60%", padding: "9%" }}>{t("AttachPDF")}
                            <input title="attachment" type="file" id="smokeTestFile" name="smokeTestFile" onChange={saveFile} />
                        </Label>
                        <Label style={{ marginTop: "5%" }}>{testData.fileName}</Label>
                    </div>
                )}

                <div className="orderData" style={{ flexDirection: "row", display: "flex", gap: "20px", justifyContent: "center" }}>
                    <Button name="rigidityTest" className={`smoketest-button-selection ${(testData.testFileType === "rigidityTest") ? "-active" : ""}`} onClick={handleOpenRigidityTets} >
                        {t("rigidityTest")}
                    </Button>
                </div>
                {
                    testData.testFileType === "rigidityTest" &&
                    <RigidityTestForm ductRigidityMeasurements={ductRigidityMeasurements} />
                }
            </div>
        </form>
    )
}
export default SmokeTestForm