import React from 'react'
import { Label } from 'reactstrap'
import { useAhuDataContext } from '../../../../context/ahuData';
import { useTranslation } from "react-i18next";
import { CheckboxOptions, CheckboxDefaultValue } from '../common/checkboxConfig';
import CheckboxGroup from '../common/CheckboxGroup';
import { MeasurementField } from '../common/MeasurementField';
import IsDisabled from '../common/isDisabled';

const InletFanForm = () => {
    const { electricalMeasurementData, handleAhuTestMeasurementsValue } = useAhuDataContext();
    const { t } = useTranslation();
    return (
        <form className="ahutest-container" >
            <Label className="ahutest-from--title">{t("Inlet Fan")}</Label>

            <div className="flexboxAlignedColumn" style={{ padding: "2%" }}>
                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Visual inspection")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="visualInspection-inFan"
                    />
                </div>
                <MeasurementField properyName="visualInspection-inFan" showValue={false} />

                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Fan manufacturer")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="manufacturer-inFan"
                    />
                </div>
                {IsDisabled("manufacturer-inFan") &&
                    <div className="flexboxAlignedRow" style={{ gap: "25px" }}>
                        <select className="ahutest-form__input select" title={t("Fan manufacturer")} name="manufacturer-inFan" onChange={e => handleAhuTestMeasurementsValue(e, "value")} value={electricalMeasurementData.measurements["manufacturer-inFan"]?.value ?? ""} placeholder={t("Value")} >
                            <option value="" disabled hidden>{t("Choose manufacturer")}</option>
                            <option className="option" value="Nicotra">Nicotra</option>
                            <option className="option" value="EBM Pabst">EBM Pabst</option>
                            <option className="option" value="ZIEHL">ZIEHL</option>
                        </select>
                    </div>}

                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("K-value")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="kValue-inFan"
                    />
                </div>
                <MeasurementField properyName="kValue-inFan" />

                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Rated power (kW)")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="ratedPower-inFan"
                    />
                </div>
                <MeasurementField properyName="ratedPower-inFan" unit="kW" gap="10px" />

                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Rated current (A)")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="ratedCurrent-inFan"
                    />
                </div>
                <MeasurementField properyName="ratedCurrent-inFan" unit="A" gap="10px" />

                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Measured current (A) max. at speed")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="measuredCurrent-inFan"
                    />
                </div>
                <MeasurementField properyName="measuredCurrent-inFan" unit="A" gap="10px" />

                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Frequency converter programmed")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="frequencyConverter-inFan"
                    />
                </div>
                <MeasurementField properyName="frequencyConverter-inFan" showValue={false} />

                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Fan controls tested")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="fanControler-inFan"
                    />
                </div>
                <MeasurementField properyName="fanControler-inFan" showValue={false} />

                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Differential pressure transmitter / air volume meter")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="pressureTransmitter-inFan"
                    />
                </div>
                <MeasurementField properyName="pressureTransmitter-inFan" inputProps={{ className: "ahutest-form__input --value smaller" }} unit="Pa" gap="10px" placeholderKey="Pressure range" />

                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Channel pressure transmitter")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="channelPressureTransmitter-inFan"
                    />
                </div>
                <MeasurementField inputProps={{ className: "ahutest-form__input --value smaller" }} properyName='channelPressureTransmitter-inFan' gap="10px" unit="Pa" placeholderKey="Pressure range" />

                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Measuring hoses")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="measuringHoses-inFan"
                    />
                </div>
                <MeasurementField properyName="measuringHoses-inFan" showValue={false} />

            </div>
        </form>
    )
}

export default InletFanForm