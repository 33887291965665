import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Label } from 'reactstrap'
import { useTkalDataContext } from '../../../../context/tkalData';

type Props = {
    handleTestType: (value: any) => void
}
const OzonatorTestCategory = ({ handleTestType }: Props) => {
    const { t } = useTranslation();
    const { ozItemData, ozonatorTest } = useTkalDataContext()
    return (
        <React.Fragment>

            <Label className="mainPageForm-label" style={{ marginTop: "5%" }}>
                {t("SelectTest")}
            </Label>
            {ozItemData.isOzoneGenerator === false &&
                <Button name="testType" className={`flexboxAlignedRow orderDataOrders button-selection-1 ${ozonatorTest.testType === 1 ? "active" : ""}`} style={{ minWidth: "250px", width: "57%" }} onClick={() => handleTestType(1)}>
                    {t(`${"Test raport"}`)}
                </Button>
            }
            {ozItemData.isOzoneGenerator &&
                <Button name="testType" className={`flexboxAlignedRow orderDataOrders button-selection-1 ${ozonatorTest.testType === 2 ? "active" : ""}`} style={{ minWidth: "250px", width: "57%" }} onClick={() => handleTestType(2)}>
                    {t(`${"Osoonigeneraatori test"}`)}
                </Button>
            }

        </React.Fragment>
    )
}
export default OzonatorTestCategory