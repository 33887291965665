import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useTkalDataContext } from "../../context/tkalData";
import { Button } from "reactstrap";
import { IconButton } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { SepuCreateTestDto, SepuTest, SepuTestType, } from "../../types/sepu/SepuTest";
import SepuVisualTest from "../../components/configurationForms/sepu/SepuVisualTest";
import SepuHatchTest from "../../components/configurationForms/sepu/SepuHatchTest";
import SelectSepuTest from "../../components/configurationForms/sepu/SelectSepuTest";
import '../../styles/sepu-test.css'
import { CreateSepuTest, FinishSepuTest, SaveSepuTest } from "../../components/queries/testQueries";
import { AxiosResponse } from "axios";
import { PurchaseOrderCommon } from "../../types/directo/PurchaseOrderCommon";

const SepuTestPage = () => {
  const { t } = useTranslation();
  const {
    setIsLoading,
    isLoading,
    sepuTest,
    setTestType,
    setItemNumber,
    setSavedSepuTests,
    setPurchaseOrderData,

    setSepuTest,

  } = useTkalDataContext();
  const [step, setStep] = useState(1);
  let testRaportSteps = 3;

  const handleNextButton = () => {
    if (step >= 2) {
      handleSaveTestData();
    }
    if (sepuTest.testType === null) {
      alert("Select test type");
      return;
    }
    if (step === 1 && sepuTest.id === (null || undefined || 0)) {
      handleCreateTest();
      return;
    }
    else {
      setStep((step) => step + 1);
      return;
    }
  };
  const handleBackButton = () => {
    if (step >= 2) {
      setStep((step) => step - 1);
    }
  };

  const handleCreateTest = () => {
    setIsLoading(true)

    if (sepuTest.testType !== SepuTestType.EMPTY) {
      var createDto: SepuCreateTestDto = {
        purchaseOrder: sepuTest.purchaseOrderNumber,
        productArticleNumber: sepuTest.productArticleNumber, productName: sepuTest.productName, productUniqueId: sepuTest.productUniqueId, testType: sepuTest.testType
      }
      CreateSepuTest(createDto).then((res: AxiosResponse<SepuTest>) => {
        setSepuTest(res.data)
        setStep(step => step + 1)
      }).catch((err) => {
        console.log(err)
        alert(err.response.data)
      }).finally(() => {
        setIsLoading(false)
      })
    }
  };

  const chooseFirstStepButtonKey = () => {
    if (step === 1) {
      if (sepuTest.id && sepuTest.testStatus !== "") return t("Open");
      if (sepuTest.id && sepuTest.testStatus === "") return t("Next");
      return t("Next");
    }
    if (step > 1) return t("Next");
  };

  const validateLastStep = () => {
    if (step === testRaportSteps) return true;
    return false;
  }
  const validateNextButton = () => {
    if (step !== testRaportSteps && sepuTest.testType === SepuTestType.TESTRAPORT) return true;
    return false;
  }

  const handleSaveTestData = async () => {
    try {
      setIsLoading(true)
      await SaveSepuTest(sepuTest);
    }
    catch (err: any) {
      console.log(err.response.data);
      setIsLoading(false);
      return;
    }
    finally {
      setIsLoading(false);
    }
  };
  const handleSendTest = async () => {
    setIsLoading(true)
    // Reset the form data and go back to the first step
    try {

      await SaveSepuTest(sepuTest)
      await FinishSepuTest(sepuTest)
    } catch (err: any) {
      console.log(err.response.data);

    } finally {
      setSepuTest({ measurements: {} } as SepuTest);
      setPurchaseOrderData({} as PurchaseOrderCommon)
      setSavedSepuTests([])
      setTestType(0)
      setItemNumber("")
      setStep(1);
      alert("Success")
      setIsLoading(false);
    }
  }

  return (
    <div className="mainPageLayout">
      {(() => {
        if (step === 1) {
          return <SelectSepuTest />;
        }
        switch (sepuTest.testType) {
          case SepuTestType.TESTRAPORT:
            switch (step) {
              case 2:
                return <SepuVisualTest />;
              case 3:
                return <SepuHatchTest />;
            }
            break;
        }
      })()}

      <div className="navigation-buttons-container">
        {step >= 2 && (
          <Button className="button-primary" onClick={handleBackButton}>
            {t("Back")}
          </Button>
        )}

        {step > 1 && (
          <>
            <IconButton onClick={() => handleSaveTestData()}>
              <SaveIcon />
            </IconButton>
          </>
        )}
        {
          validateNextButton() && <Button
            className="button-primary"
            onClick={handleNextButton}
          >
            {chooseFirstStepButtonKey()}

          </Button>
        }
        {
          validateLastStep() && <Button
            className="button-primary"
            style={{ width: "170px", backgroundColor: "white", color: "red" }}
            disabled={isLoading || sepuTest.testStatus === "Finished"}
            onClick={handleSendTest}
          >
            {t("ConfirmAndSend")}
          </Button>
        }
      </div>
    </div>
  );
};

export default SepuTestPage;
