import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Label } from 'reactstrap';
import { useTkalDataContext } from '../../context/tkalData';
import { getPrinters } from '../queries/adminQueries';

type Props = {
    defaultPrinter: string;
}
const SelectPrinter = ({ defaultPrinter }: Props) => {
    const [printers, setPrinters] = useState<string[]>([]);
    const { t } = useTranslation();
    const { setDefaultPrinter } = useTkalDataContext();
    const [selectedPrinter, setSelectedPrinter] = useState<string>(defaultPrinter);

    const handleSelectPrinter = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedPrinter(e.target.value);
        setDefaultPrinter(e.target.value)
    }

    useEffect(() => {
        getPrinters().then((res) => {
            setPrinters(res.data);
        }).catch((err) => { console.log(err) });
    }, [])

    return (
        <div className='flexboxAlignedRow' style={{ marginTop: "5%", gap: "10px", justifyContent: "space-between", width: "500px" }}>
            <Label style={{ fontSize: "23px", width: "50%" }}>{t("Valige printer")}</Label>
            <select title="Printers" className="ahutest-form__input select"
                value={selectedPrinter ?? ""} onChange={(e: any) => handleSelectPrinter(e)}>
                <option value="" disabled hidden></option>
                {printers.map((printer, idx) => (
                    <option key={idx} style={{ color: "black" }} value={printer}>{printer}</option>
                ))}
            </select>
        </div>
    )
}

export default SelectPrinter