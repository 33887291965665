import { Checkbox } from '@mui/material';
import React from 'react';
import { useAhuDataContext } from '../../../../context/ahuData';
import '../../../../styles/ahu-test.css'

interface CheckboxOption {
    value: string;
    icon: JSX.Element;
    checkedIcon: JSX.Element;
}

interface CheckboxGroupProps {
    options: CheckboxOption[];
    defaultValue: string;
    measurementKey: string;
}

const CheckboxGroup: React.FC<CheckboxGroupProps> = ({ options, defaultValue, measurementKey }) => {
    const { setElectricalMeasurementData, electricalMeasurementData } = useAhuDataContext();

    const handleChange = (value: string, key: string) => {
        setElectricalMeasurementData({
            ...electricalMeasurementData,
            "measurements": { ...electricalMeasurementData.measurements, [key]: { ...electricalMeasurementData.measurements[key], "statusNew": value } }
        })
    }

    return (
        <>
            {options.map((option, idx) => (
                <Checkbox
                    className={`grid-item-icon${idx + 1}`}
                    key={option.value}
                    icon={option.icon}
                    checkedIcon={option.checkedIcon}
                    checked={electricalMeasurementData.measurements[measurementKey]?.statusNew === option.value}
                    onChange={() => handleChange(option.value, measurementKey)}
                />
            ))}
        </>
    );
}

export default CheckboxGroup;
