import React from 'react'
import { Label } from 'reactstrap';
import { useTranslation } from "react-i18next";
import MeasurementField from '../../MeasurementField';
import { CheckboxOptions, CheckboxDefaultValue } from '../../../ahu/common/checkboxConfig';
import CheckboxGroup from '../../CheckboxGroup';

const MeasurementsForm = () => {

    const { t } = useTranslation();
    return (
        <form className="ahutest-container" >
            <Label className="ahutest-from--title">{t("Measurements (from measurents menu)")}</Label>
            <div className="flexboxAlignedColumn" style={{ padding: "2%" }}>

                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Heat recovery input temperature")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="heatInput-tempSens"
                    />
                </div>
                <MeasurementField properyName="heatInput-tempSens" showValue={false} />

                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Heat recovery out temperature")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="heatOut-tempSens"
                    />
                </div>
                <MeasurementField properyName="heatOut-tempSens" showValue={false} />

                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Supply temperature")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="supply-tempSens"
                    />
                </div>
                <MeasurementField properyName="supply-tempSens" showValue={false} />

                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Extract temperature")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="extract-tempSens"
                    />
                </div>
                <MeasurementField properyName="extract-tempSens" showValue={false} />

                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Exhaust temperature")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="exhaustAir-tempSens"
                    />
                </div>
                <MeasurementField properyName="exhaustAir-tempSens" showValue={false} />

                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Return water temperature")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="water-tempSens"
                    />
                </div>
                <MeasurementField properyName="water-tempSens" showValue={false} />

                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Relative humidty of extract air")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="humidity-tempSens"
                    />
                </div>
                <MeasurementField properyName="humidity-tempSens" showValue={false} />

                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Heat rec. pressure drop")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="heatRec-tempSens"
                    />
                </div>
                <MeasurementField properyName="heatRec-tempSens" showValue={false} />

                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Supply filter pressure drop")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="supplyFilter-tempSens"
                    />
                </div>
                <MeasurementField properyName="supplyFilter-tempSens" showValue={false} />
            </div>
        </form>
    )
}

export default MeasurementsForm