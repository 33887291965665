import React from 'react'
import { Label } from 'reactstrap';
import { useTranslation } from "react-i18next";
import MeasurementField from '../MeasurementField';
import { CheckboxOptions, CheckboxDefaultValue } from '../../ahu/common/checkboxConfig';
import CheckboxGroup from '../CheckboxGroup';
import InfoModal from '../InfoModal';
import { ltoDamperText, ltoPressureHoseText } from '../ModalTexts';

const LtoForm = () => {
    const { t } = useTranslation();
    return (
        <form className="ahutest-container" >
            <Label className="ahutest-from--title">{t("LTO")}</Label>

            <div className="flexboxAlignedColumn" style={{ padding: "2%" }}>

                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Heat exchanger installed correctly")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="heatEx-lto"
                    />
                </div>
                <MeasurementField showValue={false} properyName='heatEx-lto' />

                <div className="ahutest-form__label-info-button--grid" >
                    <Label className="grid-item-label" >{t("Pressure hose installed correctly to pressure transmitter")}</Label>
                    <InfoModal title={t("Juhend")} bodyText={ltoPressureHoseText} />
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="pressureHose-lto"
                    />
                </div>
                <MeasurementField showValue={false} properyName='pressureHose-lto' />

                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Heat exchanger rubber seals are installed")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="heatExRubber-lto"
                    />
                </div>
                <MeasurementField showValue={false} properyName='heatExRubber-lto' />

                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Heat exchanger lamella inspection for damage")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="heatExLamella-lto"
                    />
                </div>
                <MeasurementField showValue={false} properyName='heatExLamella-lto' />

                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Heat exchanger installed with pulling straps")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="heatExStraps-lto"
                    />
                </div>
                <MeasurementField showValue={false} properyName='heatExStraps-lto' />

                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Section defrost damper and actuator are correctly installed")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="deFrost-lto"
                    />
                </div>
                <MeasurementField showValue={false} properyName='deFrost-lto' />

                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Damper shafts are closed/locked")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="damperShafts-lto"
                    />
                </div>
                <MeasurementField showValue={false} properyName='damperShafts-lto' />

                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Check damper rubber seals")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="damperSeals-lto"
                    />
                </div>
                <MeasurementField showValue={false} properyName='damperSeals-lto' />

                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Rotation guard installed on dampers")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="guard-lto"
                    />
                </div>
                <MeasurementField showValue={false} properyName='guard-lto' />

                <div className="ahutest-form__label-info-button--grid" >
                    <Label className="grid-item-label" >{t("Bypass damper and actuator is correctly installed")}</Label>
                    <InfoModal title={t("Juhend")} bodyText={ltoDamperText} />
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="bypassDamper-lto"
                    />
                </div>
                <MeasurementField showValue={false} properyName='bypassDamper-lto' />



            </div>
        </form>
    )
}

export default LtoForm


