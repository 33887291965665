import { IconButton } from '@mui/material';
import { AxiosResponse } from 'axios';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { Label, Button } from 'reactstrap';
import { useTkalDataContext } from '../../../context/tkalData';
import { deleteOzonatorTest, getOzonatorPurchaseOrder, getOzonatorTest, getSavedOzonatorTests } from '../../queries/testQueries';
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import ClearIcon from '@mui/icons-material/Clear';
import { OzonatorPurchaseOrder, OzonatorPurchaseOrderRow } from '../../../types/directo/OzonatorPurchaseOrder';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { handleOpenWebsite } from '../CommonFunctions';
import OzonatorTestCategory from './common/OzonatorTestCategory';
import PrinterModal from '../../common/PrinterModal';
import { printProductCode } from '../../queries/adminQueries';

const SelectOzonator = () => {
    const { t } = useTranslation();
    const { setOzItemData, setItemNumber, itemNumber, setIsLoading, orderNumber, setOrderNumber, isLoading, ozonatorTest, setOzonatorTest, setTestType, ozOrderData, setOzOrderData, savedOzTests, setSavedOzTests } = useTkalDataContext();

    const handleGetOrderData = async (e: any) => {
        e.preventDefault();
        setIsLoading(true);
        if (!orderNumber) return alert(t("SalesorderNumberAlert"));
        getOzonatorPurchaseOrder(orderNumber, "ozonator").then((res: AxiosResponse<OzonatorPurchaseOrder>) => {
            setOzOrderData(res.data);
            setOzonatorTest({ ...ozonatorTest, purchaseOrderNumber: orderNumber })
        }).catch((err) => {
            if (err.response.status === 401 || err.response.status === 403) return alert("You dont have enough permissions to access this data")
            else {
                alert("Did not find any items");
                console.log(err.response.data)
            }
        }).finally(() => {
            setIsLoading(false);
        })
    }

    const handleItemRequest = async (item: OzonatorPurchaseOrderRow) => {
        setItemNumber(item.itemCode);
        setOzonatorTest({ ...ozonatorTest, productArticleNumber: item.itemCode, productUniqueId: item.id, productName: item.description, testType: 0 })
        setTestType(0);
        setOzItemData(item)
        if (!orderNumber) return null;
        getSavedOzonatorTests(orderNumber, item.id).then((res) => {
            setSavedOzTests(res.data)
        }).catch(() => { }).finally(() => { })
        window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: 'smooth', // This makes the scrolling smooth
        });
    }

    const handleTestType = (value: any) => {
        setOzonatorTest(prev => ({ ...prev, testType: value, id: 0, measurements: {} }))
        setTestType(value)
    }

    function returnDate(dateString: string) {
        if (dateString === null) return;
        const year = dateString.substring(0, 4);
        const month = dateString.substring(5, 7);
        const day = dateString.substring(8, 10);
        return `${day}.${month}.${year}`
    }

    const handleLoadTestData = (id: number) => {
        setIsLoading(true)
        if (ozonatorTest.id === null) return;
        getOzonatorTest(id).then((res) => {
            setOzonatorTest(res.data)
        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const handleRemoveTest = (e: any, id: number) => {
        deleteOzonatorTest(id).then(() => {
            setSavedOzTests(prev => prev!.filter((item, _) => item.id !== id));
        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            e.stopPropagation()
            setIsLoading(false)
        })
    }

    const isPrintingAllowed = (itemData: OzonatorPurchaseOrderRow) => {
        if (itemData?.testStatus === "Finished") return true;
        return false;
    }

    return (
        <div className="mainPageLayout">
            <div className="flexboxAlignedColumn mainPageForm" style={{ marginTop: "3%" }}>
                <form className="flexboxAlignedColumn mainPageForm" style={{ marginTop: "10%" }} onSubmit={e => handleGetOrderData(e ?? `${alert(t("ItemNotSelectedAlert"))}`)}>

                    <div className="flexboxAlignedColumn" style={{ padding: "2%" }}>
                        <Label className="mainPageForm-label">{t("InsertOrder")}</Label>
                        <div className="flexboxAlignedRow" style={{ gap: "5%" }}>
                            <input className="input-orderNr" type="number" onChange={e => setOrderNumber(e.target.value)} value={orderNumber ?? ""} placeholder={t("InsertNumber")} />
                            <Button className="button-primary" style={{ width: "94px" }} type="submit">{t("Search")}</Button>
                        </div>
                    </div>

                    {ozOrderData.items && (
                        <React.Fragment >
                            <Label className="mainPageForm-label" style={{ marginTop: "2%" }}>{t("Vali tooted")}</Label>
                            <div className="orderData">
                                {
                                    Object.values(ozOrderData.items).map((x, idx) => {
                                        return <div className='select-order-form__grid' key={idx}>
                                            <div>
                                                <PrinterModal item={x} isPrintingAllowed={isPrintingAllowed} printFunction={printProductCode} printFunctionArgs={[x.serialNumber]} defaultPrinter="OZ_Printer" printerLabeL={x.isOzoneGenerator ? "OzGeneratorSerialNr" : "OzProductCodeLabel"} />
                                            </div>
                                            <div className={`select-order-grid__label${(x.id === ozonatorTest.productUniqueId) ? " active" : ""}`}>
                                                <div className="itemData" onClick={() => handleItemRequest(x)}>{idx + 1}-{x.itemCode} {x.description} {x.serialNumber !== null && `SN:${x.serialNumber}`}</div>
                                            </div>
                                            <div className="select-order-grid__icon2"  >
                                                {x.testStatus === "Finished" ?
                                                    <IconButton title="Testi staatus">
                                                        <DoneIcon style={{ fill: "green" }} />
                                                    </IconButton> :
                                                    <IconButton title="Testi staatus">
                                                        <CloseIcon style={{ fill: '#FF0000' }} />
                                                    </IconButton>}
                                            </div>
                                        </div>
                                    })
                                }
                            </div>

                            {itemNumber &&
                                <OzonatorTestCategory handleTestType={handleTestType} />
                            }

                            {savedOzTests && savedOzTests.length > 0 ? (
                                <div className="orderData" style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>

                                    {savedOzTests.filter(x => x.testStatus === "Created").length > 0 &&
                                        <>
                                            <Label className="mainPageForm-label" style={{ marginTop: "5%" }}>{t("TestsInProgress")}</Label>
                                            {savedOzTests.filter(x => x.testStatus === "Created").map((x, idx) =>
                                                <div key={idx} style={{ height: "100%", minHeight: "70px", width: "80%", display: "flex", justifyContent: "center" }}>
                                                    <div style={{ height: "100%", minHeight: "70px", display: "flex", justifyContent: "center" }} className={`orderDataOrders${x.id === ozonatorTest.id ? " active" : ""}`}
                                                        onClick={() => handleLoadTestData(x.id)}>{x.productName} - {t(`${x.testName}`)} - {x.createdBy} - {returnDate(x.createdDate.toString())}
                                                    </div>
                                                    <IconButton style={{ marginTop: "4%" }} onClick={(e: any) => handleRemoveTest(e, x.id!)}>
                                                        <ClearIcon />
                                                    </IconButton>
                                                </div>)}
                                        </>
                                    }
                                    {savedOzTests.filter(x => x.testStatus === "Finished").length > 0 &&
                                        <>
                                            <Label className="mainPageForm-label" style={{ marginTop: "5%" }}>{t("TestsFinished")}</Label>
                                            {savedOzTests.filter(x => x.testStatus === "Finished").map((x, idx) =>
                                                <div key={idx} style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", gap: "25px" }}>
                                                    <div key={idx} style={{ height: "100%", minHeight: "70px", width: "70%", display: "flex", justifyContent: "center" }} className={`orderDataOrders${x.id === ozonatorTest.id ? " active" : ""}`}
                                                        onClick={() => handleLoadTestData(x.id)}>{x.productName} - {t(`${x.testName}`)} - {x.createdBy} - {returnDate(x.createdDate.toString())}
                                                    </div>
                                                    <IconButton disabled={isLoading} style={{ marginTop: "4%", cursor: "pointer" }} onClick={() => handleOpenWebsite(x.directoCode)}>
                                                        <OpenInNewIcon />
                                                    </IconButton>
                                                </div>
                                            )}
                                        </>
                                    }
                                </div>
                            ) : null}
                        </React.Fragment>
                    )}
                </form>
            </div>
        </div>
    )
}

export default SelectOzonator