import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from "react";
import Loading from "../components/common/Loading";
import { ControlPanelTest } from "../types/control-panel/ControlPanelTest";
import { CanopyPurchaseOrder } from "../types/directo/CanopyPurchaseOrder";
import { Item } from "../types/directo/Item";
import {
  OzonatorPurchaseOrder,
  OzonatorPurchaseOrderRow,
} from "../types/directo/OzonatorPurchaseOrder";
import { PurchaseOrderCommon } from "../types/directo/PurchaseOrderCommon";
import { Kalorifeer, LeakTest } from "../types/kalorifeer/Kalorifeer";
import { TkalPurchaseOrder } from "../types/kalorifeer/TkalPurchaseOrder";
import { OzonatorTestDto } from "../types/ozonator/OzonatTestDto";
import { SepuTest } from "../types/sepu/SepuTest";

interface props {
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  orderData: TkalPurchaseOrder;
  setOrderData: React.Dispatch<React.SetStateAction<TkalPurchaseOrder>>;
  orderNumber: string | undefined;
  setOrderNumber: React.Dispatch<React.SetStateAction<string | undefined>>;
  itemNumber: string | undefined;
  setItemNumber: React.Dispatch<React.SetStateAction<string | undefined>>;
  itemIndex: number | undefined;
  setItemIndex: React.Dispatch<React.SetStateAction<number | undefined>>;
  itemData: Item;
  setItemData: React.Dispatch<React.SetStateAction<Item>>;
  testType: number | undefined;
  setTestType: React.Dispatch<React.SetStateAction<number | undefined>>;

  tkalData: Kalorifeer;
  setTkalData: React.Dispatch<React.SetStateAction<Kalorifeer>>;

  savedTests: Kalorifeer[] | undefined;
  setSavedTests: React.Dispatch<React.SetStateAction<Kalorifeer[] | undefined>>;

  ozonatorTest: OzonatorTestDto;
  setOzonatorTest: Dispatch<SetStateAction<OzonatorTestDto>>;
  ozOrderData: OzonatorPurchaseOrder;
  setOzOrderData: Dispatch<SetStateAction<OzonatorPurchaseOrder>>;

  savedOzTests: OzonatorTestDto[] | undefined;
  setSavedOzTests: React.Dispatch<
    React.SetStateAction<OzonatorTestDto[] | undefined>
  >;

  ozItemData: OzonatorPurchaseOrderRow;
  setOzItemData: Dispatch<SetStateAction<OzonatorPurchaseOrderRow>>;
  defaultPrinter: string;
  setDefaultPrinter: Dispatch<SetStateAction<string>>;

  sepuTest: SepuTest;
  setSepuTest: Dispatch<SetStateAction<SepuTest>>;
  purchaseOrderData: PurchaseOrderCommon;
  setPurchaseOrderData: Dispatch<SetStateAction<PurchaseOrderCommon>>;
  savedSepuTests: SepuTest[] | undefined;
  setSavedSepuTests: Dispatch<SetStateAction<SepuTest[] | undefined>>;

  controlPanelTest: ControlPanelTest;
  setControlPanelTest: Dispatch<SetStateAction<ControlPanelTest>>;
  savedControlPanelTests: ControlPanelTest[] | undefined;
  setSavedControlPanelTests: Dispatch<SetStateAction<ControlPanelTest[] | undefined>>;
}

export const TkalDataContext = createContext({} as props);

export const useTkalDataContext = () => useContext(TkalDataContext);

export const TkalDataContextWrapper = ({ children }: any) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [orderNumber, setOrderNumber] = useState<string>();
  const [orderData, setOrderData] = useState<TkalPurchaseOrder>(
    {} as TkalPurchaseOrder
  );
  const [itemNumber, setItemNumber] = useState<string>();
  const [itemIndex, setItemIndex] = useState<number>();
  const [itemData, setItemData] = useState<Item>({} as Item);
  const [testType, setTestType] = useState<number>();
  const [tkalData, setTkalData] = useState<Kalorifeer>({
    leakTest: {} as LeakTest,
  } as Kalorifeer);
  const [savedTests, setSavedTests] = useState<Kalorifeer[]>();

  const [ozonatorTest, setOzonatorTest] = useState<OzonatorTestDto>({
    measurements: {},
  } as OzonatorTestDto);
  const [ozOrderData, setOzOrderData] = useState<OzonatorPurchaseOrder>(
    {} as OzonatorPurchaseOrder
  );
  const [ozItemData, setOzItemData] = useState<OzonatorPurchaseOrderRow>(
    {} as OzonatorPurchaseOrderRow
  );
  const [savedOzTests, setSavedOzTests] = useState<OzonatorTestDto[]>();
  const [defaultPrinter, setDefaultPrinter] = useState<string>("OZ_Printer");

  const [sepuTest, setSepuTest] = useState<SepuTest>({ measurements: {} } as SepuTest);
  const [purchaseOrderData, setPurchaseOrderData] = useState<PurchaseOrderCommon>({} as PurchaseOrderCommon)
  const [savedSepuTests, setSavedSepuTests] = useState<SepuTest[]>();

  const [controlPanelTest, setControlPanelTest] = useState<ControlPanelTest>({ measurements: {} } as ControlPanelTest);
  const [savedControlPanelTests, setSavedControlPanelTests] = useState<ControlPanelTest[]>();
  return (
    <TkalDataContext.Provider
      value={{
        isLoading,
        setIsLoading,
        orderData,
        orderNumber,
        setOrderNumber,
        setOrderData,
        itemNumber,
        setItemNumber,
        itemIndex,
        setItemIndex,
        itemData,
        setItemData,
        testType,
        setTestType,
        tkalData,
        setTkalData,
        savedTests,
        setSavedTests,
        ozonatorTest,
        setOzonatorTest,
        ozOrderData,
        setOzOrderData,
        savedOzTests,
        setSavedOzTests,
        ozItemData,
        setOzItemData,
        defaultPrinter,
        setDefaultPrinter,
        sepuTest,
        setSepuTest,
        purchaseOrderData,
        setPurchaseOrderData,
        savedSepuTests,
        setSavedSepuTests,
        controlPanelTest,
        setControlPanelTest,
        savedControlPanelTests,
        setSavedControlPanelTests
      }}
    >
      <>
        {isLoading && <Loading />}
        {children}
      </>
    </TkalDataContext.Provider>
  );
};
