import { GroupBase, Props } from 'react-select'
import Select from "react-select";

const selectBtnStyles = {
    option: (provided: any, state: any) => ({
        ...provided,
        color: !state.isSelected ? "#ffffff" : "#ffffff",
        ':active': {
            ...provided[':active'],
            backgroundColor: !state.isSelected ? "#e8e8e8" : "#ffffff"
        },
        cursor: "pointer"
    }),
    control: (provided: any) => ({
        ...provided,
        marginLeft: 10,
        cursor: 'pointer',
        width: 150,
        border: "none",
        boxShadow: "none",
        backgroundColor: "inherit",
    }),
    menu: (provided: any, state: any) => ({
        ...provided,
        marginLeft: 10,
        maxWidth: 200,
        color: "#ffffff",
        backgroundColor: "inherit"
    }),
};

export function LangMenuSelect<
    Option,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>
>(props: Props<Option, IsMulti, Group>) {
    return (
        <Select {...props}
            components={{
                IndicatorSeparator: () => null
            }}
            theme={(theme) => ({
                ...theme, borderRadius: 4, colors: {
                    ...theme.colors,
                    neutral80: '#b3b3b3', //text for label
                    primary25: '#b3b3b3', //value to select
                    primary: '#b01c1c', //selected value
                },
            })}
            styles={selectBtnStyles} />
    );
}