import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Label } from "reactstrap";
import { useCustomer } from "../../context/user";
import "../../styles/testForm.css";

const NavigationPortalPage = () => {
  const history = useHistory();
  const { setCountry, permissions } = useCustomer();
  const { t } = useTranslation();

  const handleRedirect = (location: string) => {
    if (location === "/sweden") {
      setCountry("sweden");
    }
    if (location === "/") {
      setCountry("");
    }
    if (location === "/recair") {
      setCountry("");
    }
    history.push(location);
  };
  return (
    <div className="mainPageLayout">
      <div
        className="flexboxAlignedColumn mainPageForm"
        style={{ marginTop: "5%" }}
      >
        <div className="flexboxAlignedColumn" style={{ padding: "2%" }}>
          <Label className="portal-page__label">
            {t("NavigateToCorrectPage")}
          </Label>
          {permissions.includes("PNtoTesterEst") && (
            <div
              style={{ height: "100%", minHeight: "70px", width: "80%" }}
              className={`orderDataOrders`}
              onClick={() => handleRedirect("/")}
            >
              <div className="itemData">
                {t("Duct testing in Tallinn/Tuusula")}
              </div>
            </div>
          )}
          {permissions.includes("PNtoTesterSwe") && (
            <div
              style={{ height: "100%", minHeight: "70px", width: "80%" }}
              className={`orderDataOrders`}
              onClick={() => handleRedirect("/sweden")}
            >
              <div className="itemData">{t("Duct testing in Sweden")}</div>
            </div>
          )}
          {permissions.includes("PAhuTester") && (
            <div
              style={{ height: "100%", minHeight: "70px", width: "80%" }}
              className={`orderDataOrders`}
              onClick={() => handleRedirect("/recair")}
            >
              <div className="itemData">{t("AHU testing in Recair")}</div>
            </div>
          )}
          {permissions.includes("PAhuTesterEst") && (
            <div
              style={{ height: "100%", minHeight: "70px", width: "80%" }}
              className={`orderDataOrders`}
              onClick={() => handleRedirect("/ahu-tallinn")}
            >
              <div className="itemData">{t("AHU testing in Estonia")}</div>
            </div>
          )}
          {permissions.includes("PTkalTester") && (
            <div
              style={{ height: "100%", minHeight: "70px", width: "80%" }}
              className={`orderDataOrders`}
              onClick={() => handleRedirect("/tkal")}
            >
              <div className="itemData">{t("Heat exchanger testing")}</div>
            </div>
          )}
          {permissions.includes("PCanopyTesterEst") && (
            <div
              style={{ height: "100%", minHeight: "70px", width: "80%" }}
              className={`orderDataOrders`}
              onClick={() => handleRedirect("/canopy")}
            >
              <div className="itemData">{t("Kubu")}</div>
            </div>
          )}
          {permissions.includes("POzonatorTester") && (
            <div
              style={{ height: "100%", minHeight: "70px", width: "80%" }}
              className={`orderDataOrders`}
              onClick={() => handleRedirect("/ozonator")}
            >
              <div className="itemData">{t("Osonaatori testimine")}</div>
            </div>
          )}
          {(permissions.includes("PSepuTester") ||
            permissions.includes("PManageUsers")) && (
              <div
                style={{ height: "100%", minHeight: "70px", width: "80%" }}
                className={`orderDataOrders`}
                onClick={() => handleRedirect("/sepu")}
              >
                <div className="itemData">{t("SEPU testimine")}</div>
              </div>
            )}
          {permissions.includes("PManageUsers") && (
            <div
              style={{ height: "100%", minHeight: "70px", width: "80%" }}
              className={`orderDataOrders`}
              onClick={() => handleRedirect("/admin")}
            >
              <div className="itemData">{t("Admin")}</div>
            </div>
          )}
          {permissions.includes("PControlPanelTester") && (
            <div
              style={{ height: "100%", minHeight: "70px", width: "80%" }}
              className={`orderDataOrders`}
              onClick={() => handleRedirect("/control-panel")}
            >
              <div className="itemData">{t("Juhtkilpide testimine")}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NavigationPortalPage;
