import { useEffect } from 'react'
import { useHistory } from 'react-router';
import Loading from '../common/Loading';
import { useCustomer } from '../../context/user';

const LoginRedirect = () => {
    const history = useHistory();
    const path = history.location.pathname;
    const guid = path.substring(path.lastIndexOf("/") + 1, path.length);
    const { login } = useCustomer();

    useEffect(() => {
        login(guid)
        // eslint-disable-next-line
    }, [])

    return <Loading />
}
export default LoginRedirect
