import '../../../../styles/testOverviewTable.css'
import { useDataContext } from '../../../../context/ductTestData';
import { useTranslation } from 'react-i18next';

const DuctOverviewTestForm = () => {
    const { testData } = useDataContext();
    const { t } = useTranslation();

    return (
        <>
            <tr>
                <td className="firstTd">{t("LeakTestType")}</td>
                <td>{testData.testFileType ? t(`${testData.testFileType}`) : ""}</td>
            </tr>
            <tr style={{ borderBottom: "2px solid white" }}>
                <td className="firstTd">{t("LeakTestNumber")}</td>
                <td>{testData.smokeTestFileName ? t(`${testData.smokeTestFileName}`) : ""}</td>
            </tr>
        </>
    )
}
export default DuctOverviewTestForm 