import { useState } from 'react';
import Modal from '@mui/material/Modal';
import { Box, Button, Input, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import "../../styles/buttons.css"
import { PrinterDevice } from '../../types/admin/PrinterDevice';
import { useTranslation } from 'react-i18next';
import { Label } from 'reactstrap';
import { useDataContext } from '../../context/ductTestData';
import { addPrinterDevice } from '../queries/adminQueries';

const useStyles = makeStyles({
    root: {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#000000a8',
        border: '2px solid #000',
        width: "600px",
        whiteSpace: "pre-line",
    },
    title: {
        marginTop: "10px",
        fontSize: "24px",
        fontFamily: 'Banschrift'
    },
    bodyText: {
        paddingBottom: "30px",
        fontFamily: 'Banschrift',
        fontSize: '18px',
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    },
    input: {
        width: "60%",
        background: "white",
        display: "flex",
        justifyContent: "center",
    },
    inputLabel: {
        display: "flex",
        width: "60%",
        padding: "5px 0px 5px 0px"
    }
})



const AddPrinterModal = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [printer, setPrinter] = useState<Partial<PrinterDevice>>({});
    const styles = useStyles();
    const { setIsLoading } = useDataContext();
    const { t } = useTranslation();
    const handleOpen = () => {
        setIsOpen(true);
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    const handleAddDevice = (printerDevice: PrinterDevice) => {
        setIsLoading(true)
        addPrinterDevice(printerDevice).then((res) => { setIsOpen(false) }).catch((err) => {
            const errorMessage = err.response?.data.error ?? err.message;
            alert(errorMessage);
        }).finally(() => { setIsLoading(false) });
    }
    const handleInputChange = (event: any) => {
        const { name, value } = event.target;
        setPrinter({ ...printer, [name]: value });
    };


    return (
        <>
            <div style={{ marginTop: "3%" }} onClick={handleOpen}>
                <button className="button-primary btn btn-secondary" style={{ width: "150px" }} >{t("Add printer")}</button>
            </div>

            <Modal
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className={styles.root}>
                    <Typography className={styles.title} id="modal-modal-title" variant="h6" component="h2">
                        Add new printer
                    </Typography>
                    <Typography className={styles.bodyText} id="modal-modal-description">

                        <Label className={styles.inputLabel}>Code</Label>
                        <Input className={styles.input} type="text" name="Code" value={printer.Code} onChange={handleInputChange} />

                        <Label className={styles.inputLabel}>Name</Label>
                        <Input className={styles.input} type="text" name="Name" value={printer.Name} onChange={handleInputChange} />

                        <Label className={styles.inputLabel}>Description</Label>
                        <Input className={styles.input} type="text" name="Description" value={printer.Description} onChange={handleInputChange} />

                        <Label className={styles.inputLabel}>Location</Label>
                        <Input className={styles.input} type="text" name="Location" value={printer.Location} onChange={handleInputChange} />

                        <Label className={styles.inputLabel}>Address</Label>
                        <Input className={styles.input} type="text" name="Address" value={printer.Address} onChange={handleInputChange} />

                        <Label className={styles.inputLabel}>Port number</Label>
                        <Input className={styles.input} type="number" name="PortNumber" value={printer.PortNumber} onChange={handleInputChange} />

                        <div style={{ display: "flex", justifyContent: "center", gap: "10px", marginTop: "25px" }}>
                            <Button style={{ color: "white", background: "green" }} onClick={() => handleAddDevice(printer)} >Add</Button>
                            <Button style={{ color: "white", background: "#c00" }} onClick={() => setIsOpen(false)}>Cancel</Button>
                        </div>
                    </Typography>
                </Box>
            </Modal>
        </>
    );
}
export default AddPrinterModal;
