import { useState } from 'react'
import ThumbDownOffAltOutlinedIcon from '@mui/icons-material/ThumbDownOffAltOutlined';
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { MuiIcon } from '../../../common/MuiIcon';
import { useDataContext } from '../../../../context/ductTestData';

const ThumbsdownState = (props: { label: any, children: any, titleThumbsUp: string, titleThumbsDown: string, materialCategory: any, categoryState: boolean }) => {
    const [thumbsUpState, setThumbsUpState] = useState(!props.categoryState);
    const [thumbsDownState, setThumbsDownState] = useState(props.categoryState);
    const { setStiffnessProblem, setWhiteRustProblem, setRollWidthProblem, setIsRollWidthBoxOpen, setIsWhiteRustBoxOpen, setIsStiffnessBoxOpen } = useDataContext();

    const handleThumbsDownClick = (category: any) => {
        setThumbsUpState(false);
        setThumbsDownState(true);
        if (category === "RollWidth") {
            setIsRollWidthBoxOpen(true)
        }
        if (category === "WhiteRust") {
            setIsWhiteRustBoxOpen(true)
        }
        if (category === "Stiffness") {
            setIsStiffnessBoxOpen(true)
        }
    }
    const handleThumbsUpClick = (category: any) => {
        setThumbsUpState(true)
        setThumbsDownState(false);
        if (category === "RollWidth") {
            setRollWidthProblem("")
            setIsRollWidthBoxOpen(false)
        }
        if (category === "WhiteRust") {
            setWhiteRustProblem("")
            setIsWhiteRustBoxOpen(false)
        }
        if (category === "Stiffness") {
            setStiffnessProblem("")
            setIsStiffnessBoxOpen(false)
        }
    }

    const returnIcon = (state: boolean, icon: string) => {
        if (icon === "thumbsUp") {
            return state! ? (
                <ThumbUpIcon fontSize="large" style={{ fill: "#ffffff" }} />
            ) : (
                <ThumbUpAltOutlinedIcon fontSize="large" />
            )
        }
        else {
            return state ? (
                <ThumbDownIcon fontSize="large" style={{ fill: "#ffffff" }} />
            ) : (
                <ThumbDownOffAltOutlinedIcon fontSize="large" />
            )
        }
    }
    return (
        <div className="flexboxAlignedColumn">
            <div className="flexboxAlignedRow">
                <MuiIcon onClick={() => handleThumbsDownClick(props.materialCategory)} title={props.titleThumbsDown} Icon={() => returnIcon(thumbsDownState, "thumbsDown")} />
                <props.label />
                <MuiIcon onClick={() => handleThumbsUpClick(props.materialCategory)} title={props.titleThumbsUp} Icon={() => returnIcon(thumbsUpState, "thumbsUp")} />
            </div>
            {props.categoryState! && props.children}
        </div>
    )
}

export default ThumbsdownState