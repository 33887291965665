import React from 'react'
import { Label } from 'reactstrap'
import { useAhuDataContext } from '../../../../context/ahuData';
import { useTranslation } from "react-i18next";
import { CheckboxOptions, CheckboxDefaultValue } from '../common/checkboxConfig';
import CheckboxGroup from '../common/CheckboxGroup';
import { MeasurementField } from '../common/MeasurementField';
import IsDisabled from '../common/isDisabled';

const ExhaustFanForm = () => {
    const { electricalMeasurementData, handleAhuTestMeasurementsValue } = useAhuDataContext();
    const { t } = useTranslation();
    return (
        <form className="ahutest-container" >
            <Label className="ahutest-from--title">{t("Exhaust fan")}</Label>

            <div className="flexboxAlignedColumn" style={{ padding: "2%" }}>
                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Visual inspection")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="visualInspection-exFan"
                    />
                </div>
                <MeasurementField properyName="visualInspection-exFan" showValue={false} />

                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Fan manufacturer")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="manufacturer-exFan"
                    />
                </div>
                {IsDisabled("manufacturer-exFan") &&
                    <div className="flexboxAlignedRow" style={{ gap: "25px" }}>
                        <select className="ahutest-form__input select" title={t("Fan manufacturer")} name="manufacturer-exFan" onChange={e => handleAhuTestMeasurementsValue(e, "value")} value={electricalMeasurementData.measurements["manufacturer-exFan"]?.value ?? ""} placeholder={t("Value")} >
                            <option className="option" value="" disabled hidden>{t("Choose manufacturer")}</option>
                            <option className="option" value="Nicotra">Nicotra</option>
                            <option className="option" value="EBM Pabst">EBM Pabst</option>
                            <option className="option" value="ZIEHL">ZIEHL</option>
                        </select>
                    </div>}

                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("K-value")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="kValue-exFan"
                    />
                </div>
                <MeasurementField properyName="kValue-exFan" />

                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Rated power (kW)")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="ratedPower-exFan"
                    />
                </div>
                <MeasurementField properyName="ratedPower-exFan" unit="kW" gap="10px" />

                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Rated current (A)")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="ratedCurrent-exFan"
                    />
                </div>
                <MeasurementField properyName="ratedCurrent-exFan" unit="A" gap="10px" />

                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Measured current (A) max. at speed")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="measuredCurrent-exFan"
                    />
                </div>
                <MeasurementField properyName="measuredCurrent-exFan" unit="A" gap="10px" />

                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Frequency converter programmed")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="frequencyConverter-exFan"
                    />
                </div>
                <MeasurementField properyName="frequencyConverter-exFan" showValue={false} />

                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Fan controls tested")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="fanControler-exFan"
                    />
                </div>
                <MeasurementField properyName="fanControler-exFan" showValue={false} />

                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Differential pressure transmitter / air volume meter")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="pressureTransmitter-exFan"
                    />
                </div>
                <MeasurementField properyName="pressureTransmitter-exFan" inputProps={{ className: "ahutest-form__input --value smaller" }} unit="Pa" gap="10px" placeholderKey="Pressure range" />

                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Channel pressure transmitter")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="channelPressureTransmitter-exFan"
                    />
                </div>
                <MeasurementField inputProps={{ className: "ahutest-form__input --value smaller" }} properyName='channelPressureTransmitter-exFan' gap="10px" unit="Pa" placeholderKey="Pressure range" />

                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Measuring hoses")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="measuringHoses-exFan"
                    />
                </div>
                <MeasurementField properyName="measuringHoses-exFan" showValue={false} />
            </div>
        </form>
    )
}

export default ExhaustFanForm