import { Checkbox } from '@mui/material';
import React from 'react';
import '../../../styles/ahu-test.css'
import { useAhuDataContext } from '../../../context/ahuData';

interface CheckboxOption {
    value: string;
    icon: JSX.Element;
    checkedIcon: JSX.Element;
}

interface CheckboxGroupProps {
    options: CheckboxOption[];
    defaultValue: string;
    measurementKey: string;
}

const CheckboxGroup: React.FC<CheckboxGroupProps> = ({ options, defaultValue, measurementKey }) => {
    const { ahuTestDataEst, setAhuTestDataEst } = useAhuDataContext();

    const handleChange = (value: string, key: string) => {
        setAhuTestDataEst({
            ...ahuTestDataEst,
            "measurements": { ...ahuTestDataEst.measurements, [key]: { ...ahuTestDataEst.measurements[key], "statusNew": value } }
        })
    }

    return (
        <>
            {options.map((option, idx) => (
                <Checkbox
                    className={`grid-item-icon${idx + 1}`}
                    key={option.value}
                    icon={option.icon}
                    checkedIcon={option.checkedIcon}
                    checked={ahuTestDataEst.measurements[measurementKey]?.statusNew === option.value}
                    onChange={() => handleChange(option.value, measurementKey)}
                />
            ))}
        </>
    );
}

export default CheckboxGroup;
