import React from 'react'
import { useTranslation } from 'react-i18next';
import { Label } from 'reactstrap';
import { useTkalDataContext } from '../../../../context/tkalData';
import InfoModal from '../../ahu-estonia/InfoModal';
import { CheckboxOptions, CheckboxDefaultValue } from '../../ahu/common/checkboxConfig';
import CheckBoxGroupControlPanel from '../common/CheckBoxGroupControlPanel';
import MeasurementFieldControlPanel from '../common/MeasurementFieldControlPanel';

const UVSPreCheckForm = () => {
    const { t } = useTranslation();
    const { controlPanelTest, setControlPanelTest } = useTkalDataContext();
    const handleChangeMeasurementsValueUnit = (event: any, propertyName: string) => {
        setControlPanelTest({
            ...controlPanelTest,
            "measurements": { ...controlPanelTest.measurements, [event.target.name]: { ...controlPanelTest.measurements[event.target.name], [propertyName]: event.target.value, unit: "" } }
        })
    }
    return (
        <form className="ahutest-container" >
            <Label className="ahutest-from--title">{t("Eelkontroll")}</Label>
            <div className="flexboxAlignedColumn" style={{ padding: "2%" }}>
                <div className="ahutest-form__label--grid2">
                    <Label className="grid-item-label" >{t("Koostatud vastavalt elektriskeemile")}</Label>
                    <CheckBoxGroupControlPanel
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="schema-preCheck"
                    />
                </div>
                <MeasurementFieldControlPanel propertyName="schema-preCheck" showValue={false} />

                <div className="ahutest-form__label-info-button--grid" >
                    <Label className="grid-item-label" >{t("Sisend pistikul X1 on emane pistik küljes")}</Label>
                    <InfoModal title={t("Juhend")} bodyText={"Pistik X1 emane pistik on küljes ja kleebis 1-16 on küljes"} />
                    <CheckBoxGroupControlPanel
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="inlet-preCheck"
                    />
                </div>
                <MeasurementFieldControlPanel propertyName="inlet-preCheck" showValue={false} />

                <div className="ahutest-form__label-info-button--grid" >
                    <Label className="grid-item-label" >{t("Juhtmed on fiktseeritud ja kinnitatud korralikult. Juhtmed ei ole suure pinge all")}</Label>
                    <InfoModal title={t("Juhend")} bodyText={"Kontrollida, et ei oleks lahtiseid juhtmeid. Kergelt tõmmates juhet."} />
                    <CheckBoxGroupControlPanel
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="wires-preCheck"
                    />
                </div>
                <MeasurementFieldControlPanel propertyName="wires-preCheck" showValue={false} />

                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Seadmetel ja klemmidel on kleebised/märgistused peal")}</Label>
                    <CheckBoxGroupControlPanel
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="stickers-preCheck"
                    />
                </div>
                <MeasurementFieldControlPanel propertyName="stickers-preCheck" showValue={false} />

                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Sulavkaitse 3,15A klemmis F1 on olemas 2 tk (üks sees, üks varu)")}</Label>
                    <CheckBoxGroupControlPanel
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="UVSfuseF1-preCheck"
                    />
                </div>
                <MeasurementFieldControlPanel propertyName="UVSfuseF1-preCheck" showValue={false} />

                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Sulavkaitse 3,15A klemmis F2 on olemas 2 tk (üks sees üks varu)")}</Label>
                    <CheckBoxGroupControlPanel
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="UVSfuseF2-preCheck"
                    />
                </div>
                <MeasurementFieldControlPanel propertyName="UVSfuseF1-preCheck" showValue={false} />

                <div className="ahutest-form__label-info-button--grid" >
                    <Label className="grid-item-label" >{t("Ouman M BA 1-PCB kontrolleri seerianumber")}</Label>
                    <InfoModal title={t("Juhend")} bodyText={"Sisesta kontrolleri tootekleebisel olev seerianumber"} />
                </div>
                <div className="flexboxAlignedRow" style={{ gap: "15px" }}>
                    <div className='ahutest-form__input --value' style={{ border: "none" }}>SN</div>
                    <input className="ahutest-form__input" placeholder='Seerianumber' name="oumanSerial-preCheck" value={controlPanelTest.measurements["oumanSerial-preCheck"]?.value ?? ""} onChange={e => handleChangeMeasurementsValueUnit(e, "value")} />
                </div>

            </div>
        </form>
    )
}

export default UVSPreCheckForm