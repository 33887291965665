import { Button, Label } from "reactstrap"
import '../../../../styles/flexbox.css'
import '../../../../App.css'
import '../../../../styles/testForm.css'
import '../../../../styles/buttons.css'
import '../../../../styles/input.css'
import { useDataContext } from "../../../../context/ductTestData";
import { useTranslation } from "react-i18next";
import React from "react"
import TestCategoryState from "./TestCategoryState"
import ReturnProgressBar from "../../../common/ReturnProgressBar"
import { useCustomer } from "../../../../context/user"

const SelectItemForm = () => {
    const { orderNumber, setOrderNumber, orderData, itemNumber, testData, handleItemNumberChange, handleOrderSubmit } = useDataContext();
    const { getCurrentCountry } = useCustomer();
    const country = getCurrentCountry() ?? "";
    const { t, i18n } = useTranslation();

    return (
        <form className="flexboxAlignedColumn mainPageForm" onSubmit={e => handleOrderSubmit(e, country)}>
            <ReturnProgressBar level="1" lang={i18n.language} />
            <div className="flexboxAlignedColumn" style={{ padding: "2%" }}>
                <Label className="mainPageForm-label" style={{ marginTop: "5%" }}>{country !== "sweden" ? t("InsertOrder") : t("InsertProductionOrder")}
                </Label>
                <div className="flexboxAlignedRow" style={{ gap: "5%" }}>
                    <input className="input-orderNr" type="number" onChange={e => setOrderNumber(e.target.value)} value={orderNumber ? orderNumber : ""} placeholder={t("InsertNumber")} />
                    <Button className="button-primary" style={{ width: "94px" }} type="submit">{t("Search")}</Button>
                </div>
            </div>

            {orderData.orders ? (
                <React.Fragment key={orderData.orderNumber}>
                    <Label className="mainPageForm-label" style={{ marginTop: "2%" }}>{t("SelectItemToTest")}</Label>
                    <div className="orderData">
                        {Object.keys(orderData.orders).map((x, idx) => {
                            return <div key={idx} style={{ height: "100%", minHeight: "70px" }} className={`orderDataOrders${orderData.orders[x].itemCode === itemNumber ? " active" : ""}`}
                                onClick={() => handleItemNumberChange(orderData.orders[x].itemCode, country)}>
                                <div className="itemData" >{orderData.orders[x].itemCode} {orderData.orders[x].description}</div>
                            </div>
                        })}
                    </div>
                    <TestCategoryState testName={[testData.testName]} />
                </React.Fragment>
            ) : ""}
        </form>
    )
}

export default SelectItemForm;