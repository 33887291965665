import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Label } from "reactstrap";
import { useTkalDataContext } from "../../../context/tkalData";
import { CheckboxDefaultValue, CheckboxOptionsThumbsOnly } from "../ahu/common/checkboxConfig";
import '../../../styles/sepu-test.css'
import InfoModal from "../ahu-estonia/InfoModal";
import CheckboxGroupSepu from "./CheckboxGroupSepu";
import MeasurementFieldSepu from "./MeasurementFieldSepu";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";

const SepuVisualTest = () => {
  const { t } = useTranslation();
  const { sepuTest, setSepuTest } = useTkalDataContext();

  const handleChange = (e: any) => {
    setSepuTest({ ...sepuTest, [e.target.name]: e.target.value })
  }

  const handleCheckDimensions = (propertyName: string) => {
    if (propertyName === 'outerSizes-visual') {
      if (sepuTest.outerSizeA === null || sepuTest.outerSizeB === null) alert("Täidke välisosa mõõtude väljad")
      if (sepuTest.outerSizeA === 0 || sepuTest.outerSizeB === 0) alert("Täidke välisosa mõõtude väljad")
      if (sepuTest.outerSizeA.toString() === "" || sepuTest.outerSizeB.toString() === "") return alert("Täidke välisosa mõõtude väljad")

      if ((Math.abs(sepuTest.outerSizeA - sepuTest.correctSizeA) > 2) && (Math.abs(sepuTest.outerSizeB - sepuTest.correctSizeB) > 2)) {
        setSepuTest({
          ...sepuTest,
          "measurements": { ...sepuTest.measurements, [propertyName]: { ...sepuTest.measurements[propertyName], "statusNew": "FAIL" } }
        })
        return;
      }
      if ((Math.abs(sepuTest.outerSizeA - sepuTest.correctSizeA) <= 2) && (Math.abs(sepuTest.outerSizeB - sepuTest.correctSizeB) <= 2)) {
        setSepuTest({
          ...sepuTest,
          "measurements": { ...sepuTest.measurements, [propertyName]: { ...sepuTest.measurements[propertyName], "statusNew": "OK" } }
        })
        return;
      }
    }
    if (propertyName === 'diagonals-visual') {
      if (sepuTest.diagonalA === null || sepuTest.diagonalB === null) alert("Täidke diagonaalide mõõtude väljad")
      if (sepuTest.diagonalA === 0 || sepuTest.diagonalB === 0) alert("Täidke diagonaalide mõõtude väljad")
      if (sepuTest.diagonalA.toString() === "" || sepuTest.diagonalB.toString() === "") return alert("Täidke diagonaalide mõõtude väljad")

      const arithmeticAverage = (Number(sepuTest.diagonalA) + Number(sepuTest.diagonalB)) / 2;
      const maxDeviationPercentage = 1.5;
      const deviationD1 = Math.abs((sepuTest.diagonalA / arithmeticAverage) - 1) * 100;
      const deviationD2 = Math.abs((sepuTest.diagonalB / arithmeticAverage) - 1) * 100;

      if (deviationD1 <= maxDeviationPercentage && deviationD2 <= maxDeviationPercentage) {
        setSepuTest({
          ...sepuTest,
          "measurements": { ...sepuTest.measurements, [propertyName]: { ...sepuTest.measurements[propertyName], "statusNew": "OK" } }
        })
        return;
      }
      if (deviationD1 > maxDeviationPercentage || deviationD2 > maxDeviationPercentage) {
        setSepuTest({
          ...sepuTest,
          "measurements": { ...sepuTest.measurements, [propertyName]: { ...sepuTest.measurements[propertyName], "statusNew": "FAIL" } }
        })
        return;
      }
    }
  }

  return (
    <form className="ahutest-container" >
      <Label className="ahutest-from--title">{t("Visuaalne kontrollimine")}</Label>
      <div className="flexboxAlignedColumn" style={{ padding: "2%" }}>

        <Label className="mainPageForm-label" style={{ marginTop: "5%", fontSize: "23px" }}>{t("SEPU seinaluugi koostaja")}</Label>
        <div className="flexboxAlignedRow" style={{ gap: "5%" }}>
          <input className="input-orderNr sepu-test--input" name="itemProducer" onChange={e => handleChange(e)} value={sepuTest.itemProducer ?? ""} placeholder={t("Sisestage nimi")} />
        </div>

        <div className="flexboxAlignedColumn" style={{ gap: "15px", width: "80%" }}>
          <div className="flexboxAlignedRow">
            <div className='ahutest-form__input --value' style={{ border: "none", width: "380px", textAlign: "left", marginBottom: "2%" }}>{t("Diagonaalid (mm)")}</div>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <Button className="ahutest-form__input --value range button-selection-1" style={{ border: "2px solid white", fontSize: "15px", marginTop: "0", height: "45px" }} onClick={() => handleCheckDimensions("diagonals-visual")}>Kontrolli</Button>
              <InfoModal title={t("Juhend")} bodyText={`Märkida diagonaalide A ja B mõõdud millimeetrides. Seejärel vajuta 'Kontrolli'`} />
            </div>
          </div>
          <div >
            <Label style={{ display: `${sepuTest.measurements["diagonals-visual"]?.statusNew === undefined ? 'none' : 'block'} ` }}>
              {sepuTest.measurements["diagonals-visual"]?.statusNew === "OK" ? <div style={{ fontSize: "18px" }}>{t("Test läbitud")} <DoneIcon style={{ fill: "green" }} /></div> : ""}
              {sepuTest.measurements["diagonals-visual"]?.statusNew === "FAIL" ? <div style={{ fontSize: "18px" }}>{t("Test ebaõnnestunud")}  <CloseIcon style={{ fill: '#FF0000' }} />  </div> : ""}
            </Label>
          </div>
          <div className="flexboxAlignedRow" style={{ gap: "25px" }}>
            <div className="ahutest-form__input" style={{ display: "flex", justifyContent: "center", width: "50%" }}>
              <input className="ahutest-form__input" style={{ border: "none", width: "50%" }} placeholder='Diagonaal A' name="diagonalA" type="number" value={sepuTest.diagonalA ?? ""} onChange={e => handleChange(e)} />
            </div>
            <div className="ahutest-form__input" style={{ display: "flex", justifyContent: "center", width: "50%" }}>
              <input className="ahutest-form__input" style={{ border: "none", width: "50%" }} placeholder='Diagonaal B' name="diagonalB" type="number" value={sepuTest.diagonalB ?? ""} onChange={e => handleChange(e)} />
            </div>
          </div>
        </div>

        <div className="flexboxAlignedColumn" style={{ gap: "15px", width: "80%" }}>

          <div className="flexboxAlignedRow">
            <div className='ahutest-form__input --value' style={{ border: "none", width: "380px", textAlign: "left", marginBottom: "1%" }}>{t("Välisosa gabariitmõõdud (mm)")}</div>

            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <Button className="ahutest-form__input --value range button-selection-1" style={{ border: "2px solid white", fontSize: "15px", marginTop: "0", height: "45px" }} onClick={() => handleCheckDimensions("outerSizes-visual")} >Kontrolli</Button>
              <InfoModal title={t("Juhend")} bodyText={`Märkida välisosa A ja B gabariitmõõdud millimeetrides. Seejärel vajuta 'Kontrolli'`} />
            </div>
          </div>
          <div>
            <Label style={{ display: `${sepuTest.measurements["outerSizes-visual"]?.statusNew === undefined ? 'none' : 'block'} ` }}>
              {sepuTest.measurements["outerSizes-visual"]?.statusNew === "OK" ? <div style={{ fontSize: "18px" }}>{t("Test läbitud")} <DoneIcon style={{ fill: "green" }} /></div> : ""}
              {sepuTest.measurements["outerSizes-visual"]?.statusNew === "FAIL" ? <div style={{ fontSize: "18px" }}>{t("Ebaõnnestunud")}  <CloseIcon style={{ fill: '#FF0000' }} />  </div> : ""}
            </Label>
          </div>

          <div className="flexboxAlignedRow" style={{ gap: "25px" }}>
            <div className="ahutest-form__input" style={{ display: "flex", justifyContent: "center", width: "50%" }}>
              <input type="number" className="ahutest-form__input xs-placeholder" style={{ border: "none", width: "50%" }} placeholder='Välisosa mõõt A' name="outerSizeA" value={sepuTest.outerSizeA ?? ""} onChange={e => handleChange(e)} />
            </div>
            <div className="ahutest-form__input" style={{ display: "flex", justifyContent: "center", width: "50%" }}>
              <input type="number" className="ahutest-form__input xs-placeholder" style={{ border: "none", width: "50%" }} placeholder='Välisosa mõõt B' name="outerSizeB" value={sepuTest.outerSizeB ?? ""} onChange={e => handleChange(e)} />
            </div>
          </div>
        </div>


        <div className="ahutest-form__label--grid2" >
          <Label className="grid-item-label" >{t("Tihendid on korras")}</Label>
          <CheckboxGroupSepu
            options={CheckboxOptionsThumbsOnly}
            defaultValue={CheckboxDefaultValue}
            measurementKey="seals-visual"
          />
        </div>
        <MeasurementFieldSepu propertyName="seals-visual" showValue={false} />

      </div>
    </form >
  )
};

export default SepuVisualTest;
