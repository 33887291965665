import React from 'react'
import { useTranslation } from 'react-i18next';
import { Label } from 'reactstrap';
import { useTkalDataContext } from '../../../context/tkalData';
import SelectPrinter from '../../common/SelectPrinter';
import InfoModal from '../ahu-estonia/InfoModal';
import { CheckboxDefaultValue, CheckboxOptions } from '../ahu/common/checkboxConfig';
import CheckBoxGroupControlPanel from './common/CheckBoxGroupControlPanel';
import MeasurementFieldControlPanel from './common/MeasurementFieldControlPanel';

const ControlPanelPackageForm = () => {
    const { t } = useTranslation();
    const { controlPanelTest, setControlPanelTest } = useTkalDataContext();

    return (
        <form className="ahutest-container" >
            <Label className="ahutest-from--title">{t("Pakend")}</Label>
            <div className="flexboxAlignedColumn" style={{ padding: "2%" }}>
                <div className="ahutest-form__label-info-button--grid" >
                    <Label className="grid-item-label" >{t("Toitekaabel on olemas")}</Label>
                    <InfoModal title={t("Juhend")} bodyText={"1tk"} />
                    <CheckBoxGroupControlPanel
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="cable-package"
                    />
                </div>
                <MeasurementFieldControlPanel propertyName="cable-package" showValue={false} />

                <div className="ahutest-form__label-info-button--grid" >
                    <Label className="grid-item-label" >{t("Juhendid on olemas: FIN/ENG, SWE/ENG, EST/ENG")}</Label>
                    <InfoModal title={t("Juhend")} bodyText={"3 lehte"} />
                    <CheckBoxGroupControlPanel
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="instructions-package"
                    />
                </div>
                <MeasurementFieldControlPanel propertyName="instructions-package" showValue={false} />

                <div className="ahutest-form__label-info-button--grid" >
                    <Label className="grid-item-label" >{t("Kilbil on pehmendused küljes")}</Label>
                    <CheckBoxGroupControlPanel
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="cushions-package"
                    />
                </div>
                <MeasurementFieldControlPanel propertyName="cushions-package" showValue={false} />

                <div className="ahutest-form__label-info-button--grid" >
                    <Label className="grid-item-label" >{t("Kastil on tootekleebis küljes")}</Label>
                    <CheckBoxGroupControlPanel
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="sticker-package"
                    />
                </div>
                <MeasurementFieldControlPanel propertyName="sticker-package" showValue={false} />

                <SelectPrinter defaultPrinter="OZ_Printer" />

                <Label className="grid-item-label" style={{ marginBottom: "5%", marginTop: "2%", marginLeft: "0" }}>{t("Description")}</Label>
                <textarea className="material-textarea-description" style={{ width: "90%" }} value={controlPanelTest.description ? controlPanelTest.description : ""} name="description" placeholder={t("OverallDescription")} maxLength={200}
                    onChange={(e: any) => setControlPanelTest({ ...controlPanelTest, description: e.target.value })} />
            </div>
        </form>
    )
}

export default ControlPanelPackageForm