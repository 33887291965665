import React from 'react'
import { useTranslation } from 'react-i18next';
import { Label } from 'reactstrap';
import { CheckboxOptions, CheckboxDefaultValue } from '../../ahu/common/checkboxConfig';
import CheckboxGroup from '../CheckboxGroup';
import MeasurementField from '../MeasurementField';

const ElectricalMeasurementsForm1 = () => {
    const { t } = useTranslation();
    return (
        <form className="ahutest-container" >
            <Label className="ahutest-from--title">{t("Insulation250V")}</Label>
            <div className="flexboxAlignedColumn" style={{ padding: "2%" }}>

                <div className="ahutest-form__label--grid2">
                    <Label className="grid-item-label">{t("PowerSupplyTerminals")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="powerSupplyTerminals-resistance"
                    />
                </div>
                <MeasurementField properyName='powerSupplyTerminals-resistance' unit="MΩ" gap="10px" />

                <div className="ahutest-form__label--grid2">
                    <Label className="grid-item-label">{t("Inlet fan")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="inletFan-resistance"
                    />
                </div>
                <MeasurementField properyName='inletFan-resistance' unit="MΩ" gap="10px" />


                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Exhaust fan")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="exhaustFan-resistance"
                    />
                </div>
                <MeasurementField properyName='exhaustFan-resistance' unit="MΩ" gap="10px" />

                <div className="ahutest-form__label--grid2" style={{ gap: "10px" }}>
                    <Label className="grid-item-label" >{t("Electric heater")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="electricHeater-resistance"
                    />
                </div>
                <MeasurementField properyName='electricHeater-resistance' unit="MΩ" gap="10px" />

            </div>
        </form>
    )
}

export default ElectricalMeasurementsForm1