import { useState } from 'react';
import { Button } from 'reactstrap';
import MeasurementTestForm from '../../components/configurationForms/duct-test/ntoTest/MeasurementTestForm';
import SelectItemForm from '../../components/configurationForms/duct-test/common/SelectItemForm';
import MaterialTestForm from '../../components/configurationForms/duct-test/ntoTest/MaterialTestForm';
import SmokeTestForm from '../../components/configurationForms/duct-test/common/SmokeTestForm';
import { useDataContext } from '../../context/ductTestData';
import { DuctTestType } from '../../types/DuctTestType';
import { Item } from '../../types/directo/Item';
import { Order } from '../../types/directo/Order';
import { useCustomer } from '../../context/user';
import '../../styles/flexbox.css'
import '../../styles/testForm.css'
import '../../styles/input.css'
import '../../styles/buttons.css'
import { postTestRequest, postTestRequestSweden } from '../../components/queries/testQueries';
import { useTranslation } from "react-i18next";
import DescriptionForm from '../../components/configurationForms/duct-test/ductConnectionTest/DescriptionForm';
import DuctOverviewTestForm from '../../components/configurationForms/duct-test/ductConnectionTest/DuctOverviewTestForm';
import NtoOverviewTestForm from '../../components/configurationForms/duct-test/ntoTest/NtoOverviewTestForm';
import OverviewTestForm from '../../components/configurationForms/duct-test/common/OverviewTestForm';

const StartPage = () => {
    const [step, setStep] = useState(1);
    const { setTestData, testData, setOrderData, setItemData, itemData } = useDataContext();
    const { stiffnessProblem, rollWidthProblem, whiteRustProblem, setStiffnessProblem, setWhiteRustProblem, setRollWidthProblem, setIsRollWidthBoxOpen, setIsStiffnessBoxOpen, setIsWhiteRustBoxOpen } = useDataContext();
    const { setIsLoading, isLoading } = useDataContext();
    const { getCurrentCountry } = useCustomer();
    const { t } = useTranslation();
    let ductTestSteps = 4;
    let ntoTestSteps = 5;

    const validateLastStep = () => {
        if (step === ductTestSteps && testData.testName === "ductFittingsTest") {
            return true;
        }
        if (step === ntoTestSteps && testData.testName === "ntoTest") {
            return true;
        }
        return false;
    }
    const validateNextStep = () => {
        if (step < ductTestSteps && testData.testName === "ductFittingsTest") {
            return true;
        }
        if (step < ntoTestSteps && testData.testName === "ntoTest") {
            return true;
        }
        return false;
    }

    const handleNextButton = () => {
        if (testData.testName === "ductFittingsTest") setStep(prev => prev + 1)
        if (testData.testName === "ntoTest") {
            if (step === 1) {
                if (testData.orderNumber === undefined) {
                    alert("Please insert purchaseorder number and press 'Search'")
                }
                else if (testData.itemNumber === undefined) {
                    alert(t("Select item to test"))
                }
                else if (testData.testName === undefined) {
                    alert(t("Select test"))
                }
                else {
                    setStep(prev => prev + 1)
                }
            }
            if (step === 2) {
                if (testData.itemToTest === undefined) {
                    alert(t("ItemToTestAlert"))
                }
                else if (testData.diameter === undefined) {
                    alert(t("DiameterAlert"))
                }

                else if (testData.lengthTestResult === undefined && itemData.length !== ("")) {
                    alert(t("LengthAlert"))
                }
                else if (testData.length === undefined || testData.length === "") {
                    alert(t("LengthAlert2"))
                }
                else {
                    setStep(prev => prev + 1)
                }
            }
            if (step === 3) {
                setStep(prev => prev + 1);
            }
            if (step === 4) {
                if (testData.batchId === ("" || undefined)) {
                    alert(t("BatchIdAlert"))
                    return;
                }
                if (testData.materialThickness === ("" || undefined) && testData.itemToTest === ("FirstItemTest")) {
                    alert(t("MaterialThicknessAlert"))
                    return;
                }
                else {
                    setStep(prev => prev + 1)
                }
            }
        }
    }

    const handleBackButton = () => {
        if (step >= 2) {
            setStep(prev => prev - 1);
        }
    }
    const clearTestData = () => {
        setTestData({ description: "" } as DuctTestType)
        setStiffnessProblem("");
        setRollWidthProblem("");
        setWhiteRustProblem("");
        setItemData({} as Item);
        setOrderData({} as Order);
        setIsRollWidthBoxOpen(false);
        setIsStiffnessBoxOpen(false);
        setIsWhiteRustBoxOpen(false);
    }

    const showData = (prop: any, text: string) => {
        return prop && `${text} - ${prop}\n`
    }
    const convertDate = () => {
        var today = new Date();
        today.setHours(today.getHours() + 2);
        return today;
    }
    const handlePostTestRequest = async (data: DuctTestType) => {
        setIsLoading(true);
        if (getCurrentCountry() === "sweden") {
            await postTestRequestSweden(data).then(() => {
                alert(t("TestSentSuccess"))
                clearTestData();
                setStep(1);
            }).catch((err) => {
                console.log(err)
                alert(t("TestSentError"))
            }).finally(() => { setIsLoading(false) })
            return;
        }
        await postTestRequest(data).then(() => {
            alert(t("TestSentSuccess"))
            clearTestData();
            setStep(1);
        }).catch((err) => {
            console.log(err)
            alert(t("TestSentError"))
        }).finally(() => { setIsLoading(false) })
    }

    const postTestData = async () => {
        var data = {
            ...testData,
            smokeTestFileName: `${(testData.smokeTestFileName ? testData.smokeTestFileName : "-")}`,
            description: `${showData(testData.description, "Description")}${showData(rollWidthProblem, "Roll width problem")}${showData(whiteRustProblem, "White rust problem")}${showData(stiffnessProblem, "Stiffness problem")}`,
            whiteRust: `${whiteRustProblem ? "Yes" : "-"}`, stiffness: `${stiffnessProblem ? "Yes" : "-"}`, rollWidth: `${rollWidthProblem ? "Yes" : "-"}`,
            startDate: convertDate()
        } as DuctTestType

        if (!testData.orderNumber || !testData.itemNumber) {
            alert(t("ItemNotSelectedAlert"))
        }
        await handlePostTestRequest(data);
    }

    return (
        <div className="mainPageLayout" >
            {
                (() => {
                    if (step === 1) {
                        return <SelectItemForm />
                    }
                    switch (testData.testName) {
                        case "ntoTest":
                            switch (step) {
                                case 2:
                                    return <MeasurementTestForm />
                                case 3:
                                    return <SmokeTestForm />
                                case 4:
                                    return <MaterialTestForm />
                                case 5:
                                    return <OverviewTestForm><NtoOverviewTestForm /></OverviewTestForm>
                            }
                            break;
                        case "ductFittingsTest":
                            switch (step) {
                                case 2:
                                    return <SmokeTestForm />
                                case 3:
                                    return <DescriptionForm />
                                case 4:
                                    return <OverviewTestForm><DuctOverviewTestForm /></OverviewTestForm>
                            }
                    }
                })()
            }

            <div className="navigation-buttons-container">
                {step >= 2 && <Button className="button-primary" onClick={handleBackButton}>{t("Back")}</Button>}
                {validateNextStep() && <Button className="button-primary" onClick={handleNextButton}>{t("Next")}</Button>}
                {validateLastStep() && <Button className="button-primary" style={{ width: "170px", backgroundColor: "white", color: "red" }} onClick={postTestData} disabled={isLoading}>{t("ConfirmAndSend")}</Button>}
            </div>
        </div >
    )
}

export default StartPage;