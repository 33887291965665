export const handleOpenWebsite = (directoCode: string) => {
    window.open(`https://login.directo.ee/ocra_ets_nord/event.asp?KOOD=${directoCode}`, '_blank');
}

export const returnDateFromDateString = (dateString: string) => {
    if (dateString === null) return;
    const year = dateString.substring(0, 4);
    const month = dateString.substring(5, 7);
    const day = dateString.substring(8, 10);
    return `${day}.${month}.${year}`
}