
export enum ControlPanelTestType {
    EMPTY = 0,
    UVSTESTREPORT = 1,
    UVLTESTREPORT = 2,
}

export type ControlPanelTest = {
    id: number,
    purchaseOrder: string
    productArticleNumber: string
    productName: string
    productUniqueId: string
    finishedBy: string
    finishedDate: Date,
    directoCode: string,
    /* testType: string, */
    testType: ControlPanelTestType,
    testStatus: string
    testName: string
    createdBy: string
    createdByUserId: string
    createdDate: Date
    supplierCode: string
    supplierName: string
    softwareVersion: string,
    electricalSchemaVersion: string,
    serialNumber: string,
    rootJson: string,
    description: string
    measurements: { [key: string]: Measurement },
}

export type Measurement = {
    name: string,
    status: boolean,
    statusNew: string,
    value: string,
    description: string,
    unit: string
}

export type ControlPanelCreateTestDto = {
    purchaseOrder: string
    productArticleNumber: string
    productName: string
    productUniqueId: string
    testType: ControlPanelTestType,
}