import React from 'react'
import { useTranslation } from 'react-i18next';
import { Label } from 'reactstrap';
import { CheckboxOptions, CheckboxDefaultValue } from '../../ahu/common/checkboxConfig';
import CheckboxGroup from '../CheckboxGroup';
import MeasurementField from '../MeasurementField';

const ElectricalMeasurementsForm2 = () => {
    const { t } = useTranslation();
    return (
        <form className="ahutest-container" >
            <Label className="ahutest-from--title">{t("Continuity")}</Label>
            <div className="flexboxAlignedColumn" style={{ padding: "2%" }}>
                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Din rail")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="dinRail-continuity"
                    />
                </div>
                <MeasurementField properyName='dinRail-continuity' unit="Ω" gap="10px" />

                <div className="ahutest-form__label--grid2">
                    <Label className="grid-item-label" >{t("Inlet fan")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="inletFan-continuity"
                    />
                </div>
                <MeasurementField properyName='inletFan-continuity' unit="Ω" gap="10px" />

                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Exhaust fan")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="exhaustFan-continuity"
                    />
                </div>
                <MeasurementField properyName='exhaustFan-continuity' unit="Ω" gap="10px" />

                <div className="ahutest-form__label--grid2" style={{ gap: "10px" }}>
                    <Label className="grid-item-label" >{t("Electric heater")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="electricHeater-continuity"
                    />
                </div>
                <MeasurementField properyName='electricHeater-continuity' unit="Ω" gap="10px" />
            </div>
        </form>
    )
}

export default ElectricalMeasurementsForm2