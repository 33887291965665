import React from 'react'
import { useTranslation } from 'react-i18next';
import { Label } from 'reactstrap';
import InfoModal from '../ahu-estonia/InfoModal';
import { CheckboxDefaultValue, CheckboxOptions } from '../ahu/common/checkboxConfig';
import CheckboxGroupOzonator from './common/CheckboxGroupOzonator';
import MeasurementFieldOzonator from './common/MeasurementFieldOzonator';
import { longTestText } from './ModalTexts';

const OzonatorLongTest = () => {
    const { t } = useTranslation();

    return (
        <form className="ahutest-container" >
            <Label className="ahutest-from--title">{t("Pikaajaline test")}</Label>
            <div className="flexboxAlignedColumn" style={{ padding: "2%" }}>
                <div className="ahutest-form__label-info-button--grid" >
                    <Label className="grid-item-label" >{t("Seade töötab peale pikemaajalist testi ning alarme vahepeal ei tekkinud (4h)")}</Label>
                    <InfoModal title={t("Juhend")} bodyText={longTestText} />
                    <CheckboxGroupOzonator
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="test1-longTest"
                    />
                </div>
                <MeasurementFieldOzonator propertyName="test1-longTest" showValue={false} />
            </div>
        </form>
    )
}

export default OzonatorLongTest