import { Spinner } from 'reactstrap';
import { useTranslation } from "react-i18next";
import "../../styles/loading.css"

const Loading = () => {
    const { t } = useTranslation();
    return (
        <div className="loading-container">
            <h4 className="loading-text-container">{t("Loading")}</h4>
            <div className="loading-spinner-container">
                <Spinner variant="light"></Spinner>
            </div>
        </div>
    )
};
export default Loading;
