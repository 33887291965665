import { Measurement } from "../ahu/Measurement";

export type SepuTest = {
  id: number;
  purchaseOrderNumber: string;
  productArticleNumber: string;
  productName: string;
  productUniqueId: string;
  finishedBy: string;
  finishedDate: Date;
  directoCode: string;

  testType: SepuTestType;
  testStatus: string;
  /*   testType: (typeof SEPU_TEST_TYPE)[keyof typeof SEPU_TEST_TYPE]; */
  testName: string;
  createdBy: string;
  createdByUserId: string;
  createdDate: Date;
  serialNumber: string;
  description: string;
  itemProducer: string;

  diagonalA: number;
  diagonalB: number;
  outerSizeA: number;
  outerSizeB: number;
  sealsCorrect: boolean;
  visualCorrect: boolean;
  gearingWorks: boolean;
  gearingProductionDate: string;
  hatchOpeningTime: number;
  hatchOpeningAngle: number;
  measurements: { [key: string]: Measurement },
  rootJson: string,

  correctSizeA: number;
  correctSizeB: number;
  correctOpeningTime: number;
  correctOpeningAngle: number
};

/* export const SEPU_TEST_TYPE = {
  EMPTY: 0,
  TESTRAPORT: 1,
} as const; */

export enum SepuTestType {
  EMPTY = 0,
  TESTRAPORT = 1,
}

export type SepuCreateTestDto = {
  purchaseOrder: string
  productArticleNumber: string
  productName: string
  productUniqueId: string
  testType: SepuTestType,
}
