import React from 'react'
import { Label } from 'reactstrap';
import { useTranslation } from "react-i18next";
import { CheckboxOptions, CheckboxDefaultValue } from '../common/checkboxConfig';
import CheckboxGroup from '../common/CheckboxGroup';
import { MeasurementField } from '../common/MeasurementField';

const RadiatorForm = () => {
    const { t } = useTranslation();
    return (
        <form className="ahutest-container" >
            <Label className="ahutest-from--title">{t("Electric heating radiator")}</Label>

            <div className="flexboxAlignedColumn" style={{ padding: "2%" }}>
                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Power (kW)")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="power-ElHeatRad"
                    />
                </div>
                <MeasurementField properyName="power-ElHeatRad" unit="kW" gap="10px" />

                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Current (A)")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="current-ElHeatRad"
                    />
                </div>
                <MeasurementField properyName="current-ElHeatRad" unit="A" gap="10px" />

                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Resistors heat up")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="resistors-ElHeatRad"
                    />
                </div>
                <MeasurementField properyName="resistors-ElHeatRad" showValue={false} />

                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Overheating protection")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="overheatingProtect-ElHeatRad"
                    />
                </div>
                <MeasurementField properyName="overheatingProtect-ElHeatRad" showValue={false} />

                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("After-running of the fans")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="fans-ElHeatRad"
                    />
                </div>
                <MeasurementField properyName="fans-ElHeatRad" showValue={false} />

                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Radiator control")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="control-ElHeatRad"
                    />
                </div>
                <MeasurementField properyName="control-ElHeatRad" showValue={false} />
            </div>
        </form>
    )
}

export default RadiatorForm