import { useTranslation } from "react-i18next";
import React from 'react'
import { Button, Label } from 'reactstrap'
import AhuTestCategory from "./AhuTestCategory";
import { useAhuDataContext } from "../../../context/ahuData";
import { AhuElectricalMeasurements } from "../../../types/ahu/AhuElectricalMeasurements";
import { deleteRecairAhuTest, getAllOrderTests, getCreatedAhuTests, getPdf, loadAhuTest } from "../../queries/testQueries";
import { AxiosResponse } from "axios";
import '../../../styles/testForm.css'
import FileOpenIcon from '@mui/icons-material/FileOpen';
import { saveAs } from 'file-saver';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { IconButton } from "@mui/material";
import SendEmailModal from "./SendEmailModal";
import ClearIcon from '@mui/icons-material/Clear';
import { handleOpenWebsite } from "../CommonFunctions";

const SelectOrderForm = () => {
    const { t } = useTranslation();
    const { itemIndex, setIsLoading, isLoading, electricalMeasurementData, setCreatedTests, setElectricalMeasurementData,
        handleGetOrderData, handleItemRequest, salesOrderData, setOrderNumber, orderNumber, itemNumber, createdTests, setTestType, testType } = useAhuDataContext();

    const handleLoadTestData = (selectedTest: AhuElectricalMeasurements) => {
        setIsLoading(true)
        if (selectedTest.id === null) return;
        loadAhuTest(selectedTest.id).then((res: AxiosResponse<AhuElectricalMeasurements>) => {
            setElectricalMeasurementData(res.data)
        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const handleLoadPdf = (directoCode: string, rowNumber: string) => {

        if (directoCode === (null || undefined)) return;
        setIsLoading(true)
        getPdf(directoCode, rowNumber, "event").then((response) => {
            const filename = `AHU Test no. ${directoCode}.pdf`;
            saveAs(response.data, filename);
        })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }
    const handleChangeSelect = (event: any) => {
        setElectricalMeasurementData({
            ...electricalMeasurementData,
            "commonTestData": { ...electricalMeasurementData.commonTestData, [event.target.name]: event.target.value }
        })
    }

    const handleBooleanInputChange = (event: any) => {
        setElectricalMeasurementData({
            ...electricalMeasurementData,
            "commonTestData": { ...electricalMeasurementData.commonTestData, [event.target.name]: event.target.checked }
        })
    }

    const handleRemoveTest = (id: number) => {
        deleteRecairAhuTest(id).then((res) => {
            getAllOrderTests(orderNumber!).then((response: AxiosResponse<AhuElectricalMeasurements[]>) => {
                setCreatedTests(response.data)
            });
        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    //TODO: Check if this works
    //03.01.2024 extracted it from AhuTestCategory.tsx
    const handleTestType = (value: any) => {
        setIsLoading(true)
        setElectricalMeasurementData(prev => ({ ...prev, testType: value, id: 0, measurements: {} }))
        setTestType(value)
        getCreatedAhuTests(orderNumber ?? "", value, electricalMeasurementData.snCode).then((res: AxiosResponse<AhuElectricalMeasurements[]>) => {
            setCreatedTests(res.data)
        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    function returnDate(dateString: string) {
        if (dateString === null) return;
        const year = dateString.substring(0, 4);
        const month = dateString.substring(5, 7);
        const day = dateString.substring(8, 10);
        return `${day}.${month}.${year}`
    }

    return (
        <div className="flexboxAlignedColumn mainPageForm" style={{ marginTop: "3%" }}>
            <form className="flexboxAlignedColumn mainPageForm" style={{ marginTop: "10%" }} onSubmit={e => handleGetOrderData(e ?? `${alert(t("ItemNotSelectedAlert"))}`)}>

                <div className="flexboxAlignedColumn" style={{ padding: "2%" }}>
                    <Label className="mainPageForm-label">{t("InsertSalesorder")}
                    </Label>
                    <div className="flexboxAlignedRow" style={{ gap: "5%" }}>
                        <input className="input-orderNr" type="number" onChange={e => setOrderNumber(e.target.value)} value={orderNumber ?? ""} placeholder={t("InsertNumber")} />
                        <Button className="button-primary" style={{ width: "94px" }} type="submit">{t("Search")}</Button>
                    </div>
                </div>


                {salesOrderData.items && (
                    <React.Fragment key={salesOrderData.orderNumber}>
                        <Label className="mainPageForm-label" style={{ marginTop: "2%" }}>{t("SelectItemToTest")}</Label>
                        <div className="orderData">
                            {
                                Object.values(salesOrderData.items).map((x, idx) => {
                                    return <div key={idx} style={{ height: "100%", minHeight: "70px" }} className={`orderDataOrders${(x.itemCode === itemNumber && (idx === itemIndex)) ? " active" : ""}`}
                                        onClick={(e: any) => handleItemRequest(e, x.itemCode, idx, x.snCode)}>
                                        <div className="itemData" >{x.itemCode} {x.description}</div>

                                    </div>
                                })
                            }
                        </div>
                        {electricalMeasurementData.itemNumber && <AhuTestCategory testName={["electrical measurement", "inspection log", "cooler protocol", "cooler test protocol"]} handleTestType={handleTestType} testType={testType} />}
                    </React.Fragment>
                )}

                {electricalMeasurementData.testType === "inspection log" &&
                    <>
                        <Label className="mainPageForm-label" style={{ marginTop: "5%" }}>{t("ChooseLTO")}</Label>
                        <select className="mainPageForm-select" name="ltoType" title={t("ChooseLTOType")} placeholder={t("ChooseLTOType")} value={electricalMeasurementData.commonTestData.ltoType || ""} onChange={(e: any) => handleChangeSelect(e)}>
                            <option value="" disabled hidden>{t("ChooseLTO")}</option>
                            <option value="rotor">{t("Rotor")}</option>
                            <option value="cube">{t("Cube")}</option>
                            <option value="glycol">{t("Glycol")}</option>
                        </select>
                        <Label className="mainPageForm-label" style={{ marginTop: "5%" }}>{t("ChooseFeatures")}</Label>

                        <div className="flexboxAlignedRow" style={{ gap: "30px", justifyContent: "space-between", width: "50%" }}>
                            <label style={{ fontSize: "20px" }}>{t("WaterRadiator")}</label>
                            <input className="mainPageForm-checkbox" type="checkbox" checked={electricalMeasurementData.commonTestData.waterRadiator || false} name="waterRadiator" onChange={(e: any) => handleBooleanInputChange(e)}></input>
                        </div>
                        <div className="flexboxAlignedRow" style={{ gap: "30px", justifyContent: "space-between", width: "50%" }}>
                            <label style={{ fontSize: "20px" }}>{t("PreheatingCoil")}</label>
                            <input className="mainPageForm-checkbox" type="checkbox" checked={electricalMeasurementData.commonTestData.preHeater || false} name="preHeater" onChange={(e: any) => handleBooleanInputChange(e)}></input>
                        </div>
                        <div className="flexboxAlignedRow" style={{ gap: "30px", justifyContent: "space-between", width: "50%" }}>
                            <label style={{ fontSize: "20px" }}>{t("EletricHeatingRadiator")}</label>
                            <input className="mainPageForm-checkbox" type="checkbox" checked={electricalMeasurementData.commonTestData.electricRadiator || false} name="electricRadiator" onChange={(e: any) => handleBooleanInputChange(e)}></input>
                        </div>
                    </>
                }
                {createdTests && createdTests.length > 0 ? (
                    <div className="orderData" style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>

                        {createdTests.filter(x => x.testStatus === "Created").length > 0 &&
                            <>
                                <Label className="mainPageForm-label" style={{ marginTop: "5%" }}>{t("TestsInProgress")}</Label>

                                {createdTests.filter(x => x.testStatus === "Created").map((x, idx) =>
                                    <div key={idx} style={{ height: "100%", minHeight: "70px", width: "80%", display: "flex", justifyContent: "center" }}>
                                        <div style={{ height: "100%", minHeight: "70px",/*  width: "70%", */ display: "flex", justifyContent: "center" }} className={`orderDataOrders${x.id === electricalMeasurementData.id ? " active" : ""}`}
                                            onClick={() => handleLoadTestData(x)}>{x.itemNumber} - {t(`${x.testType}`)} - {x.testPerformer} - {returnDate(x.date.toString())}
                                        </div>
                                        <IconButton style={{ marginTop: "4%" }} onClick={() => handleRemoveTest(x.id!)}>
                                            <ClearIcon />
                                        </IconButton>

                                    </div>)}
                            </>
                        }
                        {createdTests.filter(x => x.testStatus === "Finished").length > 0 &&
                            <>
                                <Label className="mainPageForm-label" style={{ marginTop: "5%" }}>{t("TestsFinished")}</Label>

                                {createdTests.filter(x => x.testStatus === "Finished").map((x, idx) =>
                                    <div key={idx} style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", gap: "5px" }}>

                                        <div key={idx} style={{ height: "100%", minHeight: "70px", width: "70%", display: "flex", justifyContent: "center" }} className={`orderDataOrders${x.id === electricalMeasurementData.id ? " active" : ""}`}
                                            onClick={() => handleLoadTestData(x)}>{x.itemNumber} - {t(`${x.testType}`)} - {x.testPerformer} - {returnDate(x.date.toString())}
                                        </div>

                                        <IconButton disabled={isLoading} style={{ marginTop: "4%", cursor: "pointer" }} onClick={() => handleLoadPdf(x.directoCode, "1639")}>
                                            <FileOpenIcon />
                                        </IconButton>
                                        <IconButton disabled={isLoading} style={{ marginTop: "4%", cursor: "pointer" }} onClick={() => handleOpenWebsite(x.directoCode)}>
                                            <OpenInNewIcon />
                                        </IconButton>
                                        <SendEmailModal testDto={x} />

                                    </div>
                                )}
                            </>
                        }
                    </div>
                ) : null}
            </form>
        </div>
    )
}

export default SelectOrderForm