export const deFrostFunctionText = `1. Sulatsufunktsiooni katsetamine. Kontrolleri seadistus:
\nMain overview -> Control location -> Local control
\nMain overview -> Settings -> Fans -> No speeds to 1  
\nMain overview -> Settings -> Fans -> Constant speed control
\nMain overview -> Controls -> Min Speed 
\nSettings menu -> Heat recovery -> Defrost time -> 1 min
\nWiring info -> Heat recovery input temperature -> "force to" -4
\n2. Oota et seade käivitub:
Main overview -> Settings -> Heat recovery  -> (defrost function peaks olema OFF)
\n3.Testi defrost funktsiooni:
\nVoolikuga ühenda soojusvaheti rõhuanduri vabasse otsa, puhu sisse ja sulge voolik, et oleks suur rõhuvahe tagatud.
\nOota 1 minut
\nPeale ühte minutit, möödavoolu klapp avaneb ~50% ja 1 sulatusklapp sulgub
\nMain overview -> Settings -> Heat recovery  -> (Jälgi: peale 1 min. peks defrost olemas ON)
\nOota veel 1 minut
\nPeale ühte minutit suletud sulatusklapp avaneb ja teine klapp sulgub.
\nTEST LÕPPENUD`;

export const initialValuesText = `Programmis muuda:
\nSettings menu -> Heat recovery -> Defrost time -> 20 min
\nWiring info -> Heat recovery input temperature -> "set to automatic"`;

export const heatingActuatorText = `Controls -> Heat valve control output -> check if value position is same as controller value`;

export const electricalHeaterText = `1. Elektrikalorifeeri katsetamine. Kontrolleri seadistus:
\nMain overview -> Control location -> Local control
\nMain overview -> Settings -> Fans -> No speeds to 1  
\nMain overview -> Settings -> Fans -> Constant speed control
\nMain overview -> Controls -> Min Speed 
\nWiring info -> Heat control output -> "force to" 5V
\n
\nTest if supply air and electrical heater heat up.
\nIF yes. Then test is finished
\nWiring info -> Heat control output -> "set to automatic"`;

export const externalSignalsText = `Wiring info menüüs seadista "Dampers" väärtuseks "Open", testi kas klemmi ühendused näitavad multimeetriga 24V`
export const coolingCoilText = `Wiring info menüüs seadista "Cooling control output" väärtuseks "6 V", testi kas klemmi ühendused näitavad multimeetriga 6V`
export const preHeaterControlText = `Wiring info menüüs seadista "Pre heat control output" väärtuseks "3 V", testi kas klemmi ühendused näitavad multimeetriga 3V`

export const ltoPressureHoseText = `Vooliku (+/-) õigesti ühendatud ja sensori seadistus 1000 Pa`;
export const ltoDamperText = `Kontrolli kas õige Belimo ajam on paigaldatud (35 sek)`;

export const pressureSensorsText = `Vooliku (+/-) õigesti ühendatud ja sensori seadistus 1000 Pa`;

export const extractFanModbusText = `Modbus konfiguratsioon:
\nFail safe mode: ON
\nSet value fail safe speed: 0%
\nCommunication parameters: 12`;

export const supplyFanModbusText = `Modbus konfiguratsioon:
\nFail safe mode: ON
\nSet value fail safe speed: 0%
\nCommunication parameters: 11`;

export const controllerScrewsText = `Testida kontrolleri kinnitused ja seejärel ühendada seade vooluvõtku`;

export const softwareLoadedText = `Lae tarkvara SD kaardilt seadme kontrollerisse`;