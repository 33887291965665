import { AxiosResponse } from 'axios';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { Button, Label } from 'reactstrap';
import { useAhuDataContext } from '../../../context/ahuData';
import { getAhuEstTestsForItem } from '../../queries/testQueries';

const SelectTestCategory = (props: { testName: string[] }) => {
    const { t } = useTranslation();
    const { testType, setTestType, setAhuTestDataEst, ahuTestDataEst, setCreatedAhuEstTests, setIsLoading } = useAhuDataContext();

    const handleTestType = (value: any) => {
        setTestType(value);
        setAhuTestDataEst({ ...ahuTestDataEst, testType: value, id: 0, measurements: {}, radiatorType: "" })
        getAhuEstTestsForItem(ahuTestDataEst.orderNumber, value, ahuTestDataEst.uniqueItemNumber).then((res: AxiosResponse<any[]>) => {
            setCreatedAhuEstTests(res.data);
        }).catch((err) => {
            if (err.response.status === 401 || err.response.status === 403) return alert("You dont have enough permissions to access this data")
            alert(err.response.data)
        }).finally(() => {
            setIsLoading(false);
        })
    }

    if (props.testName[0] === (undefined || "")) return <></>;

    return (
        <React.Fragment>
            {props.testName[0] === "unknown" ?
                <div className="message-testName-unkown" >
                    No suitable test found
                </div>
                :
                (
                    <>
                        <Label className="mainPageForm-label" style={{ marginTop: "5%" }}>
                            {t("SelectTest")}
                        </Label>

                        {props.testName.map((value, idx) =>
                            <Button key={idx} name="testType" className={`flexboxAlignedRow orderDataOrders button-selection-1 ${testType === value ? "active" : ""}`} style={{ minWidth: "250px", width: "57%" }} onClick={() => handleTestType(value)}>
                                {t(`${value}`)}
                            </Button>
                        )}
                    </>
                )
            }
        </React.Fragment>
    )
}

export default SelectTestCategory