export const TEST_TYPE = {
    EMPTY:0,
    VISUAL: 1,
    LEAK: 2,
} as const;

export type Kalorifeer = {
    id: number,
    testType: typeof TEST_TYPE[keyof typeof TEST_TYPE],
    supplierCode:string
    supplierName:string
    purchaseOrderNumber:string
    productArticleNumber:string
    productName:string
    productUniqueId:string
    testName:string
    testStatus:string
    createdBy:string
    finishedBy:string
    createdDate:Date
    finishedDate:Date
    leakTest:LeakTest
    visualTest:VisualTest
}

export type LeakTest = {
    kalorifeerType:string
    startDateTime:Date
    endDateTime:Date
    startPressure?:number
    endPressure?:number
    pressureDifference?:number
    testResult:string
}

export type VisualTest = {
    diameter1?:number
    diameter2?:number
    diameterDifference:number
    diameterTestResult:string,
    measurementType:string,
    angle?:number,
    angleDifference?:number,
    angleTestResult:string,
    length?:number
    crossLength?:number
    crossLengthDifference?:number
    crossLengthTestResult:string
    welderName:string
    weldingResult:string
    weldingResultImage1:string
    weldingResultImage2:string
    packagingImage:string
}
