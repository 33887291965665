import React from 'react'
import { useTranslation } from 'react-i18next'
import { Label } from 'reactstrap'
import { CheckboxDefaultValue, CheckboxOptions } from '../common/checkboxConfig'
import CheckboxGroup from '../common/CheckboxGroup'
import { MeasurementField } from '../common/MeasurementField'

const CoolerTestForm3 = () => {
    const { t } = useTranslation();

    return (
        <form className="ahutest-container" >
            <Label className="ahutest-from--title">{t("Cooler test protocol")}</Label>
            <div className="flexboxAlignedColumn" style={{ padding: "2%" }}>

                <Label className="ahutest-cooler-from__label">{t("High pressure switch")}</Label>
                <div className="ahutest-form__label--grid2">
                    <Label className="grid-item-label" >{t("Nominal release pressure")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="nominal-highPressure"
                    />
                </div>
                <MeasurementField properyName='nominal-highPressure' unit='bar' gap="10px" />

                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Launch tested")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="launch-highPressure"
                    />
                </div>
                <MeasurementField properyName='launch-highPressure' unit='bar' gap="10px" />

                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Compressor stop worked")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="comp-highPressure"
                    />
                </div>
                <MeasurementField properyName='comp-highPressure' showValue={false} />

                <Label className="ahutest-cooler-from__label">{t("Low pressure switch")}</Label>
                <div className="ahutest-form__label--grid2" style={{ gap: "10px" }}>
                    <Label className="grid-item-label" >{t("Nominal release pressure")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="nominal-lowPressure"
                    />
                </div>
                <MeasurementField properyName='nominal-lowPressure' unit='bar' gap="10px" />

                <div className="ahutest-form__label--grid2" style={{ gap: "10px" }}>
                    <Label className="grid-item-label" >{t("Launch tested")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="launch-lowPressure"
                    />
                </div>
                <MeasurementField properyName='launch-lowPressure' unit='bar' gap="10px" />

                <div className="ahutest-form__label--grid2" style={{ gap: "10px" }}>
                    <Label className="grid-item-label" >{t("Compressor stop worked")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="comp-lowPressure"
                    />
                </div>
                <MeasurementField properyName='comp-lowPressure' showValue={false} />

            </div>
        </form>
    )
}

export default CoolerTestForm3