import { Label } from 'reactstrap'
import { useTranslation } from 'react-i18next';

interface Props {
    testType: string
}
const AhuMeasurementsOverview = ({ testType }: Props) => {
    const { t } = useTranslation();

    return (
        <form className="ahutest-container" >
            <Label className="ahutest-from--title" style={{ textAlign: "center", width: "100%" }}>{t("TestOverview")}</Label>
            <div className="flexboxAlignedColumn"  >
                <div>{t("TestName")}: {t(testType)}</div>
            </div>
        </form>
    )
}

export default AhuMeasurementsOverview