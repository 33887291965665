import { useMemo } from 'react';
import { useAhuDataContext } from '../../../../context/ahuData';

const IsDisabled = (measurementKey: string) => {
    const { electricalMeasurementData } = useAhuDataContext();
    return useMemo(() => {
        if (electricalMeasurementData.measurements[measurementKey]?.statusNew !== "disabled") {
            return true
        } else {
            return false;
        }
    }, [electricalMeasurementData, measurementKey])
}
export default IsDisabled 
