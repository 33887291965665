import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next';
import { useTkalDataContext } from '../../../../context/tkalData';

interface InputProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLInputElement>, HTMLInputElement> {
}

type MeasurementFieldProps = {
    propertyName: string,
    unit?: string,
    placeholderKey?: string,
    inputProps?: InputProps,
    gap?: string,
    showValue?: boolean,
}

const MeasurementFieldOzonator = ({
    propertyName,
    unit = '',
    placeholderKey = 'Value',
    inputProps = { className: 'ahutest-form__input --value' },
    gap = '25px',
    showValue = true,

}: MeasurementFieldProps) => {
    const { ozonatorTest, setOzonatorTest } = useTkalDataContext();
    const { t } = useTranslation();

    const IsDisabled = (measurementKey: string) => {
        return useMemo(() => {
            if (ozonatorTest.measurements[measurementKey]?.statusNew !== "disabled") {
                return true
            } else {
                return false;
            }
        }, [ozonatorTest, measurementKey])
    }

    const handleChangeMeasurementsValue = (event: any, propertyName: string) => {
        setOzonatorTest({
            ...ozonatorTest,
            "measurements": { ...ozonatorTest.measurements, [event.target.name]: { ...ozonatorTest.measurements[event.target.name], [propertyName]: event.target.value } }
        })
    }

    const handleChangeMeasurementsValueUnit = (event: any, propertyName: string, unit: string) => {
        setOzonatorTest({
            ...ozonatorTest,
            "measurements": { ...ozonatorTest.measurements, [event.target.name]: { ...ozonatorTest.measurements[event.target.name], [propertyName]: event.target.value, unit: unit } }
        })
    }

    return (
        <>
            {IsDisabled(propertyName) &&
                <div className="flexboxAlignedRow" style={{ gap: gap }}>
                    {showValue &&
                        <>
                            <input {...inputProps} name={propertyName} onChange={e => handleChangeMeasurementsValueUnit(e, "value", unit)} value={ozonatorTest.measurements[propertyName]?.value ?? ""} placeholder={t(placeholderKey)} />
                            {unit}</>}
                    <input className="ahutest-form__input" name={propertyName} onChange={e => handleChangeMeasurementsValue(e, "description")} value={ozonatorTest.measurements[propertyName]?.description ?? ""} placeholder={t("Notes")} />
                </div>
            }
        </>
    );
}

export default MeasurementFieldOzonator