import { useTranslation } from "react-i18next";
import { useAhuDataContext } from "../../../../context/ahuData";
import IsDisabled from "./isDisabled";


interface InputProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLInputElement>, HTMLInputElement> {

}
type MeasurementFieldProps = {
    properyName: string,
    unit?: string,
    placeholderKey?: string,
    inputProps?: InputProps,
    gap?: string,
    showValue?: boolean
}
export function MeasurementField({
    properyName,
    unit = "",
    placeholderKey = "Value",
    inputProps = { className: "ahutest-form__input --value" },
    gap = "25px",
    showValue = true
}: MeasurementFieldProps) {

    const { handleAhuTestMeasurementsValueUnit, handleAhuTestMeasurementsValue, electricalMeasurementData } = useAhuDataContext();
    const { t } = useTranslation();


    return (
        <>
            {IsDisabled(properyName) &&
                <div className="flexboxAlignedRow" style={{ gap: gap }}>
                    {showValue &&
                        <>
                            <input {...inputProps} name={properyName} onChange={e => handleAhuTestMeasurementsValueUnit(e, "value", unit)} value={electricalMeasurementData.measurements[properyName]?.value ?? ""} placeholder={t(placeholderKey)} />
                            {unit}</>}
                    <input className="ahutest-form__input" name={properyName} onChange={e => handleAhuTestMeasurementsValue(e, "description")} value={electricalMeasurementData.measurements[properyName]?.description ?? ""} placeholder={t("Notes")} />
                </div>}
        </>
    );
}