import ProgressBar_1_en from '../../graphics/Progress_1.svg'
import ProgressBar_1_et from '../../graphics/Progress_1_ET.svg'
import ProgressBar_1_fi from '../../graphics/Progress_1_fi.svg'
import ProgressBar_2_en from '../../graphics/Progress_2.svg'
import ProgressBar_2_et from '../../graphics/Progress_2_ET.svg'
import ProgressBar_2_fi from '../../graphics/Progress_2_fi.svg'
import ProgressBar_3_en from '../../graphics/Progress_3.svg'
import ProgressBar_3_et from '../../graphics/Progress_3_ET.svg'
import ProgressBar_3_fi from '../../graphics/Progress_3_fi.svg'
import ProgressBar_4_en from '../../graphics/Progress_4.svg'
import ProgressBar_4_et from '../../graphics/Progress_4_ET.svg'
import ProgressBar_4_fi from '../../graphics/Progress_4_fi.svg'

const makeSvg = (src: string) => {
    return <img className="progressBar" src={`${src}`} alt={`{${src}}`} ></img>
}
const IconMap = new Map([
    ["ProgressBar_1_en", makeSvg(ProgressBar_1_en)],
    ["ProgressBar_1_et", makeSvg(ProgressBar_1_et)],
    ["ProgressBar_1_fi", makeSvg(ProgressBar_1_fi)],
    ["ProgressBar_2_en", makeSvg(ProgressBar_2_en)],
    ["ProgressBar_2_et", makeSvg(ProgressBar_2_et)],
    ["ProgressBar_2_fi", makeSvg(ProgressBar_2_fi)],
    ["ProgressBar_3_en", makeSvg(ProgressBar_3_en)],
    ["ProgressBar_3_et", makeSvg(ProgressBar_3_et)],
    ["ProgressBar_3_fi", makeSvg(ProgressBar_3_fi)],
    ["ProgressBar_4_en", makeSvg(ProgressBar_4_en)],
    ["ProgressBar_4_et", makeSvg(ProgressBar_4_et)],
    ["ProgressBar_4_fi", makeSvg(ProgressBar_4_fi)]
]);


const ReturnProgressBar = (props: { level: string, lang: string }) => {
    if (props.lang === "sv") {
        let srcString = `ProgressBar_${props.level}_${"en"}`
        return IconMap.get(srcString)!;
    }
    else {
        let srcString = `ProgressBar_${props.level}_${props.lang}`
        return IconMap.get(srcString)!;
    }
}

export default ReturnProgressBar