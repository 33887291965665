import React from 'react'
import { useTranslation } from 'react-i18next';
import { Label } from 'reactstrap';
import SelectPrinter from '../../../common/SelectPrinter';
import InfoModal from '../../ahu-estonia/InfoModal';
import { CheckboxOptionsThumbsOnly, CheckboxDefaultValue } from '../../ahu/common/checkboxConfig';
import CheckboxGroupOzonator from '../common/CheckboxGroupOzonator';
import MeasurementFieldOzonator from '../common/MeasurementFieldOzonator';
import { ozoneGeneratorSafetyInstructions } from '../ModalTexts';

const OzoneGeneratorTestForm = () => {
    const { t } = useTranslation();

    return (
        <form className="ahutest-container" >
            <Label className="ahutest-from--title">{t("Osoonigeneraatori testimine")}</Label>
            <div className="flexboxAlignedColumn" style={{ padding: "2%" }}>
                <div className="flexboxAlignedRow" style={{ gap: "20px", marginBottom: "4%" }}>
                    <Label className="grid-item-label" style={{ width: "100%", marginLeft: "0" }}>{t("Tutvuge ohutusjuhendiga")}</Label>
                    <InfoModal title={t("Juhend")} bodyText={ozoneGeneratorSafetyInstructions} />
                </div>
                <div className="ozTest-form__label-info-button--grid" >
                    <Label className="grid-item-label" >{t("Seadmel nähtavaid täkkeid ei ole ja joote kohad on korralikud ja terved")}</Label>
                    <InfoModal title={t("Juhend")} bodyText={"Kontrollida visuaalselt seadme seisukorda"} />
                    <CheckboxGroupOzonator
                        options={CheckboxOptionsThumbsOnly}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="visual-ozGen"
                    />
                </div>
                <MeasurementFieldOzonator propertyName="visual-ozGen" showValue={false} />

                <div className="ozTest-form__label-info-button--grid" >
                    <Label className="grid-item-label" >{t("Koroonaplaat põleb vähemalt 90% ulatuses")}</Label>
                    <InfoModal title={t("Juhend")} bodyText={"Kontrollida kas koroonaplaadi alad põlevad täielikult või vähemalt 90%-selt (veerand plaadi poolest võib olla kustunud)"} />
                    <CheckboxGroupOzonator
                        options={CheckboxOptionsThumbsOnly}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="working-ozGen"
                    />
                </div>
                <MeasurementFieldOzonator propertyName="working-ozGen" showValue={false} />

                <div className="ozTest-form__label-info-button--grid" >
                    <Label className="grid-item-label" >{t("Tarbitav võimsus (W)")}</Label>
                    <InfoModal title={t("Juhend")} bodyText={"Märkida ühe osoonigeneraatori tarbitav võimsus pärast 2 min töötamist."} />
                    <CheckboxGroupOzonator
                        options={CheckboxOptionsThumbsOnly}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="power-ozGen"
                    />
                </div>
                <MeasurementFieldOzonator propertyName="power-ozGen" unit="W" gap="10px" />

                <div className="ozTest-form__label-info-button--grid">
                    <Label className="grid-item-label" >{t("Tarbitav vool (A)")}</Label>
                    <InfoModal title={t("Juhend")} bodyText={"Märkida ühe osoonigeneraatori tarbitav vool pärast 2 min töötamist. Min 250 mA"} />
                    <CheckboxGroupOzonator
                        options={CheckboxOptionsThumbsOnly}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="current-ozGen"
                    />
                </div>
                <MeasurementFieldOzonator propertyName="current-ozGen" unit="A" gap="10px" />

                <SelectPrinter defaultPrinter="OZ_Printer" />
            </div>
        </form>
    )
}

export default OzoneGeneratorTestForm