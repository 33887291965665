import { IconButton, Tooltip } from "@mui/material";
import { ReactChild, ReactFragment, ReactPortal } from "react";

export const MuiIcon = (props: { onClick: React.MouseEventHandler<HTMLButtonElement> | undefined, Icon: () => JSX.Element, title: boolean | ReactChild | ReactFragment | ReactPortal, describedby?: string }) => (
    <Tooltip title={props.title} placement="top">
        <IconButton
            onClick={props.onClick}
            aria-label="done"
            size="medium"
            //className={useStyles().margin}
            aria-describedby={props.describedby}
        >
            <props.Icon />

        </IconButton>
    </Tooltip>
)