import React from 'react'
import { useTranslation } from 'react-i18next';
import { Label } from 'reactstrap';
import InfoModal from '../ahu-estonia/InfoModal';
import { CheckboxDefaultValue, CheckboxOptions } from '../ahu/common/checkboxConfig';
import CheckBoxGroupControlPanel from './common/CheckBoxGroupControlPanel';
import MeasurementFieldControlPanel from './common/MeasurementFieldControlPanel';

const ControlPanelAfterTestForm = () => {
    const { t } = useTranslation();
    const softwareFormatText = `NB! Protsess on vaja teha iga kord uuesti! \n
    Paigalda tootmise automaatika serverist SD kaardile "formatdevice" fail ning lae see juhtimiskilbi kontrollerile. \n
    Peale uuendust on fail SD kaardilt kadunud ning iga järgmise seadme puhul tuleb uuesti fail SD-kaardile laadida.`
    return (
        <form className="ahutest-container" >
            <Label className="ahutest-from--title">{t("Peale testi")}</Label>
            <div className="flexboxAlignedColumn" style={{ padding: "2%" }}>
                <div className="ahutest-form__label-info-button--grid" >
                    <Label className="grid-item-label" >{t("Tarkvara format on tehtud")}</Label>
                    <InfoModal title={t("Juhend")} bodyText={softwareFormatText} />
                    <CheckBoxGroupControlPanel
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="format-afterTest"
                    />
                </div>
                <MeasurementFieldControlPanel propertyName="format-afterTest" showValue={false} />

                <div className="ahutest-form__label-info-button--grid" >
                    <Label className="grid-item-label" >{t("Uusim tarkvara on peale pandud")}</Label>
                    <InfoModal title={t("Juhend")} bodyText={"Paigalda kontrollerile uusim tarkvara. Tarkvara on leitav Tootmise automaatika serverist"} />
                    <CheckBoxGroupControlPanel
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="reinstall-afterTest"
                    />
                </div>
                <MeasurementFieldControlPanel propertyName="reinstall-afterTest" showValue={false} />
            </div>
        </form>
    )
}

export default ControlPanelAfterTestForm