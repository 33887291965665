import React from 'react'
import { useTranslation } from 'react-i18next';
import { Label } from 'reactstrap';
import { useAhuDataContext } from '../../../../../context/ahuData';
import { CheckboxOptions, CheckboxDefaultValue } from '../../../ahu/common/checkboxConfig';
import CheckboxGroup from '../../CheckboxGroup';
import InfoModal from '../../InfoModal';
import MeasurementField from '../../MeasurementField';
import { deFrostFunctionText, initialValuesText, heatingActuatorText, electricalHeaterText } from '../../ModalTexts';

const ControllerFunctionsForm = () => {
    const { t } = useTranslation();
    const { ahuTestDataEst } = useAhuDataContext();

    return (
        <form className="ahutest-container" >
            <Label className="ahutest-from--title">{t("Test controller functions")}</Label>

            <div className="flexboxAlignedColumn" style={{ padding: "2%" }}>

                <div className="ahutest-form__label-info-button--grid" >
                    <Label className="grid-item-label" >{t("Defrost function")}</Label>
                    <InfoModal title={t("Juhend")} bodyText={deFrostFunctionText} />
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="deFrost-ctrFn"
                    />
                </div>
                <MeasurementField properyName="deFrost-ctrFn" showValue={false} />

                <div className="ahutest-form__label-info-button--grid" >
                    <Label className="grid-item-label" >{t("Initail values reinstated")}</Label>
                    <InfoModal title={t("Juhend")} bodyText={initialValuesText} />
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="initial-ctrFn"
                    />
                </div>
                <MeasurementField properyName="initial-ctrFn" showValue={false} />

                {ahuTestDataEst.radiatorType === "waterRadiator" &&
                    <>
                        <div className="ahutest-form__label-info-button--grid" >
                            <Label className="grid-item-label" >{t("Heating actuator test")}</Label>
                            <InfoModal title={t("Juhend")} bodyText={heatingActuatorText} />
                            <CheckboxGroup
                                options={CheckboxOptions}
                                defaultValue={CheckboxDefaultValue}
                                measurementKey="actuator-ctrFn"
                            />
                        </div>
                        <MeasurementField properyName="actuator-ctrFn" showValue={false} />
                    </>
                }


                {ahuTestDataEst.radiatorType === "electricRadiator" &&
                    <>
                        <div className="ahutest-form__label-info-button--grid" >
                            <Label className="grid-item-label" >{t("Electrical heater running test")}</Label>
                            <InfoModal title={t("Juhend")} bodyText={electricalHeaterText} />
                            <CheckboxGroup
                                options={CheckboxOptions}
                                defaultValue={CheckboxDefaultValue}
                                measurementKey="elHeater-ctrFn"
                            />
                        </div>

                        <MeasurementField properyName="elHeater-ctrFn" showValue={false} />
                    </>
                }
            </div>
        </form>
    )
}

export default ControllerFunctionsForm