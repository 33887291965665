import React from 'react'
import { Label } from 'reactstrap';
import { useTranslation } from "react-i18next";
import MeasurementField from '../MeasurementField';
import { CheckboxOptions, CheckboxDefaultValue } from '../../ahu/common/checkboxConfig';
import CheckboxGroup from '../CheckboxGroup';

const ElectricRadiatorForm = () => {
    const { t } = useTranslation();
    return (
        <form className="ahutest-container" >
            <Label className="ahutest-from--title">{t("Electric heating radiator")}</Label>

            <div className="flexboxAlignedColumn" style={{ padding: "2%" }}>
                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Visual inspection")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="visualInspection-ElHeatRad"
                    />
                </div>
                <MeasurementField properyName="visualInspection-ElHeatRad" showValue={false} />

                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Electrical connections checked")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="connect-ElHeatRad"
                    />
                </div>
                <MeasurementField properyName="connect-ElHeatRad" showValue={false} />

            </div>
        </form>
    )
}

export default ElectricRadiatorForm