import React from 'react'
import { Label } from 'reactstrap';
import { useTranslation } from "react-i18next";
import { CheckboxOptions, CheckboxDefaultValue } from '../common/checkboxConfig';
import CheckboxGroup from '../common/CheckboxGroup';
import { MeasurementField } from '../common/MeasurementField';
const WaterRadiatorForm = () => {
    const { t } = useTranslation();

    return (
        <form className="ahutest-container" >
            <Label className="ahutest-from--title">{t("Water radiator (heating and cooling)")}</Label>

            <div className="flexboxAlignedColumn" style={{ padding: "2%" }}>
                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Heater valve actuator")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="heaterValve-watRad"
                    />
                </div>
                <MeasurementField properyName="heaterValve-watRad" showValue={false} />

                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Pump locking")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="pumpLocking-watRad"
                    />
                </div>
                <MeasurementField properyName="pumpLocking-watRad" showValue={false} />

                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Pump operating mode")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="pumpOperating-watRad"
                    />
                </div>
                <MeasurementField properyName="pumpOperating-watRad" showValue={false} />

                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Pump alarm")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="pumpAlarm-watRad"
                    />
                </div>
                <MeasurementField properyName="pumpAlarm-watRad" showValue={false} />

                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Freeze alarm")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="antiFreezeAlarm-watRad"
                    />
                </div>
                <MeasurementField properyName="antiFreezeAlarm-watRad" showValue={false} />

                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Cooling valve actuator")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="coolingValve-watRad"
                    />
                </div>
                <MeasurementField properyName="coolingValve-watRad" showValue={false} />
            </div>
        </form>
    )
}

export default WaterRadiatorForm