import React from 'react'
import { Label } from 'reactstrap';
import { useTranslation } from "react-i18next";
import { CheckboxOptions, CheckboxDefaultValue } from '../common/checkboxConfig';
import CheckboxGroup from '../common/CheckboxGroup';
import { MeasurementField } from '../common/MeasurementField';
const FilterForm = () => {
    const { t } = useTranslation();

    return (
        <form className="ahutest-container" >
            <Label className="ahutest-from--title">{t("Filters")}</Label>
            <div className="flexboxAlignedColumn" style={{ padding: "2%" }}>
                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Inlet filter differential pressure transmitter")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="inletFilterDifferential-filt"
                    />
                </div>
                <MeasurementField inputProps={{ className: "ahutest-form__input --value smaller" }} properyName='inletFilterDifferential-filt' gap="10px" unit="Pa" placeholderKey="Pressure range" />
                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Exhaust filter differential pressure transmitter")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="exhaustFilterDifferential-filt"
                    />
                </div>
                <MeasurementField inputProps={{ className: "ahutest-form__input --value smaller" }} properyName='exhaustFilterDifferential-filt' gap="10px" unit="Pa" placeholderKey="Pressure range" />
                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Prefilter differential pressure transmitter")}</Label>
                    <CheckboxGroup
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="prefilterDifferential-filt"
                    />
                </div>
                <MeasurementField inputProps={{ className: "ahutest-form__input --value smaller" }} properyName='prefilterDifferential-filt' gap="10px" unit="Pa" placeholderKey="Pressure range" />
            </div>
        </form>
    )
}

export default FilterForm