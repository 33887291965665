import React from 'react'
import { useTranslation } from 'react-i18next';
import { Label } from 'reactstrap';
import { CheckboxDefaultValue, CheckboxOptions } from '../ahu/common/checkboxConfig';
import CheckboxGroupOzonator from './common/CheckboxGroupOzonator';
import MeasurementFieldOzonator from './common/MeasurementFieldOzonator';

const OzonatorCasingForm = () => {
    const { t } = useTranslation();

    return (
        <form className="ahutest-container" >
            <Label className="ahutest-from--title">{t("Korpus")}</Label>
            <div className="flexboxAlignedColumn" style={{ padding: "2%" }}>
                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Seadme korpus on vigastamata")}</Label>
                    <CheckboxGroupOzonator
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="intact-case"
                    />
                </div>
                <MeasurementFieldOzonator propertyName="intact-case" showValue={false} />

                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Graveeringud on peal")}</Label>
                    <CheckboxGroupOzonator
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="gravings-case"
                    />
                </div>
                <MeasurementFieldOzonator propertyName="gravings-case" showValue={false} />

                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Komponendid on paigaldatud korrektselt etalontoote järgi")}</Label>
                    <CheckboxGroupOzonator
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="placement-case"
                    />
                </div>
                <MeasurementFieldOzonator propertyName="placement-case" showValue={false} />

                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Elektiohu ja O3 osooni ohukleebis on kilbi põhjal olemas")}</Label>
                    <CheckboxGroupOzonator
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="elSticker-case"
                    />
                </div>
                <MeasurementFieldOzonator propertyName="elSticker-case" showValue={false} />

                <div className="ahutest-form__label--grid2" >
                    <Label className="grid-item-label" >{t("Tootekleebis on olemas")}</Label>
                    <CheckboxGroupOzonator
                        options={CheckboxOptions}
                        defaultValue={CheckboxDefaultValue}
                        measurementKey="productSticker-case"
                    />
                </div>
                <MeasurementFieldOzonator propertyName="productSticker-case" showValue={false} />
            </div>
        </form>
    )
}

export default OzonatorCasingForm